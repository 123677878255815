import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import '../../assets/css/confirmModal.css'; // Custom CSS for extra styling

const ConfirmModal = ({ title, message, onConfirm, onCancel, isVisible }) => {
  return (
    <Modal show={isVisible} onHide={onCancel} centered dialogClassName="custom-modal">
      <Modal.Header >
        <Modal.Title className="modal-title-custom">{title}</Modal.Title>
        <button className="custom-close-button" onClick={onCancel}>
          &times;
        </button>

      </Modal.Header>
      <Modal.Body>
        <p className="modal-message">{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <div className='d-flex justify-content-end w-100'>
        <Button variant="secondary" className="cancel-button" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="warning" className="confirm-button" onClick={onConfirm}>
          Confirm
        </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
