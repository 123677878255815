/**
 * The base breakpoints for the Soft UI Dashboard React.
 * You can add new breakpoints using this file.
 * You can customized the breakpoints for the entire Soft UI Dashboard React using thie file.
 */

const breakpoints = {
  values: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400
  }
}

export default breakpoints
