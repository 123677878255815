// @mui material components
import Card from '@mui/material/Card'
import Pagination from 'react-bootstrap/Pagination';

// Soft UI Dashboard React components
import SoftBox from 'components/SoftBox'
import SoftTypography from 'components/SoftTypography'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// Soft UI Dashboard React examples
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import Footer from 'examples/Footer'
import Table from 'examples/Tables/Table'
import SoftInput from 'components/SoftInput'
import SoftProgress from 'components/SoftProgress'
// Data
import { useEffect, useState } from 'react'
import { getAuthConfig, get, deletes } from 'libs/http-hydrate'
import SoftAvatar from 'components/SoftAvatar'
import PropTypes from 'prop-types' // Import PropTypes
import moment from 'moment'
import Modal from 'react-bootstrap/Modal'
import Box from '@mui/material/Box'
import InfoIcon from '@mui/icons-material/Info'
import EmailIcon from '@mui/icons-material/Email'
import PhoneIcon from '@mui/icons-material/Phone'
import Button from '@mui/material/Button'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import VerifiedBadge from 'assets/images/quality.png'
import { useNavigate } from 'react-router-dom'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import dayjs from 'dayjs'
import Badge from '@mui/material/Badge'
import { PickersDay } from '@mui/x-date-pickers/PickersDay'
import WebSVG from '../../assets/images/WebSvg.svg'
import IOSSVG from '../../assets/images/ios.svg'
import AndoidSVG from '../../assets/images/android.svg'
import ConfirmModal from 'layouts/Modal/ConfirmModal'
import { DayCalendarSkeleton } from '@mui/x-date-pickers'
import '../../assets/css/pagination.css'
import { Spinner } from 'react-bootstrap';
function Patients () {
  // const { columns: prCols, rows: prRows } = projectsTableData
  
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false); // To track deletion loading state
const [selectedPatient, setSelectedPatient] = useState(null); // To store the selected patient for deletion
const [deleteMessage, setDeleteMessage] = useState(''); // Message to display during deletion
const [sortField, setSortField] = useState('created_at'); // Default sorting field
const [sortOrder, setSortOrder] = useState('asc'); // Default sorting order
const [totalPages, setTotalPages] = useState(0); // Total pages for pagination

  const navigate = useNavigate()
  const [date, setDate] = useState(dayjs())
  const [isLoading, setIsLoading] = useState(false)
  const [physiotherapistList, setPhysiotherapistList] = useState({
    items: [],
    has_more: false
  })
  const [highlightedDays, setHighlightedDays] = useState([])
  const [searchQuery, setSearchQuery] = useState('') // State for search query
  const [page, setPage] = useState(0)
  const [selectedClinic, setSelectedClinic] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  // Function to handle opening the modal
  const handleOpenModal = (clinic) => {
    setSelectedClinic(clinic)
    setHighlightedDays(clinic?.activityData?.activityData)
    setModalOpen(true) // Open the modal
  }

  // Function to handle closing the modal
  const handleCloseModal = () => {
    setModalOpen(false)
  }
  useEffect(() => {
    getPhysiotherapistList()
  }, [page, searchQuery, sortField, sortOrder])

  async function getPhysiotherapistList() {
    try {
      setIsLoading(true);
      const data = await get(
        `/getAllPatientList?pageNumber=${page}&searchQuery=${searchQuery}&sortField=${sortField}&sortOrder=${sortOrder}`,
        getAuthConfig()
      );
      if (data?.status === 200) {
        setPhysiotherapistList((p) => ({
          ...p,
          items: data?.data?.msg,
          has_more: data?.data?.has_more
        }));
        setTotalPages(Math.ceil(data?.data?.totalPages)); // Assuming 10 items per pages
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }  

  function Author ({ image, name, email, onClick }) {
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5} onClick={onClick}>
        <SoftBox mr={2}>
          <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
        </SoftBox>
        <SoftBox display="flex" flexDirection="column">
          <SoftTypography variant="button" fontWeight="medium">
            {name}
          </SoftTypography>
          <SoftTypography variant="caption" color="secondary">
            {email}
            {email &&
            <img src={VerifiedBadge} alt="Verified" style={{ marginLeft: '4px', width: '16px', height: '16px' }} /> }

          </SoftTypography>
        </SoftBox>
      </SoftBox>
    )
  }

  function Function ({ job, org }) {
    return (
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="caption" fontWeight="medium" color="text">
          {job}

          {job &&
            <img src={VerifiedBadge} alt="Verified" style={{ marginLeft: '4px', width: '16px', height: '16px' }} /> }
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {org}
        </SoftTypography>
      </SoftBox>
    )
  }
  function Completion ({ value, color }) {
    return (
      <SoftBox display="flex" alignItems="center">
        <SoftTypography variant="caption" color="text" fontWeight="medium">
          {value}% &nbsp;
        </SoftTypography>
        <SoftBox width="8rem">
          <SoftProgress value={value} color={color} variant="gradient" label={false} />
        </SoftBox>
      </SoftBox>
    )
  }
  // PropTypes definition for Author
  Author.propTypes = {
    image: PropTypes.string.isRequired, // Assuming 'image' is a string
    name: PropTypes.string.isRequired, // Assuming 'name' is a string
    email: PropTypes.string.isRequired // Assuming 'email' is a string
  }

  // PropTypes definition for Function
  Function.propTypes = {
    job: PropTypes.string.isRequired, // Assuming 'job' is a string
    org: PropTypes.string.isRequired // Assuming 'org' is a string
  }

  const handleSortChange = (field) => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc'; // Toggle between asc and desc
    setSortField(field);
    setSortOrder(newSortOrder);
    getPhysiotherapistList(); // Fetch the sorted data
  };
  
      const authorsTableData = {
        columns: [
          { 
            name: 'Patient', 
            align: 'left' 
          },
          { 
            name: 'ID', 
            align: 'left' 
          },
          { 
            name: 'phone', 
            align: 'left' 
          },
          { 
            name: 'progress', 
            align: 'center' 
          },
          { 
            name: 'created', 
            align: 'center'
          },
          { 
            name: 'lastLogin', 
            align: 'center',
            component: (
              <SoftTypography
                variant="caption"
                fontWeight="bold"
                onClick={() => handleSortChange('lastLogin')}
                style={{ cursor: 'pointer' }}
              >
                Last Login {sortField === 'lastLogin' && (sortOrder === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />)}
              </SoftTypography>
            )
          },
          { 
            name: 'action', 
            align: 'center' 
          }
        ],
      
        rows: physiotherapistList.items && physiotherapistList.items.map((item) => ({
          Patient: (
            <Author image={item.profile ? item?.profile : ''} name={item.name} email={item.email} onClick={() => handleOpenModal(item)} />
          ),
          ID: (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            #{item?.uid}
          </SoftTypography>
          ),
          phone: item?.phone && item?.phone !== '+' ? <Function job={item?.phone} org={item.hospital_name} /> : 'N/A',
          progress: <Completion value={item?.progressPercentage?.toFixed(2)} color="info" />,
          created: (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
              {moment(item.created_at).format('DD / MM / YYYY')}
            </SoftTypography>
          ),
          lastLogin: (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
              {item.lastLoggedIn && moment(item.lastLoggedIn).format('DD / MM / YYYY hh:mm A')}
            </SoftTypography>
          ),
          action: (
            <>
              <SoftTypography
                component="a"
                variant="caption"
                color="secondary"
                fontWeight="medium"
                onClick={() => {
                  navigate('/EditPatient', { state: { patient: item } });
                }}
              >
                Edit
              </SoftTypography> / 
              <SoftTypography
                component="a"
                href="#"
                variant="caption"
                color="secondary"
                fontWeight="medium"
                onClick={() => handleDeleteClick(item)}
              >
                Delete
              </SoftTypography>
            </>
          )
        }))
      };
  
  const handleDeleteClick = (patient) => {
    setSelectedPatient(patient); // Store the selected patient for deletion
    setIsModalVisible(true); // Show confirmation modal
  };
  async function deletePatient() {
    setIsDeleting(true); // Start the loading spinner
    setDeleteMessage('Deleting patient...'); // Set delete message
  
    const url = `/DeletePatient?id=${selectedPatient._id}`;
    try {
      const data = await deletes(url);
      if (data?.status === 200) {
        // Update the state to remove the deleted patient directly
        setPhysiotherapistList((prevState) => ({
          ...prevState,
          items: prevState.items.filter((item) => item._id !== selectedPatient._id)
        }));
        setDeleteMessage('Patient successfully deleted.');
      }
    } catch (error) {
      setDeleteMessage('Error deleting patient. Please try again.');
    } finally {
      setIsDeleting(false); // Stop the spinner
      setIsModalVisible(false); // Hide the confirmation modal
    }
  }
  
  function ServerDay (props) {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props
    const actionsData = highlightedDays
    const dateString = dayjs(day).format('YYYY-MM-DD')
    const isHighlighted = actionsData.some((action) => dayjs(action.date).format('YYYY-MM-DD') === dateString)
    const hasWebAction = actionsData.some((action) => dayjs(action.date).format('YYYY-MM-DD') === dateString && action.actions === 'Web')
    const hasIOSAction = actionsData.some((action) => dayjs(action.date).format('YYYY-MM-DD') === dateString && action.actions === 'iOS')
    const hasAndroidAction = actionsData.some((action) => dayjs(action.date).format('YYYY-MM-DD') === dateString && action.actions === 'Android')
    const customStyles = {
      backgroundColor: 'transparent',
      border: 'none',
      color: 'black',
      position: 'relative' // Add position relative
    }
    const renderDot = (color) => (
      <div style={{ textAlign: 'center', marginTop: '15px' }}>
        {color === 'Web'
          ? (
            <img src={WebSVG} alt="Web" style={{ width: '12px', height: '12px' }} />
            )
          : color === 'Android'
            ? (
              <img src={AndoidSVG} alt="iOS" style={{ width: '12px', height: '12px' }} />
              )
            : (
            <img src={IOSSVG} alt="iOS" style={{ width: '12px', height: '12px' }} />
              )}
      </div>
    )
    return (
      <div style={{ position: 'relative' }}> {/* Wrap the entire content with a div */}
        <div style={{ position: 'relative', padding: '5px' }}> {/* Add a position relative */}
          <Badge key={day.toString()} overlap="circular">
            <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} style={customStyles} />
          </Badge>
        </div>
        {isHighlighted && (
          <div style={{ display: 'flex', justifyContent: 'center', position: 'absolute', bottom: '20px', left: '0', width: '100%' }}>
            {hasWebAction && renderDot('Web')}
            {hasIOSAction && renderDot('iOS')}
            {hasAndroidAction && renderDot('Android')}
          </div>
        )}
      </div>
    )
  }
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  return (

    <>
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Patient table</SoftTypography>
              <div style={{ padding: '1rem', width: '50%' }}>
              <SoftBox pr={1}>
              <SoftInput
                type="text"
                placeholder="Search Patients"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value)
                  setPage(0)
                }}
              />
            </SoftBox>
            </div>
            <SoftBox display="flex" justifyContent="end" alignItems="center" width="40%">

<SoftBox display="flex" alignItems="center" gap={2}>
  <SoftTypography variant="body2" style={{ fontSize: '0.8rem', whiteSpace: 'nowrap' }}>Sort Field:</SoftTypography>
  <select
    className="form-select"
    value={sortField}
    onChange={(e) => setSortField(e.target.value)}
    style={{ fontSize: '0.8rem', padding: '8px 8px', marginRight: '10px' }}
  >
    <option value="">Select Field</option>
    <option value="created_at">Created Date</option>
    <option value="lastLogin">Last Login</option>
  </select>
</SoftBox>

{/* Select Sort Order */}
<SoftBox display="flex" alignItems="center" gap={2}>
  <SoftTypography variant="body2" style={{ fontSize: '0.8rem', whiteSpace: 'nowrap' }}>Sort Order:</SoftTypography>
  <select
    className="form-select"
    value={sortOrder}
    onChange={(e) => setSortOrder(e.target.value)}
    style={{ fontSize: '0.8rem', padding: '8px 8px' }}
  >
    <option value="">Select Order</option>
    <option value="asc">Ascending</option>
    <option value="desc">Descending</option>
  </select>
</SoftBox>

</SoftBox>
            </SoftBox>
            {isLoading ?   <SoftBox display="flex" justifyContent="center" alignItems="center" p={3}>
    <Spinner 
      animation="border" 
      role="status" 
      style={{
        width: '3rem',
        height: '3rem',
        borderWidth: '0.3rem',
        color: '#007bff', // Customize to match your theme
        borderRightColor: 'transparent'// Gives a cool effect
      }}
    />
  </SoftBox>
  :  <SoftBox
              sx={{
                '& .MuiTableRow-root:not(:last-child)': {
                  '& td': {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`
                  }
                }
              }}
            >
              {physiotherapistList?.items?.length === 0 ? (<div style={{ textAlign: 'center' }}><SoftTypography variant='h6' color='textSecondary'> No Patient found </SoftTypography></div>) : <Table columns={authorsTableData?.columns} rows={authorsTableData?.rows}/>}
            </SoftBox>
}

            <SoftBox display="flex" justifyContent="center" p={2}>
  <Pagination className='custom-pagination' style={{ display: 'flex', gap: '8px', padding: '10px', borderRadius: '8px', backgroundColor: '#f1f3f5' }}>
    {/* <Pagination.Prev 
      onClick={() => handlePageChange(page - 1)} 
      disabled={page === 0} 
      style={{ padding: '5px 10px', fontSize: '1rem', color: '#007bff', cursor: 'pointer', borderRadius: '4px' }}
    /> */}
    
    {Array.from({ length: totalPages }, (_, idx) => (
      <Pagination.Item
        key={idx}
        active={idx === page}
        onClick={() => handlePageChange(idx)}
        style={{
          fontSize: '0.9rem',
          color: idx === page ? '#ffffff' : '#007bff',
          backgroundColor: idx === page ? '#007bff' : 'transparent',
          borderRadius: '4px',
          border: idx === page ? 'none' : '1px solid #007bff',
          cursor: 'pointer'
        }}
      >
        {idx + 1}
      </Pagination.Item>
    ))}
    
    {/* <Pagination.Next 
      onClick={() => handlePageChange(page + 1)} 
      disabled={page === totalPages - 1} 
      style={{ padding: '5px 10px', fontSize: '1rem', color: '#007bff', cursor: 'pointer', borderRadius: '4px' }}
    /> */}
  </Pagination>
</SoftBox>

          </Card>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>

    <ConfirmModal
  title="Confirm Deletion"
  message="Are you sure you want to delete this patient?"
  isVisible={isModalVisible}
  onConfirm={deletePatient} // Call delete function on confirm
  onCancel={() => setIsModalVisible(false)} // Close modal on cancel
/>

    <Modal
      show={modalOpen}
      onHide={handleCloseModal}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Patient Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
  <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
    {/* Each detail with its icon */}
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <InfoIcon sx={{ color: '#17c1e8' }} />
      <span style={{ fontSize: '14px' }}>Patient Name: {selectedClinic?.name || 'Not provided'}</span>
    </Box>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <EmailIcon sx={{ color: '#17c1e8' }} />
      <span style={{ fontSize: '14px' }}>Patient Email: {selectedClinic?.email || 'Not provided'}</span>
      {selectedClinic?.email && (
      <img src={VerifiedBadge} alt="Verified" style={{ marginLeft: '4px', width: '16px', height: '16px' }} />
      )}
    </Box>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <PhoneIcon sx={{ color: '#17c1e8' }} />
      <span style={{ fontSize: '14px' }}>Patient Phone: {selectedClinic?.phone || 'Not provided'}</span>
      {selectedClinic?.phone && (
      <img src={VerifiedBadge} alt="Verified" style={{ marginLeft: '4px', width: '16px', height: '16px' }} />
      )}
    </Box>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <InfoIcon sx={{ color: '#17c1e8' }} />
      <span style={{ fontSize: '14px' }}>Patient Injured BodyPart : {selectedClinic?.injuredBodyPart || 'Not provided'}</span>
    </Box>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <InfoIcon sx={{ color: '#17c1e8' }} />
      <span style={{ fontSize: '14px' }}>Patient Injury Info : {selectedClinic?.injuryInfo || 'Not provided'}</span>
    </Box>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <LocationOnIcon sx={{ color: '#17c1e8' }} />
      <span style={{ fontSize: '14px' }}>Patient Address: {`${selectedClinic?.address?.street || ''}, ${selectedClinic?.address?.city || ''}, ${selectedClinic?.address?.state || ''}, ${selectedClinic?.address?.country || ''}`.trim() || 'Not provided'}</span>
    </Box>
  </Box>
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DateCalendar
    value={date}
    renderLoading={() => <DayCalendarSkeleton />}
    slots={{
      day: ServerDay
    }}
    slotProps={{
      day: {
        highlightedDays
      }
    }}
    onChange={(newValue) => {
      setDate(newValue)
    }}
  />
                            </LocalizationProvider>
</Modal.Body>

      <Modal.Footer style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
        <Button className='m-2' variant="outlined" color="error" sx={{ color: '#ea0606' }} onClick={handleCloseModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal></>
  )
}

export default Patients
