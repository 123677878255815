import { useNavigate } from 'react-router-dom'
import { post } from './http-hydrate'

export const Auth = () => {
  const register = async (formdata) => {
    //  validate first
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    try {
      const response = await post('/register', formdata, config)
      if (response.status === 200 && response.data.type === 'OK') {
        localStorage.setItem('user', JSON.stringify(response.data.data))
      }
      return response.data
    } catch (error) {
      return new Error('ERROR occured while registering new user')
    }
  }
  const login = async (formdata) => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    try {
      const response = await post('/loginAdmin', formdata, config)
      if (response.status === 200) {
        const userData = response.data.msg
        localStorage.setItem(
          'user',
          JSON.stringify({
            email: userData.email,
            mobile_number: userData.mobile_number,
            name: userData.name,
            token: userData.token,
            _id: userData._id
          })
        )
      }
      return response.data
    } catch (error) {
      return error.response
    }
  }
  const logout = () => {
    const navigate = useNavigate()
    localStorage.removeItem('user')
    localStorage.removeItem('dark')
    navigate('/')
  }
  const getAuthToken = () => {
    return localStorage.getItem('user')
      ? `${JSON.parse(localStorage.getItem('user')).token}`
      : null
  }
  const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem('user'))
  }

  return { register, login, logout, getCurrentUser, getAuthToken }
}
