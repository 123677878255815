import { useState } from 'react'

// react-router-dom components
import { Link, useNavigate } from 'react-router-dom'

// @mui material components
import Switch from '@mui/material/Switch'

// Soft UI Dashboard React components
import SoftBox from 'components/SoftBox'
import SoftTypography from 'components/SoftTypography'
import SoftInput from 'components/SoftInput'
import SoftButton from 'components/SoftButton'

// Authentication layout components
import CoverLayout from 'layouts/authentication/components/CoverLayout'

// Images
import curved9 from 'assets/images/curved-images/curved-6.jpg'
import { Auth } from 'libs/auth' // Import the Auth component

function SignIn () {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [rememberMe, setRememberMe] = useState(false)
  const [error, setError] = useState('')
  const navigate = useNavigate()
  const handleEmailChange = (e) => setEmail(e.target.value)
  const handlePasswordChange = (e) => setPassword(e.target.value)
  const handleRememberMe = () => setRememberMe(!rememberMe)

  const authFunctions = Auth()
  const handleSubmit = async (e) => {
    e.preventDefault()

    // Perform validation here
    if (!email || !password) {
      setError('Please fill in all fields.')
      return false
    } else {
      // Validation successful, you can proceed with sign-in logic here
      setError('') // Clear any previous error
    }

    const formdata = new FormData()
    formdata.append('email', email)
    formdata.append('password', password)
    formdata.append('device', 'Web')
    try {
      const response = await authFunctions.login(formdata)
      if (response.status === 200) {
        navigate('/dashboard')
      } else if (response?.data?.err) {
        setError(response?.data?.err)
      }
    } catch (error) {
      console.error('LOGIN ERROR!!!', error?.response?.data?.err)
    }
  }
  return (
    <CoverLayout
      title="Welcome back"
      description="Enter your email and password to sign in"
      image={curved9}
    >
      <SoftBox component="form" role="form" onSubmit={handleSubmit}>
      {error && (
          <SoftTypography variant="caption" color="error">
            {error}
          </SoftTypography>
      )}
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Email
            </SoftTypography>
          </SoftBox>
          <SoftInput type="email" placeholder="Email" value={email}
            onChange={handleEmailChange} />
        </SoftBox>
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Password
            </SoftTypography>
          </SoftBox>
          <SoftInput type="password" placeholder="Password" value={password}
            onChange={handlePasswordChange}/>
        </SoftBox>
        <SoftBox display="flex" alignItems="center">
          <Switch checked={rememberMe} onChange={handleRememberMe} />
          <SoftTypography
            variant="button"
            fontWeight="regular"
            onClick={handleRememberMe}
            sx={{ cursor: 'pointer', userSelect: 'none' }}
          >
            &nbsp;&nbsp;Remember me
          </SoftTypography>
        </SoftBox>
        <SoftBox mt={4} mb={1}>
          <SoftButton variant="gradient" color="info" fullWidth type="submit">
            sign in
          </SoftButton>
        </SoftBox>
        <SoftBox mt={3} textAlign="center">
          <SoftTypography variant="button" color="text" fontWeight="regular">
            Don&apos;t have an account?{' '}
            <SoftTypography
              component={Link}
              to="/authentication/sign-up"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Sign up
            </SoftTypography>
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </CoverLayout>
  )
}

export default SignIn
