import React, { useState } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DashboardLayout from './LayoutContainers/DashboardLayout'
import DashboardNavbar from './Navbars/DashboardNavbar'
import '../assets/css/main.css'
import { getAuthConfig, post } from 'libs/http-hydrate'
import { useNavigate } from 'react-router-dom'
const AddBodyPart = () => {
  const initialValues = {
    title: '',
    description: '',
    BodyPartImage: null // Change to null
  }
  const [images, setImage] = useState({
    BodyPartImage: null
  })
  const navigate = useNavigate()
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required')
  })
  async function handleCreateExercise (formdata) {
    const formDat = new FormData()
    formDat.append('title', formdata.title)
    formDat.append('description', formdata.description)
    formDat.append('BodyPartImage', images.BodyPartImage)
    const url = '/body'
    try {
      const data = await post(url, formDat, getAuthConfig())
      if (data?.status === 200) {
        navigate('/dashboard')
      }
    } catch (error) {
    }
  }

  const handleSubmit = (values, { setSubmitting }) => {
    handleCreateExercise(values)
    setSubmitting(false)
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>

          <div className="mb-1">
            <label htmlFor="title" className="form-label">
              Title
            </label>
            <Field type="text" name="title" className="form-control" />
            <ErrorMessage name="title" component="div" className="text-danger" />
          </div>

          <div className="mb-1">
            <label htmlFor="description" className="form-label">
              Description
            </label>
            <Field type="text" name="description" className="form-control" />
            <ErrorMessage name="description" component="div" className="text-danger" />
          </div>
          <div className="mb-1">
  <label htmlFor="BodyPartImage" className="form-label">
    Exercise Image
  </label>
  <Field type="file" accept=".jpg, .jpeg, .png, .gif, .webp" name="BodyPartImage" className="form-control" onChange={(e) => {
    setImage((p) => ({ ...p, BodyPartImage: e.target.files[0] }))
  }} />
  <ErrorMessage name="BodyPartImage" component="div" className="text-danger" />
</div>

          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </Form>
      </Formik>
    </DashboardLayout>
  )
}

export default AddBodyPart
