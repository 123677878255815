// @mui material components
import Card from '@mui/material/Card'

// Soft UI Dashboard React components
import SoftBox from 'components/SoftBox'
import SoftTypography from 'components/SoftTypography'

// Soft UI Dashboard React examples
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import Footer from 'examples/Footer'
import Table from 'examples/Tables/Table'
import SoftInput from 'components/SoftInput'

// Data
import { useEffect, useState } from 'react'
import { getAuthConfig, get, deletes } from 'libs/http-hydrate'
import SoftAvatar from 'components/SoftAvatar'
import PropTypes from 'prop-types' // Import PropTypes
import moment from 'moment'
import ConfirmModal from 'layouts/Modal/ConfirmModal'
import { Pagination, Spinner } from 'react-bootstrap'
function BodyPartList () {
  // const { columns: prCols, rows: prRows } = projectsTableData
  const [totalPages, setTotalPages] = useState(0); // Total pages for pagination
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
const [bodyPartToDelete, setBodyPartToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(false)
  const [physiotherapistList, setPhysiotherapistList] = useState({
    items: [],
    has_more: false
  })
  const [page, setPage] = useState(0)
  const [searchQuery, setSearchQuery] = useState('') // State for search query
  useEffect(() => {
    getPhysiotherapistList()
  }, [page, searchQuery])

  async function getPhysiotherapistList () {
    try {
      setIsLoading(true)
      const data = await get(`/bodyPartListAdmin?pageNumber=${page}&searchQuery=${searchQuery}`, getAuthConfig())
      if (data?.status === 200) {
          setPhysiotherapistList((p) => ({ ...p, items: data?.data?.data }))
        setPhysiotherapistList((p) => ({ ...p, has_more: data?.data?.has_more }))
        setTotalPages(Math.ceil(data?.data?.totalPages)); // Assuming 10 items per pages
      }
    } catch (error) {

    } finally {
      setIsLoading(false) // Set isLoading to false in the finally block
    }
  }
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };
  function Author ({ image, name, email }) {
    return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox mr={2}>
        <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="button" fontWeight="medium">
          {name}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {email}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
    )
  }

  function Function ({ job, org }) {
    return (
    <SoftBox display="flex" flexDirection="column">
      <SoftTypography variant="caption" fontWeight="medium" color="text">
        {job}
      </SoftTypography>
      <SoftTypography variant="caption" color="secondary">
        {org}
      </SoftTypography>
    </SoftBox>
    )
  }
  // PropTypes definition for Author
  Author.propTypes = {
    image: PropTypes.string.isRequired, // Assuming 'image' is a string
    name: PropTypes.string.isRequired, // Assuming 'name' is a string
    email: PropTypes.string.isRequired // Assuming 'email' is a string
  }

  // PropTypes definition for Function
  Function.propTypes = {
    job: PropTypes.string.isRequired, // Assuming 'job' is a string
    org: PropTypes.string.isRequired // Assuming 'org' is a string
  }

  const authorsTableData = {
    columns: [
      { name: 'BodyPart', align: 'left' },
      { name: 'created', align: 'center' },
      { name: 'action', align: 'center' }
    ],

    rows: physiotherapistList.items && physiotherapistList.items.map((item) => ({
      BodyPart: (
      <Author image={item.image ? item?.image : ''} name={item.title} email={item.email} />
      ),
      created: (
      <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {moment(item.created).format('DD / MM / YYYY')}
      </SoftTypography>
      ),
      action: (
      <>
      <SoftTypography
        component="a"
        href="#"
        variant="caption"
        color="secondary"
        fontWeight="medium"
      >
        Edit
      </SoftTypography>  /  <SoftTypography
        component="a"
        href="#"
        variant="caption"
        color="secondary"
        fontWeight="medium"
        onClick={() => handleDeleteClick(item._id)} // Add onClick handler here
      >
        Delete
      </SoftTypography>
      </>
      )
    }))
  }
  const handleDeleteClick = (bodyPartId) => {
    setBodyPartToDelete(bodyPartId); // Set the ID of the body part to delete
    setDeleteModalVisible(true); // Open the confirmation modal
  };
  const confirmDelete = async () => {
    const url = `/DeletePhysiotherapist?id=${bodyPartToDelete}`;
    try {
      const data = await deletes(url);
      if (data?.status === 200) {
        setPhysiotherapistList((prevState) => ({
          ...prevState,
          items: prevState.items.filter((item) => item._id !== bodyPartToDelete)
        }));
      }
    } catch (error) {
      console.error('Error deleting body part:', error);
    } finally {
      setDeleteModalVisible(false); // Close the confirmation modal
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">BodyPart table</SoftTypography>
              <div style={{ padding: '1rem' }}>
              <SoftBox pr={1}>
              <SoftInput
                type="text"
                placeholder="Search Physiotherapist"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value)
                  setPage(0)
                }}
              />
              </SoftBox>
              </div>
            </SoftBox>
            {isLoading
              ? (
                <>
                <SoftBox display="flex" justifyContent="center" alignItems="center" p={3}>
     <Spinner 
       animation="border" 
       role="status" 
       style={{
         width: '3rem',
         height: '3rem',
         borderWidth: '0.3rem',
         color: '#007bff', // Customize to match your theme
         borderRightColor: 'transparent'// Gives a cool effect
       }}
     />
   </SoftBox></>
                )
              : (
              <SoftBox
                sx={{
                  '& .MuiTableRow-root:not(:last-child)': {
                    '& td': {
                      borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`
                    }
                  }
                }}
              >
              {physiotherapistList?.items?.length === 0 ? (<div style={{ textAlign: 'center' }}><SoftTypography variant='h6' color='textSecondary'> No BodyPart found </SoftTypography></div>) : <Table columns={authorsTableData?.columns} rows={authorsTableData?.rows}/>}
              </SoftBox>
                )}
  <SoftBox display="flex" justifyContent="center" p={2}>
  <Pagination className='custom-pagination' style={{ display: 'flex', gap: '8px', padding: '10px', borderRadius: '8px', backgroundColor: '#f1f3f5' }}>
    {/* <Pagination.Prev 
      onClick={() => handlePageChange(page - 1)} 
      disabled={page === 0} 
      style={{ padding: '5px 10px', fontSize: '1rem', color: '#007bff', cursor: 'pointer', borderRadius: '4px' }}
    /> */}
    
    {Array.from({ length: totalPages }, (_, idx) => (
      <Pagination.Item
        key={idx}
        active={idx === page}
        onClick={() => handlePageChange(idx)}
        style={{
          fontSize: '0.9rem',
          color: idx === page ? '#ffffff' : '#007bff',
          backgroundColor: idx === page ? '#007bff' : 'transparent',
          borderRadius: '4px',
          border: idx === page ? 'none' : '1px solid #007bff',
          cursor: 'pointer'
        }}
      >
        {idx + 1}
      </Pagination.Item>
    ))}
    
    {/* <Pagination.Next 
      onClick={() => handlePageChange(page + 1)} 
      disabled={page === totalPages - 1} 
      style={{ padding: '5px 10px', fontSize: '1rem', color: '#007bff', cursor: 'pointer', borderRadius: '4px' }}
    /> */}
  </Pagination>
</SoftBox>
          </Card>

        </SoftBox>
        {/* <Card>
          <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <SoftTypography variant="h6">Phys table</SoftTypography>
          </SoftBox>
          <SoftBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            <Table columns={prCols} rows={prRows} />
          </SoftBox>
        </Card> */}
      </SoftBox>
      <Footer />
      <ConfirmModal
  title="Confirm Delete"
  message="Are you sure you want to delete this body part?"
  onConfirm={confirmDelete} // Call confirmDelete on confirmation
  onCancel={() => setDeleteModalVisible(false)} // Close modal on cancel
  isVisible={deleteModalVisible} // Control visibility of the modal
/>

    </DashboardLayout>
  )
}

export default BodyPartList
