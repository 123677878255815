import React, { useEffect, useState } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DashboardLayout from './LayoutContainers/DashboardLayout'
import DashboardNavbar from './Navbars/DashboardNavbar'
import '../assets/css/main.css'
import { post, get, getAuthConfig } from 'libs/http-hydrate'
import { useLocation, useNavigate } from 'react-router-dom'
import { Auth } from 'libs/auth'
import Multiselect from 'multiselect-react-dropdown'

const AddDescExercise = () => {
  const initialValues = {
    title: '',
    code: '' // Add this line
  }
  const [apiError, setApiError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Language Code is required'),
    code: Yup.string().required('Description is required') // Add this line
  })
  const [physiotherapistList, setPhysiotherapistList] = useState({
    items: [],
    has_more: false
  })
  useEffect(() => {
    getPhysiotherapistList()
  }, [])
  async function handleCreateExercise (formdata) {
    setIsLoading(true) // Start loading

    const authFunctions = Auth()
    const formDat = new URLSearchParams()
    formDat.append('exerciseId', location?.state?.item?._id)
    formDat.append('language', formdata.title)
    formDat.append('text', formdata.code) // Add this line
    const url = '/api/exerciseDescription'
    try {
      const data = await post(url, formDat.toString(), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'Web ' + 'Admin ' + authFunctions.getAuthToken()
        }
        // Add any other necessary configurations here
      })
      if (data?.status === 200) {
        navigate('/dashboard')
      } else {
        setApiError(data?.message || 'An error occurred')
      }

      setIsLoading(false) // Stop loading after API call is complete
    } catch (error) {
      setIsLoading(false) // Stop loading after API call is complet
      setApiError(error.response?.data?.errors[0]?.msg || 'An error occurred')
    }
  }

  const handleSubmit = (values, { setSubmitting }) => {
    handleCreateExercise(values)
    setSubmitting(false)
  }
  async function getPhysiotherapistList () {
    try {
    //   setIsLoading(true)
      const data = await get('/api/languag', getAuthConfig())
      if (data?.status === 200) {
        setPhysiotherapistList((p) => ({ ...p, items: physiotherapistList?.items?.concat(data?.data?.msg) }))
        setPhysiotherapistList((p) => ({ ...p, has_more: data?.data?.has_more }))
      }
    } catch (error) {

    } finally {
      setIsLoading(false) // Set isLoading to false in the finally block
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Formik
  initialValues={initialValues}
  validationSchema={validationSchema}
  onSubmit={handleSubmit}
>
  {({ setFieldValue, values }) => (
    <Form>

<div className="mb-1">
        <label htmlFor="title" className="form-label">
          Title
        </label>

        <Multiselect
          options={physiotherapistList.items.map(item => ({ key: item.name, value: item.code }))}
          selectedValues={values.name}
          onSelect={(selectedList) => {
            setFieldValue('title', selectedList[0]?.value || '')
          }}
          onRemove={(selectedList) => {
            setFieldValue('title', '')
          }}
          selectionLimit={1}
          displayValue="key"
          placeholder="Select Language"
          // ... other props ...
        />
        <ErrorMessage name="title" component="div" className="text-danger" />
      </div>
      <div className="mb-1">
    <label htmlFor="code" className="form-label">
      Description
    </label>
    <Field as="textarea" name="code" className="form-control" />
    <ErrorMessage name="code" component="div" className="text-danger" />
  </div>

      {apiError && <div className="text-danger">{apiError}</div>}

<button type="submit" className="btn btn-primary" disabled={isLoading}>
    {isLoading ? 'Submitting...' : 'Submit'}
  </button>
        </Form>
  )}
      </Formik>
    </DashboardLayout>
  )
}

export default AddDescExercise
