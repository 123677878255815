import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Spinner,
  Alert
} from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import { Auth } from 'libs/auth'
import { post, getAuthConfig, get } from 'libs/http-hydrate'
import '../../assets/css/ticket.css'

function TicketReply() {
  const navigate = useNavigate()
  const location = useLocation()
  const ticket = location?.state?.ticket
  const [newMessage, setNewMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [replies, setReplies] = useState(ticket?.replies || [])
  const { getCurrentUser } = Auth()
  const user = getCurrentUser()
  const [file, setFile] = useState(null)
  const handleFileChange = (e) => {
    setFile(e.target.files[0]) // Capture selected file
  }
  const handleSendMessage = async (e) => {
    e.preventDefault()
    setError('')
    if (!newMessage.trim() && !file) {
      setError('Reply Message or File is required')
      return
    }
    setLoading(true)
    try {
      const formData = new FormData()
      formData.append('message', newMessage)
      if (file) {
        formData.append('attachment', file) // Attach file if uploaded
      }
      const authFunctions = Auth()
      const response = await post(
        `/tickets/reply?ticketId=${ticket?._id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Web ' + 'Admin ' + authFunctions.getAuthToken()
          }
        }
      )

      // Update the replies state with the new reply
      setReplies(response.data.replies)
      setNewMessage('') // Clear the input box after sending
    } catch (err) {
      setError(err.response?.data?.error || 'Error replying to ticket')
    } finally {
      setLoading(false)
    }
  }

  const handleStatusChange = async () => {
    setLoading(true)
    try {
      const newStatus = ticket?.status === 'open' ? 'closed' : 'open'
      const response = await get(
        `/tickets/status?ticketId=${ticket?._id}&status=${newStatus}`,
        getAuthConfig()
      )

      // Update the ticket status
      ticket.status = response.data.status
      setLoading(false)
    } catch (err) {
      setError(err.response?.data?.error || 'Error updating ticket status')
      setLoading(false)
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Container className='chat-container'>
        <div className='chat-header d-flex'>
          <div style={{ cursor: 'pointer' }} onClick={(e) => {
            e.preventDefault()
            navigate('/support')
          }}>
            <i className='fa fa-arrow-left' aria-hidden='true'></i>
          </div>
          <div style={{ marginLeft: '10px' }}>
            <span style={{ fontSize: '1rem', fontWeight: '500' }}>
              {ticket?.title}{' '}
            </span>{' '}
            <br />
            <span style={{ fontSize: '0.85rem' }}>{ticket?.description} </span>
          </div>
            <Button
              variant={ticket?.status === 'open' ? 'danger' : 'success'}
              onClick={handleStatusChange}
              className='ml-auto'
              disabled={loading}
            >
              {loading ? <Spinner animation='border' size='sm' /> : ticket?.status === 'open' ? 'Close Ticket' : 'Reopen Ticket'}
            </Button>
          
        </div>
        <Row className='chat-content'>
          {replies.map((reply, index) => (
            <Col
              key={index}
              xs={12}
              className={`d-flex ${
                reply.model_typereply !== 'Physiotherapist'
                  ? 'justify-content-start'
                  : 'justify-content-end'
              }`}
            >
              <div
                className={`message-box ${
                  reply.model_typereply !== 'Physiotherapist'
                    ? 'support-message'
                    : 'patient-message'
                }`}
              >
                {reply?.image ?  <img src={reply?.image} className='img-fluid'/> : <div className='message-text'>{reply.message}</div>}
                <div className='message-time'>
                  {moment(reply.repliedAt).format('hh:mm A')}
                </div>
              </div>
            </Col>
          ))}
        </Row>
        <Row className='chat-input'>
          <Col xs={12}>

            {ticket?.status === 'open'
              ? (
                <>
                  {error && <Alert variant='danger'>{error}</Alert>}
                  <Form onSubmit={handleSendMessage}>
                    <Form.Group className='d-flex'>
                      <Form.Control
                        type='text'
                        placeholder='Type your message...'
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        className='chat-input-box'
                        disabled={loading}
                      />
                        <Form.Control
                      type="file"
                      onChange={handleFileChange}
                      className="chat-file-input"
                      disabled={loading}
                    />
                      <Button
                        type='submit'
                        className='chat-send-button'
                        disabled={loading}
                      >
                        {loading ? <Spinner animation='border' size='sm' /> : 'Send'}
                      </Button>
                    </Form.Group>
                  </Form>
                </>
              )
              : (
                <div className='text-center'>
                  <Button
                    type='submit'
                    className='chat-send-button'
                    disabled={loading}
                  >
                    Closed
                  </Button>
                </div>
              )
            }
          </Col>
        </Row>
      </Container>
    </DashboardLayout>
  )
}

export default TicketReply
