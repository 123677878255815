import React from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DashboardLayout from './LayoutContainers/DashboardLayout'
import DashboardNavbar from './Navbars/DashboardNavbar'
import '../assets/css/main.css'
import { post } from 'libs/http-hydrate'
import { useNavigate } from 'react-router-dom'
import { Auth } from 'libs/auth'
const AddMovementType = () => {
  const initialValues = {
    title: ''
  }
  const navigate = useNavigate()
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required')
  })
  async function handleCreateExercise (formdata) {
    const authFunctions = Auth()
    const formDat = new URLSearchParams()
    formDat.append('title', formdata.title)
    const url = '/createMovementType'
    try {
      const data = await post(url, formDat.toString(), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'Web ' + 'Admin ' + authFunctions.getAuthToken()
        }
        // Add any other necessary configurations here
      })
      if (data?.status === 200) {
        navigate('/dashboard')
      }
    } catch (error) {
    }
  }

  const handleSubmit = (values, { setSubmitting }) => {
    handleCreateExercise(values)
    setSubmitting(false)
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>

          <div className="mb-1">
            <label htmlFor="title" className="form-label">
              Title
            </label>
            <Field type="text" name="title" className="form-control" />
            <ErrorMessage name="title" component="div" className="text-danger" />
          </div>
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </Form>
      </Formik>
    </DashboardLayout>
  )
}

export default AddMovementType
