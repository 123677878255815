// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-pagination .page-link{
    border: none !important;
    background-color: transparent !important;
    padding: 8px 12px !important;
}

`, "",{"version":3,"sources":["webpack://./src/assets/css/pagination.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,wCAAwC;IACxC,4BAA4B;AAChC","sourcesContent":[".custom-pagination .page-link{\n    border: none !important;\n    background-color: transparent !important;\n    padding: 8px 12px !important;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
