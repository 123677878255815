// @mui material components
import Card from '@mui/material/Card'
// Soft UI Dashboard React components
import SoftBox from 'components/SoftBox'
import SoftTypography from 'components/SoftTypography'

// Soft UI Dashboard React examples
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import Footer from 'examples/Footer'
import Table from 'examples/Tables/Table'
import SoftInput from 'components/SoftInput'

// Data
import React, { useEffect, useState } from 'react'
import { getAuthConfig, get } from 'libs/http-hydrate'
import SoftAvatar from 'components/SoftAvatar'
import PropTypes from 'prop-types' // Import PropTypes
import moment from 'moment'
import Box from '@mui/material/Box'
import Modal from 'react-bootstrap/Modal'
import InfoIcon from '@mui/icons-material/Info'
import Button from '@mui/material/Button'
import AccessTimeIcon from '@mui/icons-material/AccessTimeFilled'
import AccessibleIcon from '@mui/icons-material/Accessible'
import { useNavigate } from 'react-router-dom'
function Version () {
  // const { columns: prCols, rows: prRows } = projectsTableData
  const [isLoading, setIsLoading] = useState(false)
  const [physiotherapistList, setPhysiotherapistList] = useState({
    items: [],
    has_more: false,
    total_pages: 0
  })
  const navigate = useNavigate()
  const [page, setPage] = useState(0)
  const [searchQuery, setSearchQuery] = useState('') // State for search query
  useEffect(() => {
    getPhysiotherapistList()
  }, [page, searchQuery])

  const [modalOpen, setModalOpen] = useState(false)
  const [selectedClinic, setSelectedClinic] = useState(null)

  // Function to handle opening the modal
  const handleOpenModal = (clinic) => {
    setSelectedClinic(clinic)
    setModalOpen(true) // Open the modal
  }

  // Function to handle closing the modal
  const handleCloseModal = () => {
    setModalOpen(false)
  }
  async function getPhysiotherapistList () {
    try {
    //   setIsLoading(true)
      const data = await get('/version', getAuthConfig())
      if (data?.status === 200) {
        // if (page === 0) {
        setPhysiotherapistList((p) => ({ ...p, items: data?.data?.msg }))
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      }
    } catch (error) {

    } finally {
      setIsLoading(false) // Set isLoading to false in the finally block
    }
  }

  function Author ({ image, name, email, onClick }) {
    return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5} onClick={onClick}>
      <SoftBox mr={2}>
        <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="button" fontWeight="medium">
          {name}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {email}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
    )
  }

  function Function ({ job, org }) {
    return (
    <SoftBox display="flex" flexDirection="column">
      <SoftTypography variant="caption" fontWeight="medium" color="text">
        {job}
      </SoftTypography>
      <SoftTypography variant="caption" color="secondary">
        {org}
      </SoftTypography>
    </SoftBox>
    )
  }
  // PropTypes definition for Author
  Author.propTypes = {
    image: PropTypes.string.isRequired, // Assuming 'image' is a string
    name: PropTypes.string.isRequired, // Assuming 'name' is a string
    email: PropTypes.string.isRequired // Assuming 'email' is a string
  }

  // PropTypes definition for Function
  Function.propTypes = {
    job: PropTypes.string.isRequired, // Assuming 'job' is a string
    org: PropTypes.string.isRequired // Assuming 'org' is a string
  }

  const authorsTableData = {
    columns: [
      { name: 'Title', align: 'left' },
      { name: 'Platform', align: 'center' },
      { name: 'Note', align: 'center' },
      { name: 'ForceUpdate', align: 'left' },
      { name: 'action', align: 'center' }
    ],

    rows: physiotherapistList.items && physiotherapistList.items.map((item) => ({
      Title: (
      <Author name={item.platform} email={item.version_number} onClick={() => handleOpenModal(item)}/>
      ),
      Platform: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {item?.update_type}
        </SoftTypography>
      ),
      Note: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            {item?.release_notes}
        </SoftTypography>
      ),
      ForceUpdate: (
      <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {item?.force_update ? 'Yes' : 'No'}
      </SoftTypography>
      ),
      action: (
      <>
      <SoftTypography
        component="a"
        variant="caption"
        color="secondary"
        fontWeight="medium"
        onClick={() => {
          navigate('/editVersion', { state: { version: item } })
        }}
      >
      &nbsp;  Edit
      </SoftTypography>
      </>
      )
    }))
  }
  function generatePageNumbers () {
    const pageNumbers = []
    for (let i = 0; i < physiotherapistList.total_pages; i++) {
      pageNumbers.push(i)
    }
    return pageNumbers
  }

  return (
    <>
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Version table</SoftTypography>
              <div style={{ padding: '1rem' }}>
              <SoftBox pr={1}>
              <SoftInput
                type="text"
                placeholder="Search Exercise"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value)
                  setPage(0)
                  getPhysiotherapistList()
                }}
              />
              </SoftBox>
              </div>
            </SoftBox>
            {isLoading
              ? (
              <>
                <SoftBox display="flex" justifyContent="center" alignItems="center" p={3}>
                  <SoftTypography variant="h6">Loading ... </SoftTypography>
                </SoftBox></>
                )
              : (
              <SoftBox
                sx={{
                  '& .MuiTableRow-root:not(:last-child)': {
                    '& td': {
                      borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`
                    }
                  }
                }}
              >
              {physiotherapistList?.items?.length === 0 ? (<div style={{ textAlign: 'center' }}><SoftTypography variant='h6' color='textSecondary'> No Exercise found </SoftTypography></div>) : <Table columns={authorsTableData?.columns} rows={authorsTableData?.rows}/>}
              </SoftBox>
                )}

<div className='mt-3' style={{ display: 'flex', justifyContent: 'center' }}>
  <ul className="pagination">
    {generatePageNumbers().map((pageNumber) => (
      <li key={pageNumber} className={`page-item ${page === pageNumber ? 'active' : ''}`}>
        <a className="page-link" onClick={() => setPage(pageNumber)}>
          {pageNumber + 1}
        </a>
      </li>
    ))}
    {/* <li className={`page-item ${page === physiotherapistList.total_pages - 1 ? 'disabled' : ''}`}>
      <a className="page-link" onClick={() => setPage(physiotherapistList.total_pages - 1)}>
        Last
      </a>
    </li> */}
  </ul>

</div>

            {/* <SoftBox display="flex" justifyContent="center" p={2}>
  <button
    onClick={() => setPage(current => current - 1)}
    disabled={page === 0}
    className='btn btn-primary m-2'
  >
    Previous
  </button>
  <button
    onClick={() => setPage(current => current + 1)}
    disabled={!physiotherapistList?.has_more}
    className='btn btn-primary m-2'
  >
    Next
  </button>
</SoftBox> */}

          </Card>

        </SoftBox>
        {/* <Card>
          <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <SoftTypography variant="h6">Phys table</SoftTypography>
          </SoftBox>
          <SoftBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            <Table columns={prCols} rows={prRows} />
          </SoftBox>
        </Card> */}
      </SoftBox>
      <Footer />
    </DashboardLayout>
    <Modal
      show={modalOpen}
      onHide={handleCloseModal}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Exercise Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
        {/* Each detail with its icon */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <InfoIcon sx={{ color: '#17c1e8' }} />
          <span style={{ fontSize: '14px' }}> {selectedClinic?.title}</span>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <InfoIcon sx={{ color: '#17c1e8' }} />
          <span style={{ fontSize: '14px', maxHeight: '50vh', overflow: 'scroll' }} dangerouslySetInnerHTML={{ __html: selectedClinic?.description }}></span>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <AccessibleIcon sx={{ color: '#17c1e8' }} />
          <span style={{ fontSize: '14px' }}>
{selectedClinic?.body_part_id &&
                                selectedClinic?.body_part_id.length > 0 && (
                                  <div className="text-part ps-3 mt-2">
                                    <p style={{ cursor: 'pointer' }}>
                                      {selectedClinic?.body_part_id &&
                                        selectedClinic?.body_part_id.length > 0 &&
                                        selectedClinic?.body_part_id.map(
                                          (itemEq, ind) => (
                                            <React.Fragment key={ind}>
                                              {ind > 0 && ' - '}{' '}
                                              {/* Display a hyphen for all elements except the first one */}
                                              {itemEq?.title}
                                            </React.Fragment>
                                          )
                                        )}
                                    </p>
                                  </div>
)}</span>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <AccessTimeIcon sx={{ color: '#17c1e8' }} />
          <span style={{ fontSize: '14px' }}>Posted At: {moment(selectedClinic?.created_at).format('DD / MM / YYYY')}</span>
        </Box>
      </Box>
      </Modal.Body>
      <Modal.Footer style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
        <Button className='m-2' variant="outlined" color="error" sx={{ color: '#ea0606' }} onClick={handleCloseModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal></>
  )
}

export default Version
