// @mui material components
import Card from '@mui/material/Card'
import Pagination from 'react-bootstrap/Pagination';

// Soft UI Dashboard React components
import SoftBox from 'components/SoftBox'
import SoftTypography from 'components/SoftTypography'

// Soft UI Dashboard React examples
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import Footer from 'examples/Footer'
import Table from 'examples/Tables/Table'
import SoftInput from 'components/SoftInput'
import Switch from '@mui/material/Switch'
// Data
import { useEffect, useState } from 'react'
import { getAuthConfig, get, deletes, post } from 'libs/http-hydrate'
import SoftAvatar from 'components/SoftAvatar'
import SoftBadge from 'components/SoftBadge'
import PropTypes from 'prop-types' // Import PropTypes
import moment from 'moment'
import { Link, useNavigate } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import Box from '@mui/material/Box'
import InfoIcon from '@mui/icons-material/Info'
import EmailIcon from '@mui/icons-material/Email'
import PhoneIcon from '@mui/icons-material/Phone'
import Button from '@mui/material/Button'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import VerifiedBadge from 'assets/images/quality.png'
import { Auth } from 'libs/auth'
import ConfirmModal from 'layouts/Modal/ConfirmModal'
import { Spinner } from 'react-bootstrap'

function Tables () {
  // const { columns: prCols, rows: prRows } = projectsTableData
  const navigate = useNavigate()
    const [sortField, setSortField] = useState('created_at'); // Default sort field
  const [sortOrder, setSortOrder] = useState('asc'); // Default sort order
  const [totalPages, setTotalPages] = useState(0); // Total pages for pagination
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [physiotherapistList, setPhysiotherapistList] = useState({
    items: [],
    has_more: false
  })
  const [isDeleting, setIsDeleting] = useState(false) // To track deletion loading state
  const [selectedPhysio, setSelectedPhysio] = useState(null) // To store the selected physiotherapist for deletion
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [cart, setCart] = useState()
  const [page, setPage] = useState(0)
  const [searchQuery, setSearchQuery] = useState('') // State for search query
  const [selectedClinic, setSelectedClinic] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [editError, setEditError] = useState('')
  const [deleteMessage, setDeleteMessage] = useState('')
  // Function to handle opening the modal
  const handleOpenModal = (clinic) => {
    setSelectedClinic(clinic)
    setModalOpen(true) // Open the modal
  }

  // Function to handle closing the modal
  const handleCloseModal = () => {
    setModalOpen(false)
  }
  useEffect(() => {
    getPhysiotherapistList()
  }, [page, searchQuery, sortField, sortOrder])
  const handleDeleteClick = (physiotherapist) => {
    setSelectedPhysio(physiotherapist); // Store the physiotherapist to delete
    setIsModalVisible(true); // Show confirmation modal
  };
  async function deletePhysiotherapist() {
    setIsDeleting(true); // Start the loading spinner
    setDeleteMessage('Deleting physiotherapist...'); // Set delete message
  
    const url = `/DeletePhysiotherapist?id=${selectedPhysio._id}`;
    try {
      const data = await deletes(url);
      if (data?.status === 200) {
        setPhysiotherapistList((prevState) => ({
          ...prevState,
          items: removeItemWithId(prevState.items, selectedPhysio._id)
        }));
        setDeleteMessage('Physiotherapist successfully deleted.');
      }
    } catch (error) {
      setDeleteMessage('Error deleting physiotherapist. Please try again.');
    } finally {
      setIsDeleting(false); // Stop the spinner
      setIsModalVisible(false); // Hide the confirmation modal
    }
  }
    
  async function getPhysiotherapistList() {
    try {
      setIsLoading(true);
      const data = await get(
        `/getAllPhysiotherapistList?pageNumber=${page}&searchQuery=${searchQuery}&sortField=${sortField}&sortOrder=${sortOrder}`,
        getAuthConfig()
      );
      if (data?.status === 200) {
        setPhysiotherapistList((p) => ({
          ...p,
          items: data?.data?.msg,
          has_more: data?.data?.has_more
        }));
        setTotalPages(Math.ceil(data?.data?.totalPages)); // Assuming 10 items per pages
      }
    } catch (error) {
      console.error('Error fetching physiotherapist list:', error);
    } finally {
      setIsLoading(false); // Set isLoading to false in the finally block
    }
  }
  async function toggleAccess (id, toggle) {
    try {
      setIsLoading(true)
      const endpoint = toggle
        ? `/updateApprovalStatusPhysiotherapist?id=${id}&adminApprovedList=true`
        : `/updateApprovalStatusPhysiotherapist?id=${id}`

      const data = await get(endpoint, getAuthConfig())
      if (data?.status === 200) {
        setPhysiotherapistList((prevList) => {
          // Find the index of the item that needs to be updated
          const index = prevList.items.findIndex(item => item._id === id)
          if (index !== -1) {
            // Create a new array for immutability
            const updatedItems = [...prevList.items]
            // Update the adminApprovedList property for the specified item
            updatedItems[index] = {
              ...updatedItems[index],
              adminApprovedList: toggle
            }
            // Return the updated state
            return { ...prevList, items: updatedItems }
          }
          return prevList // Return the previous state if the item is not found
        })
      }
    } catch (error) {

    } finally {
      setIsLoading(false) // Set isLoading to false in the finally block
    }
  }
  async function toggleBadge (id, toggle) {
    try {
      setIsLoading(true)
      const endpoint = toggle
        ? `/updateApprovalStatusPhysiotherapistBadge?id=${id}&badge=true`
        : `/updateApprovalStatusPhysiotherapistBadge?id=${id}`

      const data = await get(endpoint, getAuthConfig())
      if (data?.status === 200) {
        setPhysiotherapistList((prevList) => {
          // Find the index of the item that needs to be updated
          const index = prevList.items.findIndex(item => item._id === id)
          if (index !== -1) {
            // Create a new array for immutability
            const updatedItems = [...prevList.items]
            // Update the adminApprovedList property for the specified item
            updatedItems[index] = {
              ...updatedItems[index],
              adminBadgeVerified: toggle
            }
            // Return the updated state
            return { ...prevList, items: updatedItems }
          }
          return prevList // Return the previous state if the item is not found
        })
      }
    } catch (error) {

    } finally {
      setIsLoading(false) // Set isLoading to false in the finally block
    }
  }

  async function toggleAccessRemove (id, toggle) {
    try {
      setIsLoading(true)
      const endpoint = toggle
        ? `/removeAccessPhysiotherapist?id=${id}&removeAccess=true`
        : `/removeAccessPhysiotherapist?id=${id}`

      const data = await get(endpoint, getAuthConfig())
      if (data?.status === 200) {
        setPhysiotherapistList((prevList) => {
          // Find the index of the item that needs to be updated
          const index = prevList.items.findIndex(item => item._id === id)
          if (index !== -1) {
            // Create a new array for immutability
            const updatedItems = [...prevList.items]
            // Update the adminApprovedList property for the specified item
            updatedItems[index] = {
              ...updatedItems[index],
              RemoveAccess: toggle
            }
            // Return the updated state
            return { ...prevList, items: updatedItems }
          }
          return prevList // Return the previous state if the item is not found
        })
      }
    } catch (error) {

    } finally {
      setIsLoading(false) // Set isLoading to false in the finally block
    }
  }
  function Author ({ clinic, image, name, email, onClick }) {
    return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5} onClick={onClick}>
      <SoftBox mr={2}>
        <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="button" fontWeight="medium">
          {name}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {email}
          <img src={VerifiedBadge} alt="Verified" style={{ marginLeft: '4px', width: '16px', height: '16px' }} />
          <br />
          {clinic?.name && 
          <SoftBadge
        variant="gradient"
        badgeContent={ 'Clinic : ' + clinic?.name }
        color={'success'}
        size="xs"
        container
      />}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
    )
  }

  function Function ({ job, org }) {
    return (
    <SoftBox display="flex" flexDirection="column">
      <SoftTypography variant="caption" fontWeight="medium" color="text">
        {job}
      </SoftTypography>
      <SoftTypography variant="caption" color="secondary">
        {org}
      </SoftTypography>
    </SoftBox>
    )
  }
  // PropTypes definition for Author
  Author.propTypes = {
    image: PropTypes.string.isRequired, // Assuming 'image' is a string
    name: PropTypes.string.isRequired, // Assuming 'name' is a string
    email: PropTypes.string.isRequired // Assuming 'email' is a string
  }

  // PropTypes definition for Function
  Function.propTypes = {
    job: PropTypes.string.isRequired, // Assuming 'job' is a string
    org: PropTypes.string.isRequired // Assuming 'org' is a string
  }

  const authorsTableData = {
    columns: [
      { name: 'Physiotherapist', align: 'left' },
      { name: 'designation', align: 'left' },
      { name: 'panelAccess', align: 'center' },
      { name: 'patient', align: 'center' },
      { name: 'created', align: 'center' },
      { name: 'lastLogin', align: 'center' },
      { name: 'Patient-Panel-Access', align: 'center' },
      { name: 'Verified-Badge', align: 'center' },
      { name: 'action', align: 'center' }
    ],

    rows: physiotherapistList.items && physiotherapistList.items.map((item) => ({
      Physiotherapist: (
      <Author clinic={item?.associatedEntity} image={item.profile_image ? item?.profile_image : ''} name={item.name} email={item.email ? item?.email : item?.mobile} onClick={() => handleOpenModal(item)}/>
      ),
      panelAccess: (
        <Switch checked={item?.RemoveAccess}
        onChange={() => toggleAccessRemove(item._id, !item.RemoveAccess)} // Define this function to handle access toggling
        color="secondary" />
      ),
      designation: <Function job={item.designation} org={item.hospital_name} />,
      patient: (
      <Link to={'/patientByPhysio'} state={{ physioId: item?._id }}>
      <SoftBadge
        variant="gradient"
        badgeContent={ item?.patientCount}
        color={item?.patientCount > 0 ? 'success' : 'secondary'}
        size="xs"
        container
      /></Link>
      ),
      created: (
      <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {moment(item.created_at).format('DD / MM / YYYY')}
      </SoftTypography>
      ),
      lastLogin: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
     {item.lastLoggedIn && moment(item.lastLoggedIn).format('DD / MM / YYYY hh:mm A')}
      </SoftTypography>
      ),
      'Patient-Panel-Access': (
        <Switch checked={item?.adminApprovedList}
        onChange={() => toggleAccess(item._id, !item.adminApprovedList)} // Define this function to handle access toggling
        color="secondary" />
      ),
      'Verified-Badge': (
        <Switch checked={item?.adminBadgeVerified}
        onChange={() => toggleBadge(item._id, !item.adminBadgeVerified)} // Define this function to handle access toggling
        color="secondary" />
      ),
      action: (
      <>
      <SoftTypography
        component="a"
        variant="caption"
        color="secondary"
        fontWeight="medium"
        onClick={() => {
          handleShow()
          setCart(item)
        }} cursor="pointer" >
        Credi Edit
      </SoftTypography>  /
      <SoftTypography
        component="a"
        variant="caption"
        color="secondary"
        fontWeight="medium"
        cursor="pointer" 
        onClick={() => {
          navigate('/EditPhysiotherapist', { state: { physiotherapist: item } })
        }} >
        Edit
      </SoftTypography>  /  <SoftTypography
        component="a"
        href="#"
        variant="caption"
        color="secondary"
        fontWeight="medium"
        onClick={() => handleDeleteClick(item)} // Trigger confirmation modal
        >
        Delete
      </SoftTypography>
      </>
      )
    }))
  }

  function removeItemWithId (array, patientId) {
    return array.filter((item) => item._id !== patientId)
  }
  async function handleEditCredit () {
    setIsEditing(true) // Start the loading state
    setEditError('') // Reset the error message
    const url = '/physiotherapist/credits/edit'
    try {
      const authFunctions = Auth()
      const formdata = { id: cart?._id, credits: cart?.credits }
      await post(url, formdata, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'Web ' + 'Admin ' + authFunctions.getAuthToken()
        }
        // Add any other necessary configurations here
      })
      setIsEditing(false) // Stop the loading state
      handleClose()
    } catch (error) {
      setIsEditing(false) // Stop the loading state
      setEditError('Failed to update credits. Please try again.') // Set the error message
    }
  }
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  return (
    <>
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Physiothepist table</SoftTypography>
           
              <div style={{ padding: '1rem', width: '50%' }}>
              <SoftBox pr={1}>
              <SoftInput
                type="text"
                placeholder="Search Physiotherapist"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value)
                  setPage(0)
                }}
              />
              </SoftBox>
              </div>
              <SoftBox display="flex" justifyContent="end" alignItems="center" width="40%">

<SoftBox display="flex" alignItems="center" gap={2}>
  <SoftTypography variant="body2" style={{ fontSize: '0.8rem', whiteSpace: 'nowrap' }}>Sort Field:</SoftTypography>
  <select
    className="form-select"
    value={sortField}
    onChange={(e) => setSortField(e.target.value)}
    style={{ fontSize: '0.8rem', padding: '8px 8px', marginRight: '10px' }}
  >
    <option value="">Select Field</option>
    <option value="created_at">Created Date</option>
    <option value="lastLogin">Last Login</option>
  </select>
</SoftBox>

{/* Select Sort Order */}
<SoftBox display="flex" alignItems="center" gap={2}>
  <SoftTypography variant="body2" style={{ fontSize: '0.8rem', whiteSpace: 'nowrap' }}>Sort Order:</SoftTypography>
  <select
    className="form-select"
    value={sortOrder}
    onChange={(e) => setSortOrder(e.target.value)}
    style={{ fontSize: '0.8rem', padding: '8px 8px' }}
  >
    <option value="">Select Order</option>
    <option value="asc">Ascending</option>
    <option value="desc">Descending</option>
  </select>
</SoftBox>

</SoftBox>

            </SoftBox>
            {isDeleting && (
  <SoftBox display="flex" justifyContent="center" alignItems="center" p={3}>
    <Spinner animation="border" role="status" />
    <SoftTypography variant="caption" color="text" fontWeight="medium">
      {deleteMessage}
    </SoftTypography>
  </SoftBox>
)}

            {isLoading
              ? (
              <>
               <SoftBox display="flex" justifyContent="center" alignItems="center" p={3}>
    <Spinner 
      animation="border" 
      role="status" 
      style={{
        width: '3rem',
        height: '3rem',
        borderWidth: '0.3rem',
        color: '#007bff', // Customize to match your theme
        borderRightColor: 'transparent'// Gives a cool effect
      }}
    />
  </SoftBox></>
                )
              : (
              <SoftBox
                sx={{
                  '& .MuiTableRow-root:not(:last-child)': {
                    '& td': {
                      borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`
                    }
                  }
                }}
              >
              {physiotherapistList?.items?.length === 0 ? (<div style={{ textAlign: 'center' }}><SoftTypography variant='h6' color='textSecondary'> No Physiotherapist found </SoftTypography></div>) : <Table columns={authorsTableData?.columns} rows={authorsTableData?.rows}/>}
              </SoftBox>
                )} 
<SoftBox display="flex" justifyContent="center" p={2}>
  <Pagination className='custom-pagination' style={{ display: 'flex', gap: '8px', padding: '10px', borderRadius: '8px', backgroundColor: '#f1f3f5' }}>
    {/* <Pagination.Prev 
      onClick={() => handlePageChange(page - 1)} 
      disabled={page === 0} 
      style={{ padding: '5px 10px', fontSize: '1rem', color: '#007bff', cursor: 'pointer', borderRadius: '4px' }}
    /> */}
    
    {Array.from({ length: totalPages }, (_, idx) => (
      <Pagination.Item
        key={idx}
        active={idx === page}
        onClick={() => handlePageChange(idx)}
        style={{
          fontSize: '0.9rem',
          color: idx === page ? '#ffffff' : '#007bff',
          backgroundColor: idx === page ? '#007bff' : 'transparent',
          borderRadius: '4px',
          border: idx === page ? 'none' : '1px solid #007bff',
          cursor: 'pointer'
        }}
      >
        {idx + 1}
      </Pagination.Item>
    ))}
    
    {/* <Pagination.Next 
      onClick={() => handlePageChange(page + 1)} 
      disabled={page === totalPages - 1} 
      style={{ padding: '5px 10px', fontSize: '1rem', color: '#007bff', cursor: 'pointer', borderRadius: '4px' }}
    /> */}
  </Pagination>
</SoftBox>
          </Card>

        </SoftBox>
        {/* <Card>
          <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <SoftTypography variant="h6">Phys table</SoftTypography>
          </SoftBox>
          <SoftBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            <Table columns={prCols} rows={prRows} />
          </SoftBox>
        </Card> */}
      </SoftBox>
      <Footer />
    </DashboardLayout>

    <ConfirmModal
  title="Confirm Deletion"
  message="Are you sure you want to delete this physiotherapist?"
  isVisible={isModalVisible}
  onConfirm={deletePhysiotherapist} // Call delete function on confirm
  onCancel={() => setIsModalVisible(false)} // Close modal on cancel
/>

    <Modal
      show={modalOpen}
      onHide={handleCloseModal}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Physiotherapist Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
  <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
    {/* Each detail with its icon */}
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <InfoIcon sx={{ color: '#17c1e8' }} />
      <span style={{ fontSize: '14px' }}>Physiotherapist Name: {selectedClinic?.name || 'Not provided'}</span>
    </Box>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <EmailIcon sx={{ color: '#17c1e8' }} />
      <span style={{ fontSize: '14px' }}>Physiotherapist Email: {selectedClinic?.email || 'Not provided'}</span>
      {selectedClinic?.email && (
      <img src={VerifiedBadge} alt="Verified" style={{ marginLeft: '4px', width: '16px', height: '16px' }} />
      )}
    </Box>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <PhoneIcon sx={{ color: '#17c1e8' }} />
      <span style={{ fontSize: '14px' }}>Physiotherapist Phone: {selectedClinic?.mobile || 'Not provided'}</span>
      {selectedClinic?.mobile && (
      <img src={VerifiedBadge} alt="Verified" style={{ marginLeft: '4px', width: '16px', height: '16px' }} />
      )}
    </Box>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <InfoIcon sx={{ color: '#17c1e8' }} />
      <span style={{ fontSize: '14px' }}>Gender : {selectedClinic?.gender || 'Not provided'}</span>
    </Box>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <InfoIcon sx={{ color: '#17c1e8' }} />
      <span style={{ fontSize: '14px' }}>Physiotherapist Speciality : {selectedClinic?.speciality || 'Not provided'}</span>
    </Box>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <InfoIcon sx={{ color: '#17c1e8' }} />
      <span style={{ fontSize: '14px' }}>Physiotherapist Associated Entity : {selectedClinic?.associatedEntity?.name || selectedClinic?.associatedEntity  || 'Not provided'}</span>
    </Box>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <LocationOnIcon sx={{ color: '#17c1e8' }} />
      <span style={{ fontSize: '14px' }}>Physiotherapist Address: {`${selectedClinic?.address?.street || ''}, ${selectedClinic?.address?.city || ''}, ${selectedClinic?.address?.state || ''}, ${selectedClinic?.address?.country || ''}`.trim() || 'Not provided'}</span>
    </Box>
  </Box>
</Modal.Body>

      <Modal.Footer style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
        <Button className='m-2' variant="outlined" color="error" sx={{ color: '#ea0606' }} onClick={handleCloseModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
    <Modal show={show} onHide={handleClose} centered size="lg">
                    <Modal.Header closeButton className='justify-content-start'>
                        <Modal.Title className='text-center'>Edit Patient Credit </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div className='body-content text-center'>

                        <input
                            type='number'
                            className='form-control'
                            value={cart?.credits}
                            onChange={(e) => {
                              e.preventDefault()
                              const value = parseInt(e.target.value, 10)
                              if (!isNaN(value) && value >= 0) {
                                setCart((p) => ({ ...p, credits: value.toString() }))
                              } else if (e.target.value === '') {
                                // Allow clearing the input (setting it to an empty string)
                                setCart((p) => ({ ...p, credits: '' }))
                              }
                            }}
                            />

<button className='btn btn-secondary mt-4' onClick={handleEditCredit} disabled={isEditing}>
        Save
      </button>
      {editError && <div className="text-danger mt-2">{editError}</div>}
                        </div>

                    </Modal.Body>

                </Modal>
    </>
  )
}

export default Tables
