import React from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DashboardLayout from './LayoutContainers/DashboardLayout'
import DashboardNavbar from './Navbars/DashboardNavbar'
import '../assets/css/main.css'
import { post } from 'libs/http-hydrate'
import { useNavigate } from 'react-router-dom'
import { Auth } from 'libs/auth'

const AddUserToken = () => {
  const initialValues = {
    name: '',
    email: '',
    phone: ''
  }
  const navigate = useNavigate()
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    phone: Yup.string().required('Phone number is required')
  })

  async function handleCreateUser (formdata) {
    const authFunctions = Auth()
    const url = '/admin/createUser'
    try {
      const response = await post(url, formdata, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Web ' + 'Admin ' + authFunctions.getAuthToken()
        }
      })
      if (response?.status === 201) {
        navigate('/GeneratePremium')
      } else {
        alert(response.data.message || 'Error creating user')
      }
    } catch (error) {
      console.log('Error creating user:', error)
      alert(error.response?.data?.message || error.response?.data?.errors[0]?.msg || 'Internal server error')
    }
  }

  const handleSubmit = (values, { setSubmitting }) => {
    handleCreateUser(values)
    setSubmitting(false)
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <div className="mb-1">
            <label htmlFor="name" className="form-label">
              Name
            </label>
            <Field type="text" name="name" className="form-control" />
            <ErrorMessage name="name" component="div" className="text-danger" />
          </div>
          <div className="mb-1">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <Field type="email" name="email" className="form-control" />
            <ErrorMessage name="email" component="div" className="text-danger" />
          </div>
          <div className="mb-1">
            <label htmlFor="phone" className="form-label">
              Phone
            </label>
            <Field type="text" name="phone" className="form-control" />
            <ErrorMessage name="phone" component="div" className="text-danger" />
          </div>
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </Form>
      </Formik>
    </DashboardLayout>
  )
}

export default AddUserToken
