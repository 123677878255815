// @mui material components
import Card from '@mui/material/Card'

// Soft UI Dashboard React components
import SoftBox from 'components/SoftBox'
import SoftTypography from 'components/SoftTypography'
import Button from '@mui/material/Button'
// Soft UI Dashboard React examples
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import Footer from 'examples/Footer'
import Table from 'examples/Tables/Table'
import Box from '@mui/material/Box'
import { TextField } from '@mui/material'

// Data
import { useEffect, useState } from 'react'
import { getAuthConfig, get, deletes, post, postwithOu } from 'libs/http-hydrate'
import SoftAvatar from 'components/SoftAvatar'
import PropTypes from 'prop-types' // Import PropTypes
import moment from 'moment'
import SoftBadge from 'components/SoftBadge'
import SoftInput from 'components/SoftInput'
import Modal from 'react-bootstrap/Modal'
import InfoIcon from '@mui/icons-material/Info'
import EmailIcon from '@mui/icons-material/Email'
import PhoneIcon from '@mui/icons-material/Phone'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import ConfirmModal from 'layouts/Modal/ConfirmModal'
import { Pagination, Spinner } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { Auth } from 'libs/auth'

function ClinicPhysiotherapist () {
  // const { columns: prCols, rows: prRows } = projectsTableData
  const authFunctions = Auth()
  const [isLoadingdelete, setIsLoadingdelete] = useState(false)
  const location = useLocation()
  const [Loading, setLoading] = useState(false)
  const [ClinicphysiotherapistList, setClinicPhysiotherapistList] = useState([])
  const [physiotherapistList, setPhysiotherapistList] = useState({
    items: [],
    has_more: false
  })
  const [totalPages, setTotalPages] = useState(0); // Total pages for pagination
  const [deleteModalVisible, setDeleteModalVisible] = useState(false); // Manage visibility of ConfirmModal
  const [clinicToDelete, setClinicToDelete] = useState(null); // Store the clinic to delete
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('') // State for search query
  const [page, setPage] = useState(0)
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedClinic, setSelectedClinic] = useState(null)
  const [show, setModalShow] = useState(false)
  const [clinic, setClinic] = useState({
    name: selectedClinic?.name || '',
    email: selectedClinic?.email || '',
    phone: selectedClinic?.phone || '',
    address: {
      street: selectedClinic?.address?.street || '',
      city: selectedClinic?.address?.city || '',
      state: selectedClinic?.address?.state || '',
      country: selectedClinic?.address?.country || ''
    }
  })
  const [isSaving, setIsSaving] = useState(false)
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    phone: '',
    street: '',
    city: '',
    state: '',
    country: ''
  })
  const handleClose = (clinic) => {
    setSelectedClinic(clinic) // Set the selected clinic's details
    setModalShow(false) // Open the modal
  }

  // Function to handle closing the modal
  const handleShow = () => {
    setModalShow(true)
  }
  // Function to handle opening the modal
  const handleOpenModal = (clinic) => {
    setSelectedClinic(clinic)
    fetchClinicPhysiotherapists(clinic?._id)
    setModalOpen(true) // Open the modal
  }

  // Function to handle closing the modal
  const handleCloseModal = () => {
    setModalOpen(false)
  }
  useEffect(() => {
    getPhysiotherapistList()
  }, [page, searchQuery])

  // Update the clinic state when the selectedClinic prop changes
  useEffect(() => {
    setClinic({
      name: selectedClinic?.name || '',
      email: selectedClinic?.email || '',
      phone: selectedClinic?.phone || '',
      address: {
        street: selectedClinic?.address?.street || '',
        city: selectedClinic?.address?.city || '',
        state: selectedClinic?.address?.state || '',
        country: selectedClinic?.address?.country || ''
      }
    })
  }, [selectedClinic])
  // Function to handle input changes for clinic details
  const handleInputChange = (field, value) => {
    if (field in clinic.address) {
      setClinic({ ...clinic, address: { ...clinic.address, [field]: value } })
    } else {
      setClinic({ ...clinic, [field]: value })
    } setErrors({ ...errors, [field]: '' })
  }
  const validate = () => {
    const tempErrors = {}
    tempErrors.name = clinic.name ? '' : 'Please Enter the clinic name.'
    tempErrors.email = /^\S+@\S+\.\S+$/.test(clinic.email) ? '' : 'Email is not valid.'
    tempErrors.phone = clinic.phone.length > 5 ? '' : 'Phone number is too short.'
    tempErrors.street = clinic.address.street ? '' : 'This field is required.'
    tempErrors.city = clinic.address.city ? '' : 'This field is required.'
    tempErrors.state = clinic.address.state ? '' : 'This field is required.'
    tempErrors.country = clinic.address.country ? '' : 'This field is required.'

    setErrors({ ...tempErrors })
    return Object.values(tempErrors).every(x => x === '')
  }
  // Handle the save action
  const handleSave = async () => {
    if (validate()) {
      setIsSaving(true) // Start loading
      // Implement save logic here after validation passes
      try {
        const formData = new FormData()
        formData.append('name', clinic.name)
        formData.append('email', clinic.email)
        formData.append('phone', clinic.phone)
        // Append address object fields
        formData.append('street', clinic.address.street)
        formData.append('city', clinic.address.city)
        formData.append('state', clinic.address.state)
        formData.append('country', clinic.address.country)
        formData.append('pincode', clinic.address.pincode)

        // Append other fields if necessary...
        formData.append('_id', selectedClinic?._id) // Include _id for update

        await post('/clinicsUpdated', formData, getAuthConfig()) // use the correct path and configuration

        handleClose()
      } catch (error) {
        console.error('Error updating clinic:', error)
      } finally {
        setIsSaving(false) // End loading regardless of outcome
      }
    } else {
      // Optionally handle the failing validation case
    }
  }

  async function getPhysiotherapistList() {
    try {
      setIsLoading(true); // Start loading state
      const response = await get(
        `/clinicPhysiotherapists?pageNumber=${page}&limit=10&verified=${searchQuery}&clinic=${location?.state?.clinic}`,
        getAuthConfig()
      );
  
      if (response?.status === 200) {
        const { data, hasMore } = response.data;
        setPhysiotherapistList((prevState) => ({
          ...prevState,
          items: data,
          hasMore
        }));
        setTotalPages(Math.ceil(data.length / 10)); // Assuming the totalPages is based on the response
      }
    } catch (error) {
      console.error('Error fetching clinic physiotherapists:', error);
    } finally {
      setIsLoading(false); // End loading state
    }
  }
  
  const fetchClinicPhysiotherapists = async (id) => {
    try {
      const response = await get(`/clinicPhysiotherapist?clinicId=${id}`, getAuthConfig())
      if (response.status === 200) {
        setClinicPhysiotherapistList(response?.data?.msg?.physiotherapist)
      } else {
        setClinicPhysiotherapistList([])
      }
    } catch (error) {
      console.error('Error fetching clinic physiotherapists:', error)
      setClinicPhysiotherapistList([])
    }
  }
  function Author ({ image, name, email, onClick }) {
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5} onClick={onClick} >
        <SoftBox mr={2}>
          <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
        </SoftBox>
        <SoftBox display="flex" flexDirection="column">
          <SoftTypography variant="button" fontWeight="medium">
            {name}
          </SoftTypography>
          <SoftTypography variant="caption" color="secondary">
            {email}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    )
  }
  function Function ({ job, org }) {
    return (
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="caption" fontWeight="medium" color="text">
          {job}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {org}
        </SoftTypography>
      </SoftBox>
    )
  }
  // PropTypes definition for Author
  Author.propTypes = {
    image: PropTypes.string.isRequired, // Assuming 'image' is a string
    name: PropTypes.string.isRequired, // Assuming 'name' is a string
    email: PropTypes.string.isRequired // Assuming 'email' is a string
  }

  // PropTypes definition for Function
  Function.propTypes = {
    job: PropTypes.string.isRequired, // Assuming 'job' is a string
    org: PropTypes.string.isRequired // Assuming 'org' is a string
  }

  const authorsTableData = {
    columns: [
      { name: 'Physio', align: 'left' },
      { name: 'phone', align: 'left' },
      { name: 'authorization', align: 'center' },
      { name: 'created', align: 'center' },
      { name: 'action', align: 'center' }
    ],

    rows: physiotherapistList.items && physiotherapistList.items.map((item) => ({
        Physio: (
        <Author image={item?.physiotherapistId?.profile ? item?.physiotherapistId?.profile : ''} name={item?.physiotherapistId?.name} email={item?.physiotherapistId?.email} onClick={() => handleOpenModal(item)} />
      ),
      phone: <Function job={item?.physiotherapistId?.mobile} />,
      authorization: (
        <SoftBadge
          variant="gradient"
          badgeContent={item?.declined ? 'Declined' : item?.verified ? 'Verified' : 'Unverified'}
          color={item?.declined ? 'error' : item?.verified === true ? 'success' : 'secondary'}
          size="xs"
          container
        />
      ),
      created: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {moment(item?.created_at).format('DD / MM / YYYY')}
        </SoftTypography>
      ),
      action: (
        <>
          <SoftTypography
            component="a"
            href="#"
            variant="caption"
            color="secondary"
            fontWeight="medium"
            onClick={() => handleremove(item?.physiotherapistId?.associatedEntity, item?.physiotherapistId?._id)} // Add onClick handler here

          >
            Remove
          </SoftTypography>   {item?.verified === false &&
          <>
          <SoftTypography
            component="a"
            variant="caption"
            color="secondary"
            fontWeight="medium"
            onClick={() => handleVerifyClick(item?.physiotherapistId?.associatedEntity, item?.physiotherapistId?._id)} // Add onClick handler here
          >
           / verify
          </SoftTypography></> }
        </>
      )
    }))
  }
 
  async function handleVerifyClick (clinicId, physiotherapistId) {
    const url = '/verifyPhysioByClinic'
    setLoading(true) // Start loading
    try {
      const formdata = new URLSearchParams();
      formdata.append('physiotherapistId', physiotherapistId);
      formdata.append('clinicId', clinicId);
      await post(url, formdata.toString(), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'Web ' + 'Admin ' + authFunctions.getAuthToken()
        }
        // Add any other necessary configurations here
      })
      setPhysiotherapistList(prevState => ({
        ...prevState,
        items: prevState.items.map(item =>
          item?.physiotherapistId?._id === physiotherapistId ? { ...item, verified: true } : item
        )
      }))
    } catch (error) {
    } finally {
      setLoading(false) // Stop loading
    }
  }
  async function handleremove (clinicId, physiotherapistId) {
    setLoading(true) // Start loading
    try {
        const formdata = new URLSearchParams();
        formdata.append('clinicId', clinicId);
        formdata.append('physiotherapistId', physiotherapistId);
      await post('/removePhysioByClinic', formdata.toString(), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'Web ' + 'Admin ' + authFunctions.getAuthToken()
        }
        // Add any other necessary configurations here
      })

      setPhysiotherapistList((prevState) => ({
        ...prevState,
        items: prevState.items.filter((item) => item.physiotherapistId?._id !== physiotherapistId)
      }));
    } catch (error) {
    } finally {
      setLoading(false) // Stop loading
    }
  }

    // Function to open the confirmation modal
    const handleDeleteClick = (clinicId) => {
      setClinicToDelete(clinicId);
      setDeleteModalVisible(true);
    };
// Confirm deletion function
const confirmDelete = async () => {
  if (!clinicToDelete) return;
  const url = `/DeleteClinic?clinic_id=${clinicToDelete}`;
  setIsLoading(true);

  try {
    const data = await deletes(url);
    if (data?.status === 200) {
      // Remove clinic from list after deletion
      setPhysiotherapistList((prevState) => ({
        ...prevState,
        items: prevState.items.filter((item) => item._id !== clinicToDelete)
      }));
    }
  } catch (error) {
    console.error('Error deleting clinic:', error);
  } finally {
    setIsLoading(false);
    setDeleteModalVisible(false); // Close the modal
  }
};
const handlePageChange = (pageNumber) => {
  setPage(pageNumber);
};
  return (
    <>

    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>

            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Clinic table</SoftTypography>
              <div style={{ padding: '1rem' }}>
              <SoftBox pr={1}>
              <SoftInput
                type="text"
                placeholder="Search Clinic"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value)
                  setPage(1)
                }}
              />
              </SoftBox>
              </div>
            </SoftBox>

            {isLoading ?   <SoftBox display="flex" justifyContent="center" alignItems="center" p={3}>
    <Spinner 
      animation="border" 
      role="status" 
      style={{
        width: '3rem',
        height: '3rem',
        borderWidth: '0.3rem',
        color: '#007bff', // Customize to match your theme
        borderRightColor: 'transparent'// Gives a cool effect
      }}
    />
  </SoftBox> 
  :          <SoftBox
              sx={{
                '& .MuiTableRow-root:not(:last-child)': {
                  '& td': {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`
                  }
                }
              }}
            >

              {physiotherapistList?.items?.length === 0
                ? (<div style={{ textAlign: 'center' }}><SoftTypography variant='h6' color='textSecondary'> No Clinics found </SoftTypography></div>)
                : Loading
                  ? (
  <SoftBox display="flex" justifyContent="center" alignItems="center" p={3}>
    <SoftTypography variant="h6">Editing Your Clinic Details...</SoftTypography>
  </SoftBox>
                    )
                  : <Table columns={authorsTableData?.columns} rows={authorsTableData?.rows}/>}
            </SoftBox> }

            <SoftBox display="flex" justifyContent="center" p={2}>
  <Pagination className='custom-pagination' style={{ display: 'flex', gap: '8px', padding: '10px', borderRadius: '8px', backgroundColor: '#f1f3f5' }}>
    {/* <Pagination.Prev 
      onClick={() => handlePageChange(page - 1)} 
      disabled={page === 0} 
      style={{ padding: '5px 10px', fontSize: '1rem', color: '#007bff', cursor: 'pointer', borderRadius: '4px' }}
    /> */}
    
    {Array.from({ length: totalPages }, (_, idx) => (
      <Pagination.Item
        key={idx}
        active={idx === page}
        onClick={() => handlePageChange(idx)}
        style={{
          fontSize: '0.9rem',
          color: idx === page ? '#ffffff' : '#007bff',
          backgroundColor: idx === page ? '#007bff' : 'transparent',
          borderRadius: '4px',
          border: idx === page ? 'none' : '1px solid #007bff',
          cursor: 'pointer'
        }}
      >
        {idx + 1}
      </Pagination.Item>
    ))}
    
    {/* <Pagination.Next 
      onClick={() => handlePageChange(page + 1)} 
      disabled={page === totalPages - 1} 
      style={{ padding: '5px 10px', fontSize: '1rem', color: '#007bff', cursor: 'pointer', borderRadius: '4px' }}
    /> */}
  </Pagination>
</SoftBox>
          </Card>

        </SoftBox>
        {/* <Card>
          <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <SoftTypography variant="h6">Phys table</SoftTypography>
          </SoftBox>
          <SoftBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            <Table columns={prCols} rows={prRows} />
          </SoftBox>
        </Card> */}
      </SoftBox>
      <Footer />
    </DashboardLayout>
    <Modal
      show={modalOpen}
      onHide={handleCloseModal}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Clinic Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
        {/* Each detail with its icon */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <InfoIcon sx={{ color: '#17c1e8' }} />
          <span style={{ fontSize: '14px' }}>Clinic Name: {selectedClinic?.name}</span>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <EmailIcon sx={{ color: '#17c1e8' }} />
          <span style={{ fontSize: '14px' }}>Clinic Email: {selectedClinic?.email}</span>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <PhoneIcon sx={{ color: '#17c1e8' }} />
          <span style={{ fontSize: '14px' }}>Clinic Phone: {selectedClinic?.phone}</span>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <LocationOnIcon sx={{ color: '#17c1e8' }} />
          <span style={{ fontSize: '14px' }}>Clinic Address: {`${selectedClinic?.address?.street}, ${selectedClinic?.address?.city}, ${selectedClinic?.address?.state}, ${selectedClinic?.address?.country}`}</span>
        </Box>
        {ClinicphysiotherapistList && ClinicphysiotherapistList.map((physiotherapist, index) => (
    <div key={index}>
    <p style={{ fontWeight: 'bold', marginBottom: '5px' }}>{physiotherapist?.physiotherapistId?.name}</p>
      {physiotherapist?.creditsByMonth && physiotherapist?.creditsByMonth?.map((item, indews) => (
      <div key={indews} style={{ marginLeft: '20px' }}>
      <p>{item?.month}, {item?.credits} credits</p>
      </div>
      ))}
      {/* Add more details if needed */}
    </div>
        ))}
      </Box>
      </Modal.Body>
      <Modal.Footer style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
      <Button className='m-2' variant="outlined" color="error" sx={{ color: '#ea0606' }} onClick={handleShow}>
          Edit
        </Button>
        <Button className='m-2' variant="outlined" color="error" sx={{ color: '#ea0606' }} onClick={handleCloseModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Edit Clinic Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2, '& > :not(style)': { m: 1, width: '25ch' } }}>
          <TextField
            label="Clinic Name"
            variant="outlined"
            value={clinic.name}
            onChange={(e) => handleInputChange('name', e.target.value)}
            error={!!errors.name}
            helperText={errors.name}
            InputProps={{
              startAdornment: (
                <InfoIcon sx={{ color: '#17c1e8' }} />
              )
            }}
          />
          <TextField
            label="Clinic Email"
            variant="outlined"
            value={clinic.email}
            error={!!errors.email}
            helperText={errors.email}
            onChange={(e) => handleInputChange('email', e.target.value)}
            InputProps={{
              startAdornment: (
                <EmailIcon sx={{ color: '#17c1e8' }} />
              )
            }}
          />
          <TextField
            label="Clinic Phone"
            variant="outlined"
            value={clinic.phone}
            error={!!errors.phone}
            helperText={errors.phone}
            onChange={(e) => handleInputChange('phone', e.target.value)}
            InputProps={{
              startAdornment: (
                <PhoneIcon sx={{ color: '#17c1e8' }} />
              )
            }}
          />
          <TextField
            label="Street"
            variant="outlined"
            value={clinic.address.street}
            error={!!errors.street}
            helperText={errors.street}
            onChange={(e) => handleInputChange('street', e.target.value)}
          />
          <TextField
            label="City"
            variant="outlined"
            value={clinic.address.city}
            error={!!errors.city}
            helperText={errors.city}
            onChange={(e) => handleInputChange('city', e.target.value)}
          />
          <TextField
            label="State"
            variant="outlined"
            value={clinic.address.state}
            error={!!errors.state}
            helperText={errors.state}
            onChange={(e) => handleInputChange('state', e.target.value)}
          />
          <TextField
            label="Country"
            variant="outlined"
            error={!!errors.country}
            helperText={errors.country}
            value={clinic.address.country}
            onChange={(e) => handleInputChange('country', e.target.value)}
          />
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave} disabled={isSaving}>
    {isSaving ? 'Saving...' : 'Save Changes'}
  </Button>
      </Modal.Footer>
    </Modal>
    <ConfirmModal
        title="Confirm Delete"
        message="Are you sure you want to delete this clinic?"
        onConfirm={confirmDelete}
        onCancel={() => setDeleteModalVisible(false)}
        isVisible={deleteModalVisible}
      />

    </>
  )
}

export default ClinicPhysiotherapist
