// @mui material components
import Card from '@mui/material/Card'

// Soft UI Dashboard React components
import SoftBox from 'components/SoftBox'
import SoftTypography from 'components/SoftTypography'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// Soft UI Dashboard React examples
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import Footer from 'examples/Footer'
import Table from 'examples/Tables/Table'
import SoftInput from 'components/SoftInput'
import Modal from 'react-bootstrap/Modal'
import Box from '@mui/material/Box'
import InfoIcon from '@mui/icons-material/Info'
import EmailIcon from '@mui/icons-material/Email'
import PhoneIcon from '@mui/icons-material/Phone'
import Button from '@mui/material/Button'
// Data
import { useEffect, useState } from 'react'
import { getAuthConfig, get, deletes, post } from 'libs/http-hydrate'
import SoftAvatar from 'components/SoftAvatar'
import SoftBadge from 'components/SoftBadge'
import PropTypes from 'prop-types' // Import PropTypes
import moment from 'moment'
import ConfirmModal from 'layouts/Modal/ConfirmModal';
import { Pagination, Spinner } from 'react-bootstrap';

function Community () {
  // const { columns: prCols, rows: prRows } = projectsTableData
  const [totalPages, setTotalPages] = useState(0); // Total pages for pagination
  const [isLoading, setIsLoading] = useState(false)
  const [communitiesList, setcommunitiesList] = useState({
    items: [],
    has_more: false
  })
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
const [communityToDelete, setCommunityToDelete] = useState(null);
  const [loadingEdit, setLoadingEdit] = useState(false); // State for editing
  const [loadingDeleteId, setLoadingDeleteId] = useState(null); 
  const [searchQuery, setSearchQuery] = useState('') // State for search query
  const [page, setPage] = useState(0)
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedClinic, setSelectedClinic] = useState(null)
  const [editModel, setEditmodel] = useState(false)
  const [metaTitle, setMetaTitle] = useState('');
const [metaDescription, setMetaDescription] = useState('');
const [metaKeywords, setMetaKeywords] = useState([]);

  // Function to handle opening the modal
  const handleOpenModal = (clinic) => {
    setSelectedClinic(clinic);
    setMetaTitle(clinic?.meta_title || '');
    setMetaDescription(clinic?.meta_description || '');
    setMetaKeywords(clinic?.meta_keywords || []);
    setModalOpen(true); // Open the modal
  };

  // Function to handle closing the modal
  const handleCloseModal = () => {
    setModalOpen(false)
  }
  useEffect(() => {
    getcommunitiesList()
  }, [page, searchQuery])

  async function getcommunitiesList () {
    try {
      setIsLoading(true)
      const data = await get(`/communitiesList?pageNumber=${page}&searchQuery=${searchQuery}`, getAuthConfig())
      if (data?.status === 200) {
        // if (page === 0) {
        setcommunitiesList((p) => ({ ...p, items: data?.data?.communities }))
        // } else {
        //   setcommunitiesList((p) => ({ ...p, items: communitiesList?.items?.concat(data?.data?.communities) }))
        // }
        setcommunitiesList((p) => ({ ...p, has_more: data?.data?.has_more }))
        setTotalPages(Math.ceil(data?.data?.totalPages)); // Assuming 10 items per pages
      }
    } catch (error) {
    } finally {
      setIsLoading(false) // Set isLoading to false in the finally block
    }
  }

  function Author ({ image, name, email, onClick }) {
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5} onClick={onClick}>
        <SoftBox mr={2}>
          <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
        </SoftBox>
        <SoftBox display="flex" flexDirection="column">
          <SoftTypography variant="button" fontWeight="medium">
            {name}
          </SoftTypography>
          <SoftTypography variant="caption" color="secondary">
            {email}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    )
  }

  function Function ({ job, org }) {
    return (
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="caption" fontWeight="medium" color="text">
          {job}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {org}
        </SoftTypography>
      </SoftBox>
    )
  }
  // PropTypes definition for Author
  Author.propTypes = {
    image: PropTypes.string.isRequired, // Assuming 'image' is a string
    name: PropTypes.string.isRequired, // Assuming 'name' is a string
    email: PropTypes.string.isRequired // Assuming 'email' is a string
  }

  // PropTypes definition for Function
  Function.propTypes = {
    job: PropTypes.string.isRequired, // Assuming 'job' is a string
    org: PropTypes.string.isRequired // Assuming 'org' is a string
  }

  const authorsTableData = {
    columns: [
      { name: 'Title', align: 'left' },
      { name: 'By', align: 'left' },
      { name: 'verified', align: 'center' },
      { name: 'created', align: 'center' },
      { name: 'action', align: 'center' }
    ],

    rows: communitiesList.items && communitiesList.items.map((item) => ({
      Title: (
        <Author image={item.community_image ? item?.community_image : ''} name={item.title} email={item?.posted_By?.email} onClick={() => handleOpenModal(item)} />
      ),
      By: <Function job={item?.posted_By?.name}/>,
      verified: (
        <SoftBadge
          variant="gradient"
          badgeContent={item?.is_ApprovedAdmin ? 'Verified' : 'Unverified'}
          color={item.is_ApprovedAdmin === true ? 'success' : 'secondary'}
          size="xs"
          container
        />
      ),
      created: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {moment(item.created_at).format('DD / MM / YYYY')}
        </SoftTypography>
      ),
      action: (
        <>
        {!item?.is_ApprovedAdmin &&
        <>
          <SoftTypography
            component="a"
            href="#"
            variant="caption"
            color="secondary"
            fontWeight="medium"
            onClick={() => handleApprove(item._id)} // Add onClick handler here
          >
            Approve
          </SoftTypography> / </> } 

          {loadingDeleteId === item._id 
          ? (<SoftTypography variant="caption" color="secondary">
                              Deleting...
                            </SoftTypography>
                          ) : (
                            <>
                              <SoftTypography
                                component="a"
                                href="#"
                                variant="caption"
                                color="secondary"
                                fontWeight="medium"
                                onClick={() => handleDeleteClick(item._id)}
                              >
                                Delete
                              </SoftTypography>
                            </>)}
        </>
      )
    }))
  }
  async function handleApprove (blogId) {
    const url = `/approveCommunity?communityId=${blogId}`
    try {
      const data = await get(url, getAuthConfig())
      if (data?.status === 200) {
        const updatedItems = communitiesList?.items.map((item) => {
          if (item?._id === blogId) {
            return { ...item, is_ApprovedAdmin: true }
          }
          return item
        })
        setcommunitiesList({ items: updatedItems })
      }
    } catch (error) {
    }
  }
  const handleDeleteClick = (communityId) => {
    setCommunityToDelete(communityId); // Store the community ID to delete
    setDeleteModalVisible(true); // Show confirmation modal
  };
  const confirmDelete = async () => {
    setLoadingDeleteId(communityToDelete); // Set loading for the specific item being deleted
    const url = `/community/delete?id=${communityToDelete}`;
    try {
      const data = await get(url, getAuthConfig());
      if (data?.status === 200) {
        setcommunitiesList((prevState) => ({
          ...prevState,
          items: prevState.items.filter((item) => item._id !== communityToDelete)
        }));
      }
    } catch (error) {
      console.error('Error deleting community:', error);
    } finally {
      setLoadingDeleteId(null); // Reset after deletion
      setDeleteModalVisible(false); // Close confirmation modal
    }
  };
  const handleSaveChanges = async () => {
    setLoadingEdit(true); // Start loading when saving changes

    const formData = new FormData();
    formData.append('title', selectedClinic.title);
    formData.append('description', selectedClinic.description);
    if (selectedClinic.community_image instanceof File) {
      formData.append('community_image', selectedClinic.community_image);
    }
    formData.append('meta_title', metaTitle); // Add meta_title
    formData.append('meta_description', metaDescription); // Add meta_description
    formData.append('meta_keywords', metaKeywords); // Add meta_keywords (as JSON)
    try {
      const response = await post(`/community/update?id=${selectedClinic._id}`, formData, getAuthConfig());
      if (response?.status === 200) {
        console.log(response?.data, 'hiudsi')
        // Update the local state to reflect the changes
        const updatedItems = communitiesList?.items.map((item) => {
          if (item._id === selectedClinic._id) {
            return { ...response?.data?.data }; // Update the item with the edited data
          }
          return item;
        });
        setcommunitiesList({ items: updatedItems });
        setEditmodel(false)
        handleCloseModal(); // Close the modal after saving
      }
    } catch (error) {
      console.error('Error updating community:', error);
    } finally {
      setLoadingEdit(false); // Stop loading after saving changes
    }
  };
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };
  return (
    <>
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Community table</SoftTypography>
              <div style={{ padding: '1rem' }}>
              <SoftBox pr={1}>
              <SoftInput
                type="text"
                placeholder="Search For Community"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value)
                  setPage(0)
                }}
              />
            </SoftBox>
            </div>
            </SoftBox>
            {isLoading
              ? (
              <>
               <SoftBox display="flex" justifyContent="center" alignItems="center" p={3}>
    <Spinner 
      animation="border" 
      role="status" 
      style={{
        width: '3rem',
        height: '3rem',
        borderWidth: '0.3rem',
        color: '#007bff', // Customize to match your theme
        borderRightColor: 'transparent'// Gives a cool effect
      }}
    />
  </SoftBox></>
                )
              : <SoftBox
              sx={{
                '& .MuiTableRow-root:not(:last-child)': {
                  '& td': {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`
                  }
                }
              }}
            >
              {communitiesList?.items?.length === 0 ? (<div style={{ textAlign: 'center' }}><SoftTypography variant='h6' color='textSecondary'> No Blog found </SoftTypography></div>) : <Table columns={authorsTableData?.columns} rows={authorsTableData?.rows}/>}
            </SoftBox> }
            <SoftBox display="flex" justifyContent="center" p={2}>
  <Pagination className='custom-pagination' style={{ display: 'flex', gap: '8px', padding: '10px', borderRadius: '8px', backgroundColor: '#f1f3f5' }}>
    {/* <Pagination.Prev 
      onClick={() => handlePageChange(page - 1)} 
      disabled={page === 0} 
      style={{ padding: '5px 10px', fontSize: '1rem', color: '#007bff', cursor: 'pointer', borderRadius: '4px' }}
    /> */}
    
    {Array.from({ length: totalPages }, (_, idx) => (
      <Pagination.Item
        key={idx}
        active={idx === page}
        onClick={() => handlePageChange(idx)}
        style={{
          fontSize: '0.9rem',
          color: idx === page ? '#ffffff' : '#007bff',
          backgroundColor: idx === page ? '#007bff' : 'transparent',
          borderRadius: '4px',
          border: idx === page ? 'none' : '1px solid #007bff',
          cursor: 'pointer'
        }}
      >
        {idx + 1}
      </Pagination.Item>
    ))}
    
    {/* <Pagination.Next 
      onClick={() => handlePageChange(page + 1)} 
      disabled={page === totalPages - 1} 
      style={{ padding: '5px 10px', fontSize: '1rem', color: '#007bff', cursor: 'pointer', borderRadius: '4px' }}
    /> */}
  </Pagination>
</SoftBox>
          </Card>
        </SoftBox>
        {/* <Card>
          <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <SoftTypography variant="h6">Phys table</SoftTypography>
          </SoftBox>
          <SoftBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            <Table columns={prCols} rows={prRows} />
          </SoftBox>
        </Card> */}
      </SoftBox>
      <Footer />
    </DashboardLayout>
    <ConfirmModal
  title="Confirm Delete"
  message="Are you sure you want to delete this community?"
  onConfirm={confirmDelete} // Confirm delete action
  onCancel={() => setDeleteModalVisible(false)} // Cancel action
  isVisible={deleteModalVisible} // Visibility state
/>

    <Modal
      show={modalOpen}
      onHide={handleCloseModal}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Community Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
      {selectedClinic?.community_image && (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <img 
          src={selectedClinic.community_image} 
          alt="Community Image" 
          style={{ maxHeight: '200px', maxWidth: '100%', objectFit: 'cover' }} 
        />
      </Box>
    )}
        {/* Each detail with its icon */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <InfoIcon sx={{ color: '#17c1e8' }} />
          <span style={{ fontSize: '14px' }}> {selectedClinic?.title}</span>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <InfoIcon sx={{ color: '#17c1e8' }} />
          <span style={{ fontSize: '14px', maxHeight: '50vh', overflow: 'scroll' }} dangerouslySetInnerHTML={{ __html: selectedClinic?.description }}></span>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {selectedClinic?.posted_By?.email ? <EmailIcon sx={{ color: '#17c1e8' }} /> : <PhoneIcon sx={{ color: '#17c1e8' }} />}
          <span style={{ fontSize: '14px' }}>Posted By: {selectedClinic?.posted_By?.email ? selectedClinic?.posted_By?.email : selectedClinic?.posted_By?.mobile}</span>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <EmailIcon sx={{ color: '#17c1e8' }} />
          <span style={{ fontSize: '14px' }}>Posted At: {moment(selectedClinic?.created_at).format('DD / MM / YYYY')}</span>
        </Box>
      </Box>
      </Modal.Body>
      <Modal.Footer style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
      <Button
    variant="contained"
    color="primary"
    sx={{ color: '#fff' }}
    onClick={() => { setEditmodel(true) }}
  >
    Edit
  </Button>
        <Button className='m-2' variant="outlined" color="error" sx={{ color: '#ea0606' }} onClick={handleCloseModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
    <Modal   size='lg'    show={editModel} onHide={() => {
      setEditmodel(false)
    }}>
    <Modal.Body>
  <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
    {/* Edit Title */}
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <InfoIcon sx={{ color: '#17c1e8' }} />
      <input
        type='text'
        value={selectedClinic?.title}
        onChange={(e) => setSelectedClinic({ ...selectedClinic, title: e.target.value })}
        className='form-control'
        placeholder="Community Title"
      />
    </Box>

    {/* Edit Description */}
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '200px' }}>
      <InfoIcon sx={{ color: '#17c1e8' }} />

      <CKEditor
                editor={ClassicEditor}
                data={selectedClinic?.description}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setSelectedClinic({ ...selectedClinic, description: data });
                }}
                
              />
    </Box>

    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <InfoIcon sx={{ color: '#17c1e8' }} />
      <input
        type="text"
        value={metaTitle}
        onChange={(e) => setMetaTitle(e.target.value)}
        className="form-control"
        placeholder="Meta Title"
      />
    </Box>

    {/* Meta Description */}
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <InfoIcon sx={{ color: '#17c1e8' }} />
      <input
        type="text"
        value={metaDescription}
        onChange={(e) => setMetaDescription(e.target.value)}
        className="form-control"
        placeholder="Meta Description"
      />
    </Box>

    {/* Meta Keywords */}
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <InfoIcon sx={{ color: '#17c1e8' }} />
      <input
        type="text"
        value={metaKeywords.join(', ')}
        onChange={(e) => setMetaKeywords(e.target.value.split(',').map((keyword) => keyword.trim()))}
        className="form-control"
        placeholder="Meta Keywords (comma separated)"
      />
    </Box>
    {/* Edit Image */}
<Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
  {/* Check if a new file is uploaded, if yes, create a preview URL, otherwise display the existing image */}
  {selectedClinic?.community_image instanceof File ? (
    <img
      src={URL.createObjectURL(selectedClinic.community_image)} // Create preview URL for the uploaded image
      alt="Community Image Preview"
      style={{ maxHeight: '200px', maxWidth: '100%', objectFit: 'cover' }}
    />
  ) : selectedClinic?.community_image ? (
    <img
      src={selectedClinic.community_image}
      alt="Community Image"
      style={{ maxHeight: '200px', maxWidth: '100%', objectFit: 'cover' }}
    />
  ) : (
    <div>No image selected</div> // Fallback if there's no image available
  )}
</Box>

    <input
      type="file"
      onChange={(e) => setSelectedClinic({ ...selectedClinic, community_image: e.target.files[0] })}
      accept="image/png, image/jpeg"
      className="form-control mt-2"
    />
  </Box>
</Modal.Body>
<Modal.Footer style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
  <Button
    variant="contained"
    color="primary"
    sx={{ color: '#fff' }}
    onClick={handleSaveChanges}
    disabled={loadingEdit} // Disable the button during edit
    >
      {loadingEdit ? 'Saving...' : 'Save Changes'}
  </Button>
  <Button className='m-2' variant="outlined" color="error" sx={{ color: '#ea0606' }} onClick={() => setEditmodel(false) }>
    Close
  </Button>
</Modal.Footer>
      </Modal>
    </>
  )
}

export default Community
