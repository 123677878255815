import React, { useState } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DashboardLayout from './LayoutContainers/DashboardLayout'
import DashboardNavbar from './Navbars/DashboardNavbar'
import '../assets/css/main.css'
import { post } from 'libs/http-hydrate'
import { useNavigate } from 'react-router-dom'
import { Auth } from 'libs/auth'
const AddLanguage = () => {
  const initialValues = {
    title: '',
    code: '' // Add this line
  }
  const [apiError, setApiError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    code: Yup.string().required('Code is required') // Add this line
  })
  async function handleCreateExercise (formdata) {
    setIsLoading(true) // Start loading

    const authFunctions = Auth()
    const formDat = new URLSearchParams()
    formDat.append('name', formdata.title)
    formDat.append('code', formdata.code) // Add this line
    const url = '/api/languages'
    try {
      const data = await post(url, formDat.toString(), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'Web ' + 'Admin ' + authFunctions.getAuthToken()
        }
        // Add any other necessary configurations here
      })
      if (data?.status === 200) {
        navigate('/dashboard')
      } else {
        setApiError(data?.message || 'An error occurred')
      }

      setIsLoading(false) // Stop loading after API call is complete
    } catch (error) {
      setIsLoading(false) // Stop loading after API call is complet
      setApiError(error.response?.data?.errors[0]?.msg || 'An error occurred')
    }
  }

  const handleSubmit = (values, { setSubmitting }) => {
    handleCreateExercise(values)
    setSubmitting(false)
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>

          <div className="mb-1">
            <label htmlFor="title" className="form-label">
              Title
            </label>
            <Field type="text" name="title" className="form-control" />
            <ErrorMessage name="title" component="div" className="text-danger" />
          </div>
          <div className="mb-1">
    <label htmlFor="code" className="form-label">
      Code
    </label>
    <Field type="text" name="code" className="form-control" />
    <ErrorMessage name="code" component="div" className="text-danger" />
  </div>
  {apiError && <div className="text-danger">{apiError}</div>}

  <button type="submit" className="btn btn-primary" disabled={isLoading}>
      {isLoading ? 'Submitting...' : 'Submit'}
    </button>
        </Form>
      </Formik>
    </DashboardLayout>
  )
}

export default AddLanguage
