// Soft UI Dashboard React Helper Functions
import pxToRem from 'assets/theme/functions/pxToRem'

const cardContent = {
  styleOverrides: {
    root: {
      marginTop: 0,
      marginBottom: 0,
      padding: `${pxToRem(8)} ${pxToRem(24)} ${pxToRem(24)}`
    }
  }
}

export default cardContent
