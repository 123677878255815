import React, { useEffect, useState } from 'react'
import { Card, Button } from '@mui/material'
import SoftBox from 'components/SoftBox'
import SoftTypography from 'components/SoftTypography'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import Footer from 'examples/Footer'
import Table from 'examples/Tables/Table'
import SoftInput from 'components/SoftInput'
import SoftAvatar from 'components/SoftAvatar'
import PropTypes from 'prop-types'
import moment from 'moment'
import { getAuthConfig, get, post } from 'libs/http-hydrate'
import { Auth } from 'libs/auth'
import { Modal } from 'react-bootstrap'

function PremiumUserToken () {
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingToken, setisLoadingToken] = useState(false)
  const [physiotherapistList, setPhysiotherapistList] = useState({
    items: [],
    has_more: false
  })
  const [page, setPage] = useState(0)
  const [searchQuery, setSearchQuery] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [selectedUserId, setSelectedUserId] = useState(null)
  const [generatedToken, setGeneratedToken] = useState('')
  const [showTokenModal, setShowTokenModal] = useState(false)

  useEffect(() => {
    getPhysiotherapistList()
  }, [page, searchQuery])

  async function getPhysiotherapistList () {
    try {
      setIsLoading(true)
      const data = await get(`/admin/userList?pageNumber=${page}`, getAuthConfig())
      if (data?.status === 200) {
        if (page === 0) {
          setPhysiotherapistList((p) => ({ ...p, items: data?.data?.users }))
        } else {
          setPhysiotherapistList((p) => ({ ...p, items: physiotherapistList?.items?.concat(data?.data?.users) }))
        }
        setPhysiotherapistList((p) => ({ ...p, has_more: data?.data?.has_more }))
      }
    } catch (error) {
      console.error('Error fetching user list:', error)
    } finally {
      setIsLoading(false)
    }
  }

  async function handleGenerateToken () {
    const authFunctions = Auth()
    const url = '/admin/generateToken'
    try {
      setisLoadingToken(true)
      const response = await post(url, { userId: selectedUserId }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Web ' + 'Admin ' + authFunctions.getAuthToken()
        }
      })
      if (response?.status === 201) {
        setGeneratedToken(response.data.token)
        setShowTokenModal(true)
        setShowModal(false)
      } else {
        alert(response.data.message || 'Error generating token')
      }
    } catch (error) {
      console.error('Error generating token:', error)
      alert(error.response?.data?.message || 'Internal server error')
    } finally {
      setisLoadingToken(false)
    }
  }

  function Author ({ image, name, email }) {
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox mr={2}>
          <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
        </SoftBox>
        <SoftBox display="flex" flexDirection="column">
          <SoftTypography variant="button" fontWeight="medium">
            {name}
          </SoftTypography>
          <SoftTypography variant="caption" color="secondary">
            {email}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    )
  }

  Author.propTypes = {
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired
  }

  const authorsTableData = {
    columns: [
      { name: 'User', align: 'left' },
      { name: 'created', align: 'center' },
      { name: 'action', align: 'center' }
    ],
    rows: physiotherapistList.items.map((item) => ({
      User: (
        <Author image={item.image ? item?.image : ''} name={item.name} email={item.email} />
      ),
      created: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {moment(item.created_at).format('DD / MM / YYYY')}
        </SoftTypography>
      ),
      action: (
        <>
          <SoftTypography
            component="a"
            href="#"
            variant="caption"
            color="secondary"
            fontWeight="medium"
            onClick={() => {
              setSelectedUserId(item._id)
              setShowModal(true)
            }}
          >
            Generate Token
          </SoftTypography>
          {/* / <SoftTypography
            component="a"
            href="#"
            variant="caption"
            color="secondary"
            fontWeight="medium"
            onClick={() => handleDeleteClick(item._id)}
          >
            Delete
          </SoftTypography> */}
        </>
      )
    }))
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Token User List</SoftTypography>
              <div style={{ padding: '1rem' }}>
                <SoftBox pr={1}>
                  <SoftInput
                    type="text"
                    placeholder="Search Physiotherapist"
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.target.value)
                      setPage(0)
                    }}
                  />
                </SoftBox>
              </div>
            </SoftBox>
            {isLoading
              ? (
                <SoftBox display="flex" justifyContent="center" alignItems="center" p={3}>
                  <SoftTypography variant="h6">Loading ... </SoftTypography>
                </SoftBox>
                )
              : (
                <SoftBox
                  sx={{
                    '& .MuiTableRow-root:not(:last-child)': {
                      '& td': {
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`
                      }
                    }
                  }}
                >
                  {physiotherapistList?.items?.length === 0
                    ? (
                    <div style={{ textAlign: 'center' }}>
                      <SoftTypography variant='h6' color='textSecondary'> No Users found </SoftTypography>
                    </div>
                      )
                    : (
                      <Table columns={authorsTableData?.columns} rows={authorsTableData?.rows} />
                      )}
                </SoftBox>
                )}
            <SoftBox display="flex" justifyContent="center" p={2}>
              <button
                onClick={() => setPage(current => current - 1)}
                disabled={page === 0}
                className='btn btn-primary m-2'
              >
                Previous
              </button>
              <button
                onClick={() => setPage(current => current + 1)}
                disabled={!physiotherapistList?.has_more}
                className='btn btn-primary m-2'
              >
                Next
              </button>
            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>
      <Footer />

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <SoftBox p={3}>
          <SoftTypography variant="h6">Generate Token</SoftTypography>
          <SoftTypography variant="body2" color="textSecondary">
            Do you want to generate a token for the selected user?
          </SoftTypography>
          <SoftBox mt={2} display="flex" justifyContent="flex-end">
            <Button onClick={() => setShowModal(false)} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleGenerateToken} color="primary" autoFocus disabled={isLoadingToken}>
              {isLoadingToken ? 'Generating' : 'Generate Token'}
            </Button>
          </SoftBox>
        </SoftBox>
      </Modal>

      <Modal show={showTokenModal} onHide={() => setShowTokenModal(false)} centered>
        <SoftBox p={3}>
          <SoftTypography variant="h6">Generated Token</SoftTypography>
          <SoftTypography variant="body2" color="textSecondary" style={{ wordBreak: 'break-all', overflowWrap: 'break-word' }}>
            {generatedToken}
          </SoftTypography>
          <SoftBox mt={2} display="flex" justifyContent="flex-end">
            <Button onClick={() => setShowTokenModal(false)} color="primary" autoFocus>
              Close
            </Button>
          </SoftBox>
        </SoftBox>
      </Modal>
    </DashboardLayout>
  )
}

export default PremiumUserToken
