import React, { useEffect, useState } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DashboardLayout from './LayoutContainers/DashboardLayout'
import DashboardNavbar from './Navbars/DashboardNavbar'
import '../assets/css/main.css'
import { getAuthConfig, get, post } from 'libs/http-hydrate'
import { useNavigate } from 'react-router-dom'
import Multiselect from 'multiselect-react-dropdown'

const ExerciseForm = () => {
  const initialValues = {
    body_part_id: [],
    title: '',
    description: '',
    exerciseImage: null, // Change to null
    videoLink: null, // Add the videoLink field
    Benifits: '',
    count: 10,
    restTime: 30,
    set: 3,
    movementType: '',
    equipment: [],
    isLive: true
  }
  const [errors, setErrors] = useState({
    body_part_id: false
  })
  const [images, setImage] = useState({
    videoLink: null,
    exerciseImage: null,
    equipment: [],
    body_part_id: []
  })
  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()
  const [bodyPartData, setBodyPart] = useState(
    {
      has_more: false,
      items: []
    })
  const [equipmentPartData, setequipmentPartData] = useState(
    {
      has_more: false,
      items: []
    })
  const [movemntPartData, setmovemntPartData] = useState(
    {
      has_more: false,
      items: []
    })
  const page = 0
  useEffect(() => {
    getBodyPartData()
  }, [page])
  useEffect(() => {
    getEquipment()
    getMovementType()
  }, [])
  async function getEquipment () {
    try {
      const data = await get(`/listEquipment?pageNumber=${page}`, getAuthConfig())
      if (data?.status === 200) {
        setequipmentPartData((p) => ({ ...p, items: data?.data?.equipmentList }))
        setequipmentPartData((p) => ({ ...p, has_more: data?.data?.has_more }))
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate('/login')
      }
    }
  }
  async function getMovementType () {
    try {
      const data = await get(`/listMovementType?pageNumber=${page}`, getAuthConfig())
      if (data?.status === 200) {
        setmovemntPartData((p) => ({ ...p, items: data?.data?.equipmentList }))
        setmovemntPartData((p) => ({ ...p, has_more: data?.data?.has_more }))
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate('/login')
      }
    }
  }
  async function getBodyPartData () {
    try {
      const data = await get(`/bodyPartListAdmin?pageNumber=${page}`, getAuthConfig())
      if (data?.status === 200) {
        setBodyPart((p) => ({ ...p, items: data?.data?.data }))
        setBodyPart((p) => ({ ...p, has_more: data?.data?.has_more }))
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate('/login')
      }
    }
  }
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
    count: Yup.number().required('Count is required').positive('Count must be a positive number'),
    set: Yup.number().required('Set is required').positive('Set must be a positive number'),
    restTime: Yup.number().required('Rest Time is required').positive('Rest time must be a positive number'),
    Benifits: Yup.string().required('Benifits is required'),
    isLive: Yup.boolean() // Optional validation for boolean field
  })

  async function handleCreateExercise (formdata) {
    const formDat = new FormData()
    formDat.append('title', formdata.title)
    formDat.append('description', formdata.description)
    formDat.append('exerciseImage', images.exerciseImage)
    formDat.append('videoLink', images.videoLink)
    formDat.append('count', formdata.count)
    formDat.append('restTime', formdata.restTime)
    formDat.append('set', formdata.set)
    formDat.append('Benifits', formdata.Benifits)
    if (formdata?.movementType) {
      formDat.append('movementType', formdata.movementType)
    }
    formDat.append('isLive', formdata.isLive)
    if (images?.equipment?.length > 0) {
      // Appending the data to the URLSearchParams object
      for (const key in images?.equipment) {
        formDat.append('equipment', images?.equipment[key].key)
      }
    }
    if (images?.body_part_id?.length > 0) {
      // Appending the data to the URLSearchParams object
      for (const key in images?.body_part_id) {
        formDat.append('body_part_id', images?.body_part_id[key].value)
      }
    }
    setIsLoading(true) // Start loading
    const url = '/exercise'
    try {
      const data = await post(url, formDat, getAuthConfig())
      if (data?.status === 200) {
        navigate('/dashboard')
      }
    } catch (error) {
    } finally {
      setIsLoading(false) // Stop loading
    }
  }

  const handleSubmit = (values, { setSubmitting }) => {
    if (images?.body_part_id?.length <= 0) {
      // If body_part_id validation fails
      setErrors({ body_part_id: 'Please select at least one body part' })
      setSubmitting(false)
      return false
    }

    const errorsToDisplay = { ...errors } // Create a copy of errors object
    let hasErrors = false

    // Check if exerciseImage is empty
    if (!images.exerciseImage) {
      errorsToDisplay.exerciseImage = 'Exercise Image is required'
      hasErrors = true
    } else {
      errorsToDisplay.exerciseImage = null
    }

    // Check if videoLink is empty
    if (!images.videoLink) {
      errorsToDisplay.videoLink = 'Video Link is required'
      hasErrors = true
    } else {
      errorsToDisplay.videoLink = null
    }

    if (images?.body_part_id?.length <= 0) {
      // If body_part_id validation fails
      errorsToDisplay.body_part_id = 'Please select at least one body part'
      hasErrors = true
    }

    if (hasErrors) {
      setErrors(errorsToDisplay) // Set the updated errors object
      setSubmitting(false)
      return
    }

    handleCreateExercise(values)
    setSubmitting(false)
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <div className="mb-1">
            <label htmlFor="body_part_id" className="form-label">
              Body Part
            </label>
            {/* <Field type="text" name="body_part_id" className="form-control" /> */}

            <Multiselect
    options={bodyPartData?.items?.map((bodyPart) => ({
      key: bodyPart.title,
      value: bodyPart._id
    }))} // closeIcon="close"
                                                                    customArrow={<img src="data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e" />}
                                                                    displayValue="key"
                                                                    placeholder="Select BodyPart"
                                                                    showArrow={true}
                                                                    keepSearchTerm={true}
                                                                    onSelect={(selectedList, selectedItem) => {
                                                                      setImage((p) => ({ ...p, body_part_id: selectedList }))
                                                                    }} // Function will trigger on select event
                                                                    onRemove={(selectedList, removedItem) => {
                                                                      setImage((p) => ({ ...p, body_part_id: selectedList }))
                                                                    }}
                                                                />

{errors.body_part_id && <div className="text-danger">{errors.body_part_id}</div>}

            {/* <Field
          as="select" // Use "select" as the input type
          className="form-control"
          name="body_part_id"
        >
          <option value="" label="Select Body Part" />
          {bodyPartData.items.map((bodyPart) => (
            <option key={bodyPart._id} value={bodyPart._id}>
              {bodyPart.title}
            </option>
          ))}
        </Field> */}

            <ErrorMessage name="body_part_id" component="div" className="text-danger" />
          </div>

          <div className="mb-1">
            <label htmlFor="title" className="form-label">
              Title
            </label>
            <Field type="text" name="title" className="form-control" />
            <ErrorMessage name="title" component="div" className="text-danger" />
          </div>

          <div className="mb-1">
            <label htmlFor="description" className="form-label">
              Description
            </label>
            <Field type="text" name="description" className="form-control" />
            <ErrorMessage name="description" component="div" className="text-danger" />
          </div>
          <div className="mb-1">
            <label htmlFor="Benifits" className="form-label">
              Benifits
            </label>
            <Field type="text" name="Benifits" className="form-control" />
            <ErrorMessage name="Benifits" component="div" className="text-danger" />
          </div>
          <div className="mb-1">
  <label htmlFor="exerciseImage" className="form-label">
    Exercise Image
  </label>
  <Field type="file" accept=".jpg, .jpeg, .png, .gif, .webp" name="exerciseImage" className="form-control" onChange={(event) => {
    setImage((p) => ({ ...p, exerciseImage: event.currentTarget.files[0] }))
    setErrors((p) => ({ ...p, exerciseImage: false }))
  }}/>
  <ErrorMessage name="exerciseImage" component="div" className="text-danger" />
  {errors.exerciseImage && <div className="text-danger">{errors.exerciseImage}</div>}

</div>

          <div className="mb-1">
            <label htmlFor="videoLink" className="form-label">
              Video Link
            </label>
            <Field type="file" accept="video/mp4,video/x-m4v,video/*" name="videoLink" className="form-control" onChange={(event) => {
              setImage((p) => ({ ...p, videoLink: event.currentTarget.files[0] }))
              setErrors((p) => ({ ...p, videoLink: false }))
            }}/>
            <ErrorMessage name="videoLink" component="div" className="text-danger" />
            {errors.videoLink && <div className="text-danger">{errors.videoLink}</div>}

          </div>

          <div className="mb-1">
            <label htmlFor="count" className="form-label">
              Count
            </label>
            <Field type="number" name="count" className="form-control" />
            <ErrorMessage name="count" component="div" className="text-danger" />
          </div>

          <div className="mb-1">
            <label htmlFor="restTime" className="form-label">
              Rest Time <span>( In Seconds )</span>
            </label>
            <Field type="number" name="restTime" placeholder="Ex. 30 Seconds" className="form-control" />
            <ErrorMessage name="restTime" component="div" className="text-danger" />
          </div>

          <div className="mb-1">
            <label htmlFor="set" className="form-label">
              Set
            </label>
            <Field type="number" name="set" className="form-control" />
            <ErrorMessage name="set" component="div" className="text-danger" />
          </div>

          <div className="mb-1">
            <label htmlFor="movementType" className="form-label">
            Movement Type
            </label>
            {/* <Field type="text" name="body_part_id" className="form-control" /> */}
            <Field
          as="select" // Use "select" as the input type
          className="form-control"
          name="movementType"
        >
          <option value="" label="Select Movement Type" />
          {movemntPartData.items.map((bodyPart) => (
            <option key={bodyPart._id} value={bodyPart.title}>
              {bodyPart.title}
            </option>
          ))}
        </Field>

            <ErrorMessage name="movementType" component="div" className="text-danger" />
          </div>

          <div className="mb-1">
            <label htmlFor="equipment" className="form-label">
            Equipment
            </label>

            <Multiselect
    options={equipmentPartData?.items?.map((bodyPart) => ({
      key: bodyPart.title,
      value: bodyPart.title
    }))} // closeIcon="close"
                                                                    customArrow={<img src="data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e" />}
                                                                    displayValue="key"
                                                                    placeholder="Select Equipment"
                                                                    showArrow={true}
                                                                    keepSearchTerm={true}
                                                                    onSelect={(selectedList, selectedItem) => {
                                                                      setImage((p) => ({ ...p, equipment: selectedList }))
                                                                      // handleTimesChange(itemId, selectedList)
                                                                    }} // Function will trigger on select event
                                                                    onRemove={(selectedList, removedItem) => {
                                                                      // handleTimesChange(itemId, selectedList)
                                                                      setImage((p) => ({ ...p, equipment: selectedList }))
                                                                    }}
                                                                />

            <ErrorMessage name="equipment" component="div" className="text-danger" />
          </div>
          {/* <div className="mb-1">
            <label htmlFor="movementType" className="form-label">
              Movement Type
            </label>
            <Field type="text" name="movementType" className="form-control" />
            <ErrorMessage name="movementType" component="div" className="text-danger" />
          </div> */}

<div className="mb-1 align-items-center d-flex m-3">
      <label htmlFor="isLive" className="form-label mb-0 ">
       Want To Live This Exercise
      </label>
      <Field type="checkbox" name="isLive" className="form-check-input position-relative mt-0 mb-0 mr-0 ml-3" />
      <ErrorMessage name="isLive" component="div" className="text-danger" />
    </div>

          <button type="submit" className="btn btn-primary">
          {isLoading ? 'Submitting...' : 'Submit'}

          </button>
        </Form>
      </Formik>
    </DashboardLayout>
  )
}

export default ExerciseForm
