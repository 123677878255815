// @mui material components
import Card from '@mui/material/Card'

// Soft UI Dashboard React components
import SoftBox from 'components/SoftBox'
import SoftTypography from 'components/SoftTypography'

// Soft UI Dashboard React examples
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import Footer from 'examples/Footer'
import Table from 'examples/Tables/Table'
import SoftInput from 'components/SoftInput'
import Modal from 'react-bootstrap/Modal'
import { Nav, Pagination, Spinner } from 'react-bootstrap'

// Data
import React, { useEffect, useState } from 'react'
import { getAuthConfig, get, post } from 'libs/http-hydrate'
import SoftAvatar from 'components/SoftAvatar'
// import SoftBadge from 'components/SoftBadge'
import PropTypes from 'prop-types' // Import PropTypes
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import ConfirmModal from 'layouts/Modal/ConfirmModal'
// import { Link } from 'react-router-dom'
function TemplateList () {
  // const { columns: prCols, rows: prRows } = projectsTableData
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [totalPages, setTotalPages] = useState(0); // Total pages for pagination
const [templateToDelete, setTemplateToDelete] = useState(null);
const [sortOrder, setSortOrder] = useState('asc'); // Default sort order
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [cart, setCart] = useState([])
  const [physiotherapistList, setPhysiotherapistList] = useState({
    items: [],
    has_more: false
  })
  const [page, setPage] = useState(0)
  const [searchQuery, setSearchQuery] = useState('') // State for search query
  useEffect(() => {
    getPhysiotherapistList()
  }, [page, searchQuery, sortOrder])

  async function getPhysiotherapistList () {
    try {
      setIsLoading(true)
      const data = await get(`/templateListAdmin?pageNumber=${page + 1}&searchTerm=${searchQuery}&sortOrder=${sortOrder}`, getAuthConfig())
      if (data?.status === 200) {
        // if (page === 0) {
        setPhysiotherapistList((p) => ({ ...p, items: data?.data?.data }))
        // } else {
        //   setPhysiotherapistList((p) => ({ ...p, items: physiotherapistList?.items?.concat(data?.data?.data) }))
        // }
        setPhysiotherapistList((p) => ({ ...p, has_more: data?.data?.has_more }))
        setTotalPages(Math.ceil(data?.data?.totalPages)); // Assuming 10 items per pages
      }
    } catch (error) {

    } finally {
      setIsLoading(false) // Set isLoading to false in the finally block
    }
  }

  function Author ({ image, name, email, onClick }) {
    return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5} onClick={onClick}>
      <SoftBox mr={2}>
        <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="button" fontWeight="medium">
          {name}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {email}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
    )
  }

  function Function ({ job, org }) {
    return (
    <SoftBox display="flex" flexDirection="column">
      <SoftTypography variant="caption" fontWeight="medium" color="text">
        {job}
      </SoftTypography>
      <SoftTypography variant="caption" color="secondary">
        {org}
      </SoftTypography>
    </SoftBox>
    )
  }
  // PropTypes definition for Author
  Author.propTypes = {
    image: PropTypes.string.isRequired, // Assuming 'image' is a string
    name: PropTypes.string.isRequired, // Assuming 'name' is a string
    email: PropTypes.string.isRequired // Assuming 'email' is a string
  }

  // PropTypes definition for Function
  Function.propTypes = {
    job: PropTypes.string.isRequired, // Assuming 'job' is a string
    org: PropTypes.string.isRequired // Assuming 'org' is a string
  }

  const authorsTableData = {
    columns: [
      { name: 'Exercise', align: 'left' },
      { name: 'Category', align: 'left' },
      { name: 'created', align: 'center' },
      { name: 'action', align: 'center' }
    ],

    rows: physiotherapistList.items && physiotherapistList.items.map((item) => ({
      Exercise: (
      <Author image={item.exerciseImage ? item?.exerciseImage : ''} name={item.name} email={item.email} style={{ cursor: 'pointer' }} onClick={(e) => {
        e.preventDefault()
        setCart(item?.exerciseId)
        handleShow()
      }}/>
      ),
      Category: 
      (item?.body_part_id && 
        
        (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
              {item.body_part_id.map((it) => it.title).join(' - ')}
        </SoftTypography>
      )),
      created: (
      <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {moment(item.created_at).format('DD / MM / YYYY')}
      </SoftTypography>
      ),
      action: (
      <>
      <SoftTypography
        component="a"
        variant="caption"
        color="secondary"
        fontWeight="medium"
        onClick={() => {
          navigate('/template', { state: { template: item } })
        }}
// Add onClick handler here
      >
        Edit
      </SoftTypography> / &nbsp;
       <SoftTypography
        component="a"
        href="#"
        variant="caption"
        color="secondary"
        fontWeight="medium"
        onClick={() => handleDeleteClick(item._id)} // Add onClick handler here
      >
        Delete
      </SoftTypography>
      </>
      )
    }))
  }
  const handleDeleteClick = (templateId) => {
    setTemplateToDelete(templateId); // Set the ID of the template to delete
    setDeleteModalVisible(true); // Open the confirmation modal
  };
  const confirmDelete = async () => {
    const url = `/deletesurgeryLab?id=${templateToDelete}`;
    try {
      const data = await post(url, null, getAuthConfig());
      if (data?.status === 200) {
        setPhysiotherapistList((prevState) => ({
          ...prevState,
          items: prevState.items.filter((item) => item._id !== templateToDelete)
        }));
      }
    } catch (error) {
      console.error('Error deleting template:', error);
    } finally {
      setDeleteModalVisible(false); // Close the confirmation modal
    }
  };
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };
  return (
    <>
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Suggest Exercise table</SoftTypography>
              <div style={{ padding: '1rem', width: '80%' }}>
  <SoftBox display="flex" justifyContent="space-between" gap="1rem">
    {/* Search Input with 50% Width */}
    <SoftBox style={{ width: '50%' }}>
      <SoftInput
        type="text"
        placeholder="Search Physiotherapist"
        value={searchQuery}
        onChange={(e) => {
          setSearchQuery(e.target.value);
          setPage(0);
        }}
        style={{ width: '100%' }}
      />
    </SoftBox>

    {/* Sort Order Box with 50% Width */}
    <SoftBox display="flex" alignItems="center" gap={1} style={{ width: '50%' }}>
      <SoftTypography
        variant="body2"
        style={{ fontSize: '0.8rem', whiteSpace: 'nowrap' }}
      >
        Sort Order:
      </SoftTypography>
      <select
        className="form-select"
        value={sortOrder}
        onChange={(e) => setSortOrder(e.target.value)}
        style={{ fontSize: '0.8rem', padding: '8px 8px', width: '100%' }}
      >
        <option value="">Select Order</option>
        <option value="asc">Ascending</option>
        <option value="desc">Descending</option>
      </select>
    </SoftBox>
  </SoftBox>
</div>
            </SoftBox>
            {isLoading
              ? (
                <>
                <SoftBox display="flex" justifyContent="center" alignItems="center" p={3}>
     <Spinner 
       animation="border" 
       role="status" 
       style={{
         width: '3rem',
         height: '3rem',
         borderWidth: '0.3rem',
         color: '#007bff', // Customize to match your theme
         borderRightColor: 'transparent'// Gives a cool effect
       }}
     />
   </SoftBox></>
                )
              : (
              <SoftBox
                sx={{
                  '& .MuiTableRow-root:not(:last-child)': {
                    '& td': {
                      borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`
                    }
                  }
                }}
              >
              {physiotherapistList?.items?.length === 0 ? (<div style={{ textAlign: 'center' }}><SoftTypography variant='h6' color='textSecondary'> No Template found </SoftTypography></div>) : <Table columns={authorsTableData?.columns} rows={authorsTableData?.rows}/>}
              </SoftBox>
                )}

<SoftBox display="flex" justifyContent="center" p={2}>
  <Pagination className='custom-pagination' style={{ display: 'flex', gap: '8px', padding: '10px', borderRadius: '8px', backgroundColor: '#f1f3f5' }}>
    {/* <Pagination.Prev 
      onClick={() => handlePageChange(page - 1)} 
      disabled={page === 0} 
      style={{ padding: '5px 10px', fontSize: '1rem', color: '#007bff', cursor: 'pointer', borderRadius: '4px' }}
    /> */}
    
    {Array.from({ length: totalPages }, (_, idx) => (
      <Pagination.Item
        key={idx}
        active={idx === page}
        onClick={() => handlePageChange(idx)}
        style={{
          fontSize: '0.9rem',
          color: idx === page ? '#ffffff' : '#007bff',
          backgroundColor: idx === page ? '#007bff' : 'transparent',
          borderRadius: '4px',
          border: idx === page ? 'none' : '1px solid #007bff',
          cursor: 'pointer'
        }}
      >
        {idx + 1}
      </Pagination.Item>
    ))}
    
    {/* <Pagination.Next 
      onClick={() => handlePageChange(page + 1)} 
      disabled={page === totalPages - 1} 
      style={{ padding: '5px 10px', fontSize: '1rem', color: '#007bff', cursor: 'pointer', borderRadius: '4px' }}
    /> */}
  </Pagination>
</SoftBox>
          </Card>
        </SoftBox>
        {/* <Card>
          <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <SoftTypography variant="h6">Phys table</SoftTypography>
          </SoftBox>
          <SoftBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            <Table columns={prCols} rows={prRows} />
          </SoftBox>
        </Card> */}
      </SoftBox>
      <Footer />
    </DashboardLayout>
    <ConfirmModal
  title="Confirm Delete"
  message="Are you sure you want to delete this template?"
  onConfirm={confirmDelete} // Call confirmDelete on confirmation
  onCancel={() => setDeleteModalVisible(false)} // Close modal on cancel
  isVisible={deleteModalVisible} // Control visibility of the modal
/>

    <Modal show={show} onHide={handleClose} centered size="lg">
                    <Modal.Header closeButton className='justify-content-start'>
                        <Modal.Title className='text-center'>Exercise List</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div className='body-content'>

                            {cart && cart.length > 0 && cart.map((item, index) => {
                              return (
                                    <>
                                        <div className='exercise-card mb-3' style={{ boxShadow: 'none' }}>
                                            <div className='img-text-part'>
                                                <div className='img-block'>
                                                    <img src={item
                                                      ?.exerciseImage} height={130} width={130} style={{ borderRadius: '10%' }} alt='ex-1' />
                                                </div>
                                                <div className='text-part ps-3'>
                                                <div className="info-section">
              {/* <span className="label m-2">Title:</span> */}
              <h6 className="data m-2">{item
                ?.title} </h6> </div>

                                                          <div className="info-section">
              {/* <span className="label m-2">Equipment:</span> */}
              <span className="data m-2">
                {item?.equipment && item.equipment.length > 0
                  ? item.equipment.map((equip, index) => (
                      <React.Fragment key={index}>
                        {index > 0 && ' , '}
                        {equip}
                      </React.Fragment>
                  ))
                  : 'No Equipment'}
              </span>
            </div>
       <div className="info-section">
              {/* <span className="label m-2">BodyPart:</span> */}
              <span className="data m-2">
                {item?.body_part_id &&
                  item?.body_part_id.length > 0 &&
                  item?.body_part_id.map((itemEq, ind) => (
                    <React.Fragment key={ind}>
                      {ind > 0 && ' , '}
                      {itemEq?.title}
                    </React.Fragment>
                  ))}
              </span>
            </div>
                                                    {/* <p style={{ cursor: "pointer" }} >LTR - WIG - WAGS - KNEE ROCKS </p> */}

                                                </div>
                                            </div>
                                            <div className="checkbox me-2" style={{ cursor: 'pointer' }}
                                            >
                                                <Nav variant="pills" className="flex-column">
                                                    <Nav.Item className='d-flex justify-content-start align-items-center'>
                                                        <div className="checkbox me-2 sidebarCheckbox">
                                                            <input
                                                                type="checkbox"
                                                                id={`checkbox_${'morning'}`}
                                                                checked={true}
                                                            />
                                                            <label htmlFor={`checkbox_${'morning'}`}></label>
                                                        </div>
                                                        <Nav.Link eventKey="first">

                                                        </Nav.Link>

                                                    </Nav.Item>

                                                </Nav>

                                            </div>
                                        </div>

                                        <hr />
                                    </>
                              )
                            })}

                        </div>

                    </Modal.Body>

                </Modal>
</>
  )
}

export default TemplateList
