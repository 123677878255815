import React from 'react'
import Dashboard from 'layouts/dashboard'
import Tables from 'layouts/tables'
// import Billing from 'layouts/billing'
// import RTL from 'layouts/rtl'
import Profile from 'layouts/profile'
import SignIn from 'layouts/authentication/sign-in'

// Soft UI Dashboard React icons
import Shop from 'examples/Icons/Shop'
import Office from 'examples/Icons/Office'
// import Settings from 'examples/Icons/Settings'
import Document from 'examples/Icons/Document'
import CustomerSupport from 'examples/Icons/CustomerSupport'
import CreditCard from 'examples/Icons/CreditCard'
import Patients from 'layouts/tables/patient'
import Clinics from 'layouts/tables/clinic'
import PatientForPhysio from 'layouts/tables/patientForPhysio'
import Community from 'layouts/tables/Community'
import ExerciseForm from 'examples/ExerciseForm'
import AddBodyPart from 'examples/AddBodyPart'
import AddSurgeryTemplate from 'examples/AddSurgeryTemplate'
import AddEquipment from 'examples/AddEquipment'
import AddMovementType from 'examples/AddMovementType'
import Exercise from 'layouts/tables/Exercise'
import ExerciseList from 'layouts/tables/ExerciseList'
import BodyPartList from 'layouts/tables/BodyPartList'
import AddLanguage from 'examples/AddLanguage'
import AddDescExercise from 'examples/AddDescExericse'
import TemplateList from 'layouts/tables/TemplateList'
import EditExercise from 'examples/EditExercise'
import EditPatient from 'examples/EditPatient'
import EditPhysiotherapist from 'examples/EditPhysiotherapist'
import Version from 'layouts/tables/Version'
import EditVersion from 'examples/EditVersion'
import Subscribers from 'layouts/tables/Subscribers'
import CreditPrice from 'layouts/tables/CreditPrice'
import PaidPhysiotherapist from 'layouts/tables/PaidPhysiotherapist'
import PaidPatient from 'layouts/tables/PaidPatient'
import PhysioPaymentHistory from 'layouts/tables/PhysioPaymentHistory'
import PatientPaymentHistory from 'layouts/tables/PatientPaymentHistory'
import AddUserToken from 'examples/AddUserToken'
import PremiumUserToken from 'layouts/tables/PremiumUserToken'
import TicketList from 'layouts/tables/TicketList'
import TicketReply from 'layouts/tables/TicketReply'
import ClinicPhysiotherapist from 'layouts/tables/ClinicPhysiotherapist'

const handleLogout = () => {
  // Add your logout logic here, e.g., clearing authentication tokens or state
  // For example, you can use localStorage to clear a token:
  localStorage.removeItem('user')

  // Redirect the user to the login page or perform any other necessary actions
  // For example, you can use window.location to navigate to the login page:
  window.location.href = '/authentication/sign-in'
}
const routes = [
  {
    type: 'collapse',
    name: 'Dashboard',
    key: 'dashboard',
    route: '/dashboard',
    icon: <Shop size="12px" />,
    component: <Dashboard />,
    noCollapse: true
  },
  {
    type: 'collapse',
    name: 'Tickets',
    key: 'ticket',
    route: '/tickets',
    icon: <Shop size="12px" />,
    component: <TicketList />,
    noCollapse: true
  },
  {
    type: 'collapse',
    name: 'Token',
    key: 'Token',
    route: '/Token',
    icon: <Shop size="12px" />,
    component: <AddUserToken />,
    noCollapse: true
  },
  {
    type: 'collapse',
    name: 'Premium Token',
    key: 'GeneratePremium',
    route: '/GeneratePremium',
    icon: <Shop size="12px" />,
    component: <PremiumUserToken />,
    noCollapse: true
  },
  {
    type: 'collapse',
    name: 'Physiothepist',
    key: 'physiothepist',
    route: '/physiothepist',
    icon: <Office size="12px" />,
    component: <Tables />,
    noCollapse: true
  },
  {
    type: 'collapse',
    name: 'Patient',
    key: 'patient',
    route: '/patient',
    icon: <CreditCard size="12px" />,
    component: <Patients />,
    noCollapse: true
  },
  {
    type: 'collapse',
    name: 'Clinic',
    key: 'clinic',
    route: '/clinic',
    icon: <CreditCard size="12px" />,
    component: <Clinics />,
    noCollapse: true
  },
  {
    type: 'collapse',
    name: 'Community',
    key: 'community',
    route: '/community',
    icon: <CreditCard size="12px" />,
    component: <Community />,
    noCollapse: true
  },

  {
    type: 'collapse',
    name: 'Exercise List',
    key: 'ExerciseList',
    route: '/exerciseList',
    icon: <CreditCard size="12px" />,
    component: <ExerciseList />,
    noCollapse: true
  },
  {
    type: 'collapse',
    name: 'BodyPart List',
    key: 'BodyPartList',
    route: '/bodyPartList',
    icon: <CreditCard size="12px" />,
    component: <BodyPartList />,
    noCollapse: true
  },
  {
    type: 'collapse',
    name: 'Add Exercise',
    key: 'Exercise',
    route: '/Exercise',
    icon: <CreditCard size="12px" />,
    component: <ExerciseForm />,
    noCollapse: true
  },
  {
    type: 'collapse',
    name: 'Add Bodypart',
    key: 'Bodypart',
    route: '/Bodypart',
    icon: <CreditCard size="12px" />,
    component: <AddBodyPart />,
    noCollapse: true
  },
  {
    type: 'collapse',
    name: 'Template Add',
    key: 'template',
    route: '/template',
    icon: <CreditCard size="12px" />,
    component: <AddSurgeryTemplate />,
    noCollapse: true
  },
  {
    type: 'collapse',
    name: 'Template List',
    key: 'templateList',
    route: '/templateList',
    icon: <CreditCard size="12px" />,
    component: <TemplateList />,
    noCollapse: true
  },
  {
    type: 'collapse',
    name: 'Equipment',
    key: 'Equipment',
    route: '/Equipment',
    icon: <CreditCard size="12px" />,
    component: <AddEquipment />,
    noCollapse: true
  },
  {
    type: 'collapse',
    name: 'MovementType',
    key: 'MovementType',
    route: '/MovementType',
    icon: <CreditCard size="12px" />,
    component: <AddMovementType />,
    noCollapse: true
  },
  {
    type: 'collapse',
    name: 'Suggest Exercise',
    key: 'SuggestExercise',
    route: '/SuggestExercise',
    icon: <CreditCard size="12px" />,
    component: <Exercise />,
    noCollapse: true
  },
  {
    type: 'collapse',
    name: 'Add Language',
    key: 'addLanguage',
    route: '/addLanguage',
    icon: <CreditCard size="12px" />,
    component: <AddLanguage />,
    noCollapse: true
  },
  {
    type: 'collapse',
    name: 'Version',
    key: 'version',
    route: '/version',
    icon: <CreditCard size="12px" />,
    component: <Version />,
    noCollapse: true
  },
  {
    type: 'collapse',
    name: 'Subcribers',
    key: 'Subcribers',
    route: '/subcribers',
    icon: <CreditCard size="12px" />,
    component: <Subscribers />,
    noCollapse: true
  },
  {
    type: 'collapse',
    name: 'Patient Credit Amount',
    key: 'Credit',
    route: '/creditAmount',
    icon: <CreditCard size="12px" />,
    component: <CreditPrice />,
    noCollapse: true
  },
  {
    type: 'collapse',
    name: 'Paid Physiotherapist',
    key: 'PaidPhysiotherapist',
    route: '/PaidPhysiotherapist',
    icon: <CreditCard size="12px" />,
    component: <PaidPhysiotherapist />,
    noCollapse: true
  },
  {
    type: 'collapse',
    name: 'Paid Patient',
    key: 'PaidPatient',
    route: '/PaidPatient',
    icon: <CreditCard size="12px" />,
    component: <PaidPatient />,
    noCollapse: true
  },
  // {
  //   type: 'collapse',
  //   name: 'Billing',
  //   key: 'billing',
  //   route: '/billing',
  //   icon: <CreditCard size="12px" />,
  //   component: <Billing />,
  //   noCollapse: true
  // },
  // {
  //   type: "collapse",
  //   name: "Virtual Reality",
  //   key: "virtual-reality",
  //   route: "/virtual-reality",
  //   icon: <Cube size="12px" />,
  //   component: <VirtualReality />,
  //   noCollapse: true,
  // },
  // {
  //   type: 'collapse',
  //   name: 'RTL',
  //   key: 'rtl',
  //   route: '/rtl',
  //   icon: <Settings size="12px" />,
  //   component: <RTL />,
  //   noCollapse: true
  // },
  { type: 'title', title: 'Account Pages', key: 'account-pages' },
  {
    type: 'collapse',
    name: 'Profile',
    key: 'profile',
    route: '/profile',
    icon: <CustomerSupport size="12px" />,
    component: <Profile />,
    noCollapse: true
  },
  {
    type: 'collapse',
    name: 'Logout',
    key: 'logout',
    icon: <CustomerSupport size="12px" />,
    noCollapse: true,
    handleLofgout: handleLogout // Use onClick here
  },
  {
    type: 'collapse',
    name: 'Sign In',
    key: 'sign-in',
    route: '/authentication/sign-in',
    icon: <Document size="12px" />,
    component: <SignIn />,
    noCollapse: true
  },
  {
    type: 'collapse',
    name: 'patient',
    key: 'sign-in',
    route: '/patientByPhysio',
    icon: <Document size="12px" />,
    component: <PatientForPhysio />,
    noCollapse: true
  },
  {
    type: 'collapse',
    name: 'Description Add',
    key: 'sign-in',
    route: '/DescriptionAdding',
    icon: <Document size="12px" />,
    component: <AddDescExercise />,
    noCollapse: true
  },
  {
    type: 'collapse',
    name: 'Payment History',
    key: 'sign-in',
    route: '/physiotherapistHistory',
    icon: <Document size="12px" />,
    component: <PhysioPaymentHistory />,
    noCollapse: true
  },
  {
    type: 'collapse',
    name: 'Patient Payment History',
    key: 'sign-in',
    route: '/PatientHistory',
    icon: <Document size="12px" />,
    component: <PatientPaymentHistory />,
    noCollapse: true
  },
  {
    type: 'collapse',
    name: 'Edit Exercise',
    key: 'sign-in',
    route: '/editExercise',
    icon: <Document size="12px" />,
    component: <EditExercise />,
    noCollapse: true
  },
  {
    type: 'collapse',
    name: 'Edit Version',
    key: 'sign-in',
    route: '/editVersion',
    icon: <Document size="12px" />,
    component: <EditVersion />,
    noCollapse: true
  },
  {
    type: 'collapse',
    name: 'Edit Patient',
    key: 'sign-in',
    route: '/EditPatient',
    icon: <Document size="12px" />,
    component: <EditPatient />,
    noCollapse: true
  },
  {
    type: 'collapse',
    name: 'Edit Physiotherapist',
    key: 'sign-in',
    route: '/EditPhysiotherapist',
    icon: <Document size="12px" />,
    component: <EditPhysiotherapist />,
    noCollapse: true
  },
  {
    type: 'collapse',
    name: 'reply',
    key: 'reply',
    route: '/ticketReply',
    icon: <Document size="12px" />,
    component: <TicketReply />,
    noCollapse: true
  },
  {
    type: 'collapse',
    name: 'clinicPhysioList',
    key: 'clinicPhysioList',
    route: '/clinicPhysioList',
    icon: <Document size="12px" />,
    component: <ClinicPhysiotherapist />,
    noCollapse: true
  }
  // {
  //   type: "collapse",
  //   name: "Sign Up",
  //   key: "sign-up",
  //   route: "/authentication/sign-up",
  //   icon: <SpaceShip size="12px" />,
  //   component: <SignUp />,
  //   noCollapse: true,
  // },
]

export default routes
