import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import DashboardLayout from './LayoutContainers/DashboardLayout'
import DashboardNavbar from './Navbars/DashboardNavbar'
import '../assets/css/main.css'
import { getAuthConfig, get, post } from 'libs/http-hydrate'
import { useLocation, useNavigate } from 'react-router-dom'
import Multiselect from 'multiselect-react-dropdown'
import UploadIcon from '../assets/images/upload.svg'
import ReactPlayer from 'react-player'

const EditExercise = () => {
  const initialFormState = {
    body_part_id: [],
    title: '',
    description: '',
    exerciseImage: null,
    videoLink: null,
    Benifits: '',
    count: 0,
    restTime: 0,
    set: 0,
    movementType: '',
    equipment: [],
    typeOfExercise: '' // Add this lines
  }
  // State for form values and errors
  const [formValues, setFormValues] = useState(initialFormState)
  const [formErrors, setFormErrors] = useState({})
  const [images, setImage] = useState({
    equipment: []
  })
  const [bodyPartData, setBodyPart] = useState({
    has_more: false,
    items: []
  })
  const [equipmentPartData, setequipmentPartData] = useState({
    has_more: false,
    items: []
  })
  const [movemntPartData, setmovemntPartData] = useState({
    has_more: false,
    items: []
  })
  const page = 0
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const location = useLocation()
  useEffect(() => {
    getBodyPartData()
  }, [page])

  useEffect(() => {
    if (location?.state?.exercise?._id) {
      setFormValues(location?.state?.exercise)
      const bodyPartId = []
      for (const key in location?.state?.exercise?.body_part_id) {
        bodyPartId.push({
          key: location?.state?.exercise?.body_part_id[key].title,
          value: location?.state?.exercise?.body_part_id[key]._id
        })
      }
      setImage((p) => ({ ...p, body_part_id: bodyPartId }))
    }
  }, [location?.state?.exercise])

  async function getEquipment () {
    try {
      const data = await get(
            `/listEquipment?pageNumber=${page}`,
            getAuthConfig()
      )
      if (data?.status === 200) {
        setequipmentPartData((p) => ({
          ...p,
          items: data?.data?.equipmentList
        }))
        setequipmentPartData((p) => ({ ...p, has_more: data?.data?.has_more }))
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate('/login')
      }
    }
  }
  async function getMovementType () {
    try {
      const data = await get(
            `/listMovementType?pageNumber=${page}`,
            getAuthConfig()
      )
      if (data?.status === 200) {
        setmovemntPartData((p) => ({ ...p, items: data?.data?.equipmentList }))
        setmovemntPartData((p) => ({ ...p, has_more: data?.data?.has_more }))
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate('/login')
      }
    }
  }
  async function getBodyPartData () {
    try {
      const data = await get(
            `/bodyPartListAdmin?pageNumber=${page}`,
            getAuthConfig()
      )
      if (data?.status === 200) {
        setBodyPart((p) => ({ ...p, items: data?.data?.data }))
        setBodyPart((p) => ({ ...p, has_more: data?.data?.has_more }))
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate('/login')
      }
    }
  }
  const [videoUrl, setVideoUrl] = useState(null)

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value, checked, files, type } = e.target

    if (type === 'file' && name === 'exerciseImage') {
      setFormValues({ ...formValues, exerciseImage: files[0] })
    } else if (type === 'file' && name === 'videoLink') {
      const selectedVideo = files[0]
      setFormValues({ ...formValues, videoLink: selectedVideo })
      setVideoUrl(URL.createObjectURL(selectedVideo)) // Set the video URL
    } else if (type === 'checkbox' && name === 'isLive') {
      setFormValues({ ...formValues, [name]: checked })
    } else {
      setFormValues({ ...formValues, [name]: value })
    }
  }

  // Handle form submit
  const handleSubmit = async (e) => {
    e.preventDefault()
    const errors = validate(formValues)
    if (Object.keys(errors).length === 0) {
      handleCreateExercise()
      // Form submission logic here
    } else {
      setFormErrors(errors)
    }
  }
  const validate = (values) => {
    const errors = {}

    if (!values.body_part_id) errors.body_part_id = 'BodyPart is required'
    if (!values.title) errors.title = 'Title is required'
    if (!values.description) errors.description = 'Description is required'
    if (!values.Benifits) errors.Benifits = 'Benefits are required'
    if (!values.exerciseImage) { errors.exerciseImage = 'Exercise image is required' }
    if (!values.videoLink) errors.videoLink = 'Video link is required'

    if (!values.count) {
      errors.count = 'Count is required'
    } else if (isNaN(values.count) || values.count <= 0) {
      errors.count = 'Count must be a positive number'
    }

    if (!values.restTime) {
      errors.restTime = 'Rest Time is required'
    } else if (isNaN(values.restTime) || values.restTime <= 0) {
      errors.restTime = 'Rest time must be a positive number'
    }

    if (!values.set) {
      errors.set = 'Set is required'
    } else if (isNaN(values.set) || values.set <= 0) {
      errors.set = 'Set must be a positive number'
    }

    // if (!values.movementType) errors.movementType = 'Movement Type is required'

    // if (!images.body_part_id) errors.body_part_id = "BodyPart is required";
    // if (values.equipment.length === 0)
    //   errors.equipment = "At least one equipment must be selected";

    // ... Add any other specific validations you need

    return errors
  }

  async function handleCreateExercise () {
    setIsLoading(true) // Start loading
    const formDat = new FormData()
    formDat.append('title', formValues.title)
    formDat.append('description', formValues.description)
    // Assuming formValues.exerciseImage and formValues.videoLink are supposed to be files
    if (formValues.exerciseImage && formValues.exerciseImage instanceof File) {
      formDat.append('exerciseImage', formValues.exerciseImage)
    }

    if (formValues.videoLink && formValues.videoLink instanceof File) {
      formDat.append('videoLink', formValues.videoLink)
    }
    formDat.append('count', formValues.count)
    formDat.append('restTime', formValues.restTime)
    formDat.append('set', formValues.set)
    formDat.append('Benifits', formValues.Benifits)
    if (formValues?.movementType) {
      formDat.append('movementType', formValues.movementType)
    }
    // formDat.append('movementType', formValues.movementType)
    formDat.append('typeOfExercise', formValues.typeOfExercise)
    formDat.append('hold', formValues.hold)
    formDat.append('isLive', formValues.isLive)
    if (images?.equipment?.length > 0) {
      // Appending the data to the URLSearchParams object
      for (const key in images?.equipment) {
        formDat.append('equipment', images?.equipment[key].key)
      }
    } else {
      // Loop through the array and append each item to formData
      formValues?.equipment?.forEach((item, index) => {
        formDat.append(`equipment[${index}]`, item)
      })
    }

    if (images?.body_part_id?.length > 0) {
      for (const key in images?.body_part_id) {
        formDat.append('body_part_id', images?.body_part_id[key].value)
      }
    }
    if (formValues?._id) {
      formDat.append('_id', formValues?._id)
    }
    const url = '/Editexercise'
    try {
      const data = await post(url, formDat, getAuthConfig())
      if (data?.status === 200) {
        navigate('/exerciseList')
      }
      setIsLoading(false) // Start loading
    } catch (error) {
      setIsLoading(false) // Start loading
    }
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Row>
            <Col lg="4">
              <div className="filed">
                {/* <label>Add Profile Image</label> */}
                <div className="upload-part mt-2">
                  <div className="form-group file-area">
                    <input
                      type="file"
                      id="exerciseImage" // Unique ID for exerciseImage
                      required="required"
                      multiple="multiple"
                      accept="image/png,image/gif,image/jpeg,image/jpg"
                      // onChange={(e) => {
                      //   if (
                      //     !(
                      //       e.target.files[0].type === "image/png" ||
                      //       e.target.files[0].type === "image/jpg" ||
                      //       e.target.files[0].type === "image/jpeg" ||
                      //       e.target.files[0].type === "image/gif" ||
                      //       e.target.files[0].type === "application/pdf" ||
                      //       e.target.files[0].type ===
                      //       "application/msword" ||
                      //       e.target.files[0].type ===
                      //       "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      //     )
                      //   ) {
                      //     alert("Only images,pdf,doc,docx allowded");
                      //     return false;
                      //   }
                      //   if (e.target.files[0].size > 2097152) {
                      //     alert("Not More than 2 MB is allowded");
                      //     return false;
                      //   }
                      //   // setPersonalInformation((p) => ({ ...p, profile_image: e.target.files[0] }));
                      // }}

                      name="exerciseImage"
                      className="input-field"
                      onChange={handleChange}
                    />
                    <div className="file-dummy">
                    {formValues.exerciseImage instanceof File
                      ? <div className="success">
            <img
              src={URL.createObjectURL(formValues.exerciseImage)}
              onLoad={() => URL.revokeObjectURL(formValues.exerciseImage)}
              alt="Exercise Preview"
              style={{ maxHeight: '100px', maxWidth: '100px' }}
            />
          </div>
                      : formValues.exerciseImage
                        ? <img
                src={formValues.exerciseImage}
                alt="Exercise Image"
                style={{ maxHeight: '100px', maxWidth: '100px' }}
              />
                        : <div className="default">
              <div className="image-block mb-2">
                <img src={UploadIcon} alt="icon" />
              </div>
              Upload Exercise Image here (png, jpg)
            </div>
      }
                    </div>
                  </div>

                  {formErrors.exerciseImage && (
                    <div className="text-danger">
                      {formErrors.exerciseImage}
                    </div>
                  )}
                </div>
              </div>

              <div className="filed">
                {/* <label>Add Profile Image</label> */}
                <div className="upload-part mt-2">
                  <div className="form-group file-area">
                    <input
                      type="file"
                      id="videoLink" // Unique ID for videoLink
                      required="required"
                      multiple="multiple"
                      // onChange={(e) => {
                      //   if (
                      //     !(
                      //       e.target.files[0].type === "image/png" ||
                      //       e.target.files[0].type === "image/jpg" ||
                      //       e.target.files[0].type === "image/jpeg" ||
                      //       e.target.files[0].type === "image/gif" ||
                      //       e.target.files[0].type === "application/pdf" ||
                      //       e.target.files[0].type ===
                      //       "application/msword" ||
                      //       e.target.files[0].type ===
                      //       "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      //     )
                      //   ) {
                      //     alert("Only images,pdf,doc,docx allowded");
                      //     return false;
                      //   }
                      //   if (e.target.files[0].size > 2097152) {
                      //     alert("Not More than 2 MB is allowded");
                      //     return false;
                      //   }
                      //   // setPersonalInformation((p) => ({ ...p, profile_image: e.target.files[0] }));
                      // }}

                      accept="video/mp4,video/x-m4v,video/*"
                      name="videoLink"
                      className="input-field"
                      onChange={handleChange}
                    />

                    <div className="file-dummy">

                    {videoUrl
                      ? (
  <div className="success">

<ReactPlayer url={videoUrl} height={'130px'} width={'200px'} controls />
    {/* <video
      width="200"
      height="100"
      controls
      onLoadStart={() => URL.revokeObjectURL(videoUrl)}
    >
      <source src={videoUrl} type="video/mp4" />
      Your browser does not support the video tag.
    </video> */}
  </div>
                        )
                      : formValues.videoLink
                        ? (

                          <ReactPlayer url={formValues.videoLink} height={'130px'} width={'200px'} controls />

                          )
                        : (
  <div className="default">
    <div className="image-block mb-2">
      <img src={UploadIcon} alt="icon" />
    </div>
    Upload Exercise Video here
  </div>
                          )}

                    </div>
                  </div>
                </div>
                {formErrors.videoLink && (
                  <div className="text-danger">{formErrors.videoLink}</div>
                )}
              </div>
            </Col>
            <Col lg="8">
              <div className="form-data" style={{ maxWidth: '100%' }}>
                {/* Title */}
                <div className="mb-1 filed">
                  <label htmlFor="title" className="form-label">
                    Title
                  </label>
                  <input
                    type="text"
                    name="title"
                    className="input-field"
                    onChange={handleChange}
                    value={formValues.title}
                    placeholder="Exercise Title"
                  />
                  {formErrors.title && (
                    <div className="text-danger">{formErrors.title}</div>
                  )}
                </div>

                {/* Description */}
                <div className="mb-1 filed">
                  <label htmlFor="description" className="form-label">
                    Description
                  </label>
                  <textarea
                    type="text"
                    name="description"
                    className="input-field w-100 form-control"
                    onChange={handleChange}
                    value={formValues.description}
                    rows={3}
                    placeholder="Exercise Description"
                  />
                  {formErrors.description && (
                    <div className="text-danger">{formErrors.description}</div>
                  )}
                </div>

                {/* Body Part */}
                <div className="mb-1 filed filed">
                  <label htmlFor="body_part_id" className="form-label">
                    Body Part
                  </label>
                  <Multiselect
                    options={bodyPartData?.items?.map((bodyPart) => ({
                      key: bodyPart.title,
                      value: bodyPart._id
                    }))} // closeIcon="close"
                    customArrow={
                      <img src="data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e" />
                    }
                    name="bodyPArt"
                    displayValue="key"
                    placeholder="Select BodyPart"
                    showArrow={true}
                    keepSearchTerm={true}
                    onSelect={(selectedList, selectedItem) => {
                      setImage((p) => ({ ...p, body_part_id: selectedList }))
                      // handleTimesChange(itemId, selectedList)
                    }} // Function will trigger on select event
                    onRemove={(selectedList, removedItem) => {
                      // handleTimesChange(itemId, selectedList)
                      setImage((p) => ({ ...p, body_part_id: selectedList }))
                    }}
                    selectedValues={formValues?.body_part_id?.map(
                      (bodyPart) => ({
                        key: bodyPart?.title,
                        value: bodyPart?._id
                      })
                    )}
                    onFocus={getBodyPartData}
                  />

                  {/* <select
                    name="body_part_id"
                    className="form-control form-select input-field"
                    onChange={handleChange}
                    value={formValues.body_part_id}
                    onFocus={getBodyPartData}
                  >
                    <option
                      value=""
                      label={
                        formValues?.body_part_id?.title
                          ? formValues?.body_part_id?.title
                          : "Select Body Part"
                      }
                    />
                    {bodyPartData.items.map((bodyPart) => (
                      <option key={bodyPart._id} value={bodyPart._id}>
                        {bodyPart.title}
                      </option>
                    ))}
                  </select> */}
                  {formErrors.body_part_id && (
                    <div className="text-danger">{formErrors.body_part_id}</div>
                  )}
                </div>
                {/* Movement Type */}
                <div className="mb-1 filed">
                  <label htmlFor="movementType" className="form-label">
                    Movement Type
                  </label>
                  <select
                    name="movementType"
                    className="form-select"
                    onChange={handleChange}
                    value={formValues.movementType}
                    onFocus={getMovementType}
                  >
                    <option value="">
                      {' '}
                      {formValues?.movementType
                        ? formValues?.movementType
                        : 'Select Movement Type'}
                    </option>
                    {movemntPartData.items.map((bodyPart) => (
                      <option key={bodyPart._id} value={bodyPart.title}>
                        {bodyPart.title}
                      </option>
                    ))}{' '}
                  </select>
                  {formErrors.movementType && (
                    <div className="text-danger">{formErrors.movementType}</div>
                  )}
                </div>

                {/* Equipment */}
                <div className="mb-1 filed" onFocus={getEquipment} tabIndex="0">
                  <label htmlFor="equipment" className="form-label">
                    Equipment
                  </label>
                  <Multiselect
                    options={equipmentPartData?.items?.map((bodyPart) => ({
                      key: bodyPart.title,
                      value: bodyPart.title
                    }))} // closeIcon="close"
                    customArrow={
                      <img src="data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e" />
                    }
                    displayValue="key"
                    placeholder="Select Equipment"
                    showArrow={true}
                    keepSearchTerm={true}
                    onSelect={(selectedList, selectedItem) => {
                      setImage((p) => ({ ...p, equipment: selectedList }))
                      // handleTimesChange(itemId, selectedList)
                    }} // Function will trigger on select event
                    onRemove={(selectedList, removedItem) => {
                      // handleTimesChange(itemId, selectedList)
                      setImage((p) => ({ ...p, equipment: selectedList }))
                    }}
                    selectedValues={formValues?.equipment?.map((bodyPart) => ({
                      key: bodyPart,
                      value: bodyPart
                    }))}
                    onFocus={getEquipment}
                  />
                </div>
                {/* Benefits */}
                <div className="mb-1 filed">
                  <label htmlFor="Benifits" className="form-label">
                    Benefits
                  </label>
                  <input
                    type="text"
                    name="Benifits"
                    className="input-field"
                    onChange={handleChange}
                    value={formValues.Benifits}
                    placeholder="Exercise Benefits"
                  />
                  {formErrors.Benifits && (
                    <div className="text-danger">{formErrors.Benifits}</div>
                  )}
                </div>

                {/* Exercise Image */}
                {/* <div className="mb-1 filed">
              <label htmlFor="exerciseImage" className="form-label">
                Exercise Image
              </label>
              {
  formValues?.exerciseImage &&
  (typeof formValues.exerciseImage === 'object' ?
    <img
      src={URL.createObjectURL(formValues.exerciseImage)}
      onLoad={() => URL.revokeObjectURL(formValues.exerciseImage)}
      alt="Exercise Preview"
      height={50}
      width={50}
    /> :
    <img
      src={formValues.exerciseImage}
      alt="Exercise Image"
      height={50}
      width={50}
    />
  )
}
              <input
                type="file"
                accept=".jpg, .jpeg, .png, .gif, .webp"
                name="exerciseImage"
                className="input-field"
                onChange={handleChange}
              />
              {formErrors.exerciseImage && (
                <div className="text-danger">{formErrors.exerciseImage}</div>
              )}
            </div> */}

                {/* Video Link */}
                {/* <div className="mb-1 filed">
              <label htmlFor="videoLink" className="form-label">
                Video Link
              </label>
              {
        formValues?.videoLink &&
        (typeof formValues.videoLink === 'object' ?
            <video width="320" height="240" controls>
                <source src={URL.createObjectURL(formValues.videoLink)} type="video/mp4"/>
                Your browser does not support the video tag.
            </video> :
            <video width="320" height="240" controls>
                <source src={formValues.videoLink} type="video/mp4"/>
                Your browser does not support the video tag.
            </video>
        )
    }
              <input
                type="file"
                accept="video/mp4,video/x-m4v,video/*"
                name="videoLink"
                className="input-field"
                onChange={handleChange}
              />
              {formErrors.videoLink && (
                <div className="text-danger">{formErrors.videoLink}</div>
              )}
            </div> */}
                <Row>
                  <Col lg="6">
                    {/* Count */}
                    <div className="mb-1 filed">
                      <label htmlFor="count" className="form-label">
                        Count
                      </label>

                      <Form.Select
                        aria-label="Default select example"
                        name="count"
                        className="mb-3"
                        onChange={handleChange}
                      >
                        <option>{formValues.count}</option>
                        {[...Array(30)].map((_, index) => (
                          <option key={index + 1} value={index + 1}>
                            {index + 1}
                          </option>
                        ))}
                      </Form.Select>
                      {/* <input
                    type="number"
                    name="count"
                    className="input-field"
                    onChange={handleChange}
                    value={formValues.count}
                  /> */}
                      {formErrors.count && (
                        <div className="text-danger">{formErrors.count}</div>
                      )}
                    </div>

                    {/* Rest Time */}
                    <div className="mb-1 filed">
                      <label htmlFor="restTime" className="form-label">
                        Rest Time
                      </label>

                      <Form.Select
                        aria-label="Default select example"
                        className="mb-3"
                        name="restTime"
                        onChange={handleChange}
                      >
                        <option>{formValues.restTime}</option>

                        {[...Array(13)].map((_, index) => (
                          <option key={index} value={(index) * 5}>
                            {(index) * 5} seconds
                          </option>
                        ))}
                      </Form.Select>
                      {/* <input
                    type="number"
                    name="restTime"
                    className="input-field"
                    onChange={handleChange}
                    value={formValues.restTime}
                  /> */}
                      {formErrors.restTime && (
                        <div className="text-danger">{formErrors.restTime}</div>
                      )}
                    </div>
                  </Col>

                  <Col lg="6">
                    {/* Set */}
                    <div className="mb-1 filed">
                      <label htmlFor="set" className="form-label">
                        Set
                      </label>

                      <Form.Select
                        aria-label="Default select example"
                        name="set"
                        className="mb-3"
                        onChange={handleChange}
                      >
                        <option>{formValues.set}</option>
                        <option value="1">01</option>
                        <option value="2">02</option>
                        <option value="3">03</option>
                        <option value="4">04</option>
                        <option value="5">05</option>
                        <option value="6">06</option>
                        <option value="7">07</option>
                        <option value="8">08</option>
                        <option value="9">09</option>
                        <option value="10">10</option>

                      </Form.Select>
                      {/* <input
                    type="number"
                    name="set"
                    className="input-field"
                    onChange={handleChange}
                    value={formValues.set}
                  /> */}
                      {formErrors.set && (
                        <div className="text-danger">{formErrors.set}</div>
                      )}
                    </div>
                    <div className="filed">
                      <label>Holds </label>
                      <Form.Select
                        aria-label="Default select example"
                        className="mb-3"
                        name="hold"
                        placeholder="Select Seconds"
                        onChange={handleChange}
                      >
                        <option>{formValues?.hold}</option>

                        {[...Array(13)].map((_, index) => (
                          <option key={index + 1} value={(index + 1) * 5}>
                            {(index + 1) * 5} seconds
                          </option>
                        ))}
                      </Form.Select>
                    </div>

                  </Col>
                </Row>
                <div className="mb-1 filed d-flex">
  <label htmlFor="isLive" className="form-label" style={{ display: 'inline-block', marginRight: '10px' }}>
    Want to live this Exercise ?
  </label>

  <input
    type="checkbox"
    name="isLive"
    checked={formValues.isLive}
    onChange={handleChange}
    className="input-field w-50"
    style={{ display: 'inline-block' }}
  />
  {formErrors.isLive && (
    <div className="text-danger">{formErrors.isLive}</div>
  )}
</div>
                {/* Submit Button */}
                <button
                  type="submit"
                  className=" theme-button btn btn-primary"
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  {isLoading ? 'Submitting' : 'Submit'}
                </button>
              </div>
            </Col>
          </Row>
    </DashboardLayout>
  )
}

export default EditExercise
