import React, { useState } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DashboardLayout from './LayoutContainers/DashboardLayout'
import DashboardNavbar from './Navbars/DashboardNavbar'
import '../assets/css/main.css'
import { post } from 'libs/http-hydrate'
import { useLocation, useNavigate } from 'react-router-dom'
import { Auth } from 'libs/auth'

const EditVersion = () => {
  const location = useLocation()
  const initialValues = {
    ...location?.state?.version
  }

  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()

  const validationSchema = Yup.object().shape({
    platform: Yup.string().required('platform is required'),
    version_number: Yup.string().required('version number is required'),
    update_type: Yup.string().required('update type is required'),
    release_notes: Yup.string().required('release notes is required'),
    force_update: Yup.string().required('force update Time is required')
  })

  async function handleCreateExercise (formdata) {
    const requestData = {
      id: location?.state?.version?._id,
      platform: location?.state?.version?.platform,
      version_number: formdata.version_number,
      update_type: formdata.update_type,
      release_notes: formdata.release_notes,
      force_update: formdata.force_update
    }
    setIsLoading(true) // Start loading
    const url = '/version'
    const authFunctions = Auth()

    try {
      const data = await post(url, requestData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Web ' + 'Admin ' + authFunctions.getAuthToken()
        }
      })
      if (data?.status === 200) {
        navigate('/version')
      }
    } catch (error) {
    } finally {
      setIsLoading(false) // Stop loading
    }
  }

  const handleSubmit = (values, { setSubmitting }) => {
    handleCreateExercise(values)
    setSubmitting(false)
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <div className="mb-1">
            <label htmlFor="platform" className="form-label">
            platform
            </label>
            <Field as="select" name="platform" className="form-control" disabled>
      <option value="">Select Platform</option>
      <option value="ios">iOS</option>
      <option value="android">Android</option>
    </Field>            <ErrorMessage name="platform" component="div" className="text-danger" />
          </div>

          <div className="mb-1">
            <label htmlFor="version_number" className="form-label">
            version number
            </label>
            <Field type="text" name="version_number" className="form-control" />
            <ErrorMessage name="version_number" component="div" className="text-danger" />
          </div>

          <div className="mb-1">
            <label htmlFor="update_type" className="form-label">
            update type
            </label>
            <Field as="select" name="update_type" className="form-control" disabled>
      <option value="">Select Update Type</option>
      <option value="patient">patient</option>
      <option value="physiotherapist">patient</option>
    </Field>            <ErrorMessage name="update_type" component="div" className="text-danger" />
          </div>
          <div className="mb-1">
            <label htmlFor="release_notes" className="form-label">
            release notes
            </label>
            <Field type="text" name="release_notes" className="form-control" />
            <ErrorMessage name="release_notes" component="div" className="text-danger" />
          </div>
          <div className="mb-1">
            <label htmlFor="force_update" className="form-label">
            force update
            </label>
            <Field as="select" name="force_update" className="form-control">
      <option value="">Select Force Update</option>
      <option value={true}>Yes</option>
      <option value={false}>No</option>
    </Field>            <ErrorMessage name="force_update" component="div" className="text-danger" />
          </div>

          <button type="submit" className="btn btn-primary">
          {isLoading ? 'Submitting...' : 'Submit'}

          </button>
        </Form>
      </Formik>
    </DashboardLayout>
  )
}

export default EditVersion
