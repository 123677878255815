// @mui material components
import Card from '@mui/material/Card'

// Soft UI Dashboard React components
import SoftBox from 'components/SoftBox'
import SoftTypography from 'components/SoftTypography'

// Soft UI Dashboard React examples
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import Footer from 'examples/Footer'
import Table from 'examples/Tables/Table'
import SoftInput from 'components/SoftInput'
import Modal from 'react-bootstrap/Modal'

// Data
import React, { useEffect, useState } from 'react'
import { getAuthConfig, get, post } from 'libs/http-hydrate'
import SoftAvatar from 'components/SoftAvatar'
// import SoftBadge from 'components/SoftBadge'
import PropTypes from 'prop-types' // Import PropTypes
import moment from 'moment'
import { Auth } from 'libs/auth'
// import { Link } from 'react-router-dom'
function CreditPrice () {
  // const { columns: prCols, rows: prRows } = projectsTableData
  const [isLoading, setIsLoading] = useState(false)
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [cart, setCart] = useState([])
  const [physiotherapistList, setPhysiotherapistList] = useState({
    items: [],
    has_more: false
  })
  const [page, setPage] = useState(0)
  const [searchQuery, setSearchQuery] = useState('') // State for search query
  useEffect(() => {
    getPhysiotherapistList()
  }, [page, searchQuery])

  async function getPhysiotherapistList () {
    try {
      setIsLoading(true)
      const data = await get('/PatientCreditPriceList', getAuthConfig())
      if (data?.status === 200) {
        // if (page === 0) {
        setPhysiotherapistList((p) => ({ ...p, items: data?.data?.msg }))
        // } else {
        //   setPhysiotherapistList((p) => ({ ...p, items: physiotherapistList?.items?.concat(data?.data?.data) }))
        // }
        setPhysiotherapistList((p) => ({ ...p, has_more: data?.data?.has_more }))
      }
    } catch (error) {

    } finally {
      setIsLoading(false) // Set isLoading to false in the finally block
    }
  }

  function Author ({ image, name, email, onClick }) {
    return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5} onClick={onClick}>
      <SoftBox mr={2}>
        <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="button" fontWeight="medium">
          {name}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {email}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
    )
  }

  function Function ({ job, org }) {
    return (
    <SoftBox display="flex" flexDirection="column">
      <SoftTypography variant="caption" fontWeight="medium" color="text">
        {job}
      </SoftTypography>
      <SoftTypography variant="caption" color="secondary">
        {org}
      </SoftTypography>
    </SoftBox>
    )
  }
  // PropTypes definition for Author
  Author.propTypes = {
    image: PropTypes.string.isRequired, // Assuming 'image' is a string
    name: PropTypes.string.isRequired, // Assuming 'name' is a string
    email: PropTypes.string.isRequired // Assuming 'email' is a string
  }

  // PropTypes definition for Function
  Function.propTypes = {
    job: PropTypes.string.isRequired, // Assuming 'job' is a string
    org: PropTypes.string.isRequired // Assuming 'org' is a string
  }

  const authorsTableData = {
    columns: [
      { name: 'Amount', align: 'left' },
      { name: 'created', align: 'left' },
      { name: 'action', align: 'left' }
    ],

    rows: physiotherapistList.items && physiotherapistList.items.map((item) => ({
      Amount: (
      <Author name={item.PatientCreditAmount} email={item.email} style={{ cursor: 'pointer' }} onClick={(e) => {
        e.preventDefault()
        setCart(item)
        handleShow()
      }}/>
      ),
      created: (
      <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {moment(item.createdAt).format('DD / MM / YYYY')}
      </SoftTypography>
      ),
      action: (
      <>
       <SoftTypography
        component="a"
        href="#"
        variant="caption"
        color="secondary"
        fontWeight="medium"
        onClick={() => {
          setCart(item)
          handleShow()
        }} // Add onClick handler here
      >
        Edit
      </SoftTypography>
      </>
      )
    }))
  }
  async function handleDeleteClick () {
    try {
      const authFunctions = Auth()
      const formdata = { id: cart?._id, PatientCreditAmount: cart?.PatientCreditAmount }
      const data = await post('/updateCreditPrice', formdata, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'Web ' + 'Admin ' + authFunctions.getAuthToken()
        }
        // Add any other necessary configurations here
      })
      if (data?.status === 200) {
        getPhysiotherapistList()
        handleClose()
      }
    } catch (error) {
    }
  }

  return (
    <>
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Patient Credit Price Amount  </SoftTypography>
              <div style={{ padding: '1rem' }}>
              <SoftBox pr={1}>
              <SoftInput
                type="text"
                placeholder="Search Subscribers"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value)
                  setPage(0)
                }}
              />
              </SoftBox>
              </div>
            </SoftBox>
            {isLoading
              ? (
              <>
                <SoftBox display="flex" justifyContent="center" alignItems="center" p={3}>
                  <SoftTypography variant="h6">Loading ... </SoftTypography>
                </SoftBox></>
                )
              : (
              <SoftBox
                sx={{
                  '& .MuiTableRow-root:not(:last-child)': {
                    '& td': {
                      borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`
                    }
                  }
                }}
              >
              {physiotherapistList?.items?.length === 0 ? (<div style={{ textAlign: 'center' }}><SoftTypography variant='h6' color='textSecondary'> No Subcribers found </SoftTypography></div>) : <Table columns={authorsTableData?.columns} rows={authorsTableData?.rows}/>}
              </SoftBox>
                )}

<SoftBox display="flex" justifyContent="center" p={2}>
  <button
    onClick={() => setPage(current => current - 1)}
    disabled={page === 0}
    className='btn btn-primary m-2'
  >
    Previous
  </button>
  <button
    onClick={() => setPage(current => current + 1)}
    disabled={!physiotherapistList?.has_more}
    className='btn btn-primary m-2'
  >
    Next
  </button>
</SoftBox>
          </Card>
        </SoftBox>
        {/* <Card>
          <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <SoftTypography variant="h6">Phys table</SoftTypography>
          </SoftBox>
          <SoftBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            <Table columns={prCols} rows={prRows} />
          </SoftBox>
        </Card> */}
      </SoftBox>
      <Footer />
    </DashboardLayout>

    <Modal show={show} onHide={handleClose} centered size="lg">
                    <Modal.Header closeButton className='justify-content-start'>
                        <Modal.Title className='text-center'>Edit Amount </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div className='body-content text-center'>

                        <input
                            type='number'
                            className='form-control'
                            value={cart.PatientCreditAmount}
                            onChange={(e) => {
                              e.preventDefault()
                              const value = parseInt(e.target.value, 10)
                              if (!isNaN(value) && value >= 0) {
                                setCart((p) => ({ ...p, PatientCreditAmount: value.toString() }))
                              } else if (e.target.value === '') {
                                // Allow clearing the input (setting it to an empty string)
                                setCart((p) => ({ ...p, PatientCreditAmount: '' }))
                              }
                            }}
                            />

                            <button className='btn btn-secondary mt-4' onClick={handleDeleteClick}> Edit </button>

                        </div>

                    </Modal.Body>

                </Modal>
</>
  )
}

export default CreditPrice
