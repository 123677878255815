// @mui material components
import Card from '@mui/material/Card'

// Soft UI Dashboard React components
import SoftBox from 'components/SoftBox'
import SoftTypography from 'components/SoftTypography'

// Soft UI Dashboard React examples
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import Footer from 'examples/Footer'
import Table from 'examples/Tables/Table'
import SoftInput from 'components/SoftInput'
import Switch from '@mui/material/Switch'
// Data
import { useEffect, useState } from 'react'
import { getAuthConfig, get, deletes } from 'libs/http-hydrate'
import SoftAvatar from 'components/SoftAvatar'
import SoftBadge from 'components/SoftBadge'
import PropTypes from 'prop-types' // Import PropTypes
import moment from 'moment'
import { Link, useNavigate } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import Box from '@mui/material/Box'
import InfoIcon from '@mui/icons-material/Info'
import EmailIcon from '@mui/icons-material/Email'
import PhoneIcon from '@mui/icons-material/Phone'
import Button from '@mui/material/Button'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import VerifiedBadge from 'assets/images/quality.png'
import { Pagination, Spinner } from 'react-bootstrap'

function PaidPhysiotherapist () {
  // const { columns: prCols, rows: prRows } = projectsTableData
  const navigate = useNavigate()
  const [totalPages, setTotalPages] = useState(0); // Total pages for pagination
  const [isLoading, setIsLoading] = useState(false)
  const [physiotherapistList, setPhysiotherapistList] = useState({
    items: [],
    has_more: false
  })
  const [page, setPage] = useState(0)
  const [searchQuery, setSearchQuery] = useState('') // State for search query
  const [selectedClinic, setSelectedClinic] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  // Function to handle opening the modal
  const handleOpenModal = (clinic) => {
    setSelectedClinic(clinic)
    setModalOpen(true) // Open the modal
  }

  // Function to handle closing the modal
  const handleCloseModal = () => {
    setModalOpen(false)
  }
  useEffect(() => {
    getPhysiotherapistList()
  }, [page, searchQuery])

  async function getPhysiotherapistList () {
    try {
      setIsLoading(true)
      const data = await get(`/physiotherapistlist?pageNumber=${page}&searchQuery=${searchQuery}&paid=paid`, getAuthConfig())
      if (data?.status === 200) {
        // if (page === 0) {
        setPhysiotherapistList((p) => ({ ...p, items: data?.data?.data }))
        // } else {
        //   setPhysiotherapistList((p) => ({ ...p, items: physiotherapistList?.items?.concat(data?.data?.msg) }))
        // }
        setPhysiotherapistList((p) => ({ ...p, has_more: data?.data?.has_more }))
        setTotalPages(Math.ceil(data?.data?.totalPages)); // Assuming 10 items per pages
      }
    } catch (error) {

    } finally {
      setIsLoading(false) // Set isLoading to false in the finally block
    }
  }
  async function toggleAccess (id, toggle) {
    try {
      setIsLoading(true)
      const endpoint = toggle
        ? `/updateApprovalStatusPhysiotherapist?id=${id}&adminApprovedList=true`
        : `/updateApprovalStatusPhysiotherapist?id=${id}`

      const data = await get(endpoint, getAuthConfig())
      if (data?.status === 200) {
        setPhysiotherapistList((prevList) => {
          // Find the index of the item that needs to be updated
          const index = prevList.items.findIndex(item => item._id === id)
          if (index !== -1) {
            // Create a new array for immutability
            const updatedItems = [...prevList.items]
            // Update the adminApprovedList property for the specified item
            updatedItems[index] = {
              ...updatedItems[index],
              adminApprovedList: toggle
            }
            // Return the updated state
            return { ...prevList, items: updatedItems }
          }
          return prevList // Return the previous state if the item is not found
        })
      }
    } catch (error) {

    } finally {
      setIsLoading(false) // Set isLoading to false in the finally block
    }
  }

  function Author ({ image, name, email, onClick }) {
    return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5} onClick={onClick}>
      <SoftBox mr={2}>
        <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="button" fontWeight="medium">
          {name}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {email}

          <img src={VerifiedBadge} alt="Verified" style={{ marginLeft: '4px', width: '16px', height: '16px' }} />
        </SoftTypography>
      </SoftBox>
    </SoftBox>
    )
  }

  function Function ({ job, org }) {
    return (
    <SoftBox display="flex" flexDirection="column">
      <SoftTypography variant="caption" fontWeight="medium" color="text">
        {job}
      </SoftTypography>
      <SoftTypography variant="caption" color="secondary">
        {org}
      </SoftTypography>
    </SoftBox>
    )
  }
  // PropTypes definition for Author
  Author.propTypes = {
    image: PropTypes.string.isRequired, // Assuming 'image' is a string
    name: PropTypes.string.isRequired, // Assuming 'name' is a string
    email: PropTypes.string.isRequired // Assuming 'email' is a string
  }

  // PropTypes definition for Function
  Function.propTypes = {
    job: PropTypes.string.isRequired, // Assuming 'job' is a string
    org: PropTypes.string.isRequired // Assuming 'org' is a string
  }

  const authorsTableData = {
    columns: [
      { name: 'Physiotherapist', align: 'left' },
      { name: 'designation', align: 'left' },
      { name: 'patient', align: 'center' },
      { name: 'created', align: 'center' },
      { name: 'lastLogin', align: 'center' },
      { name: 'Access', align: 'center' },
      { name: 'action', align: 'center' }
    ],

    rows: physiotherapistList.items && physiotherapistList.items.map((item) => ({
      Physiotherapist: (
      <Author image={item.profile_image ? item?.profile_image : ''} name={item.name} email={item.email ? item?.email : item?.mobile} onClick={() => handleOpenModal(item)}/>
      ),
      designation: <Function job={item.designation} org={item.hospital_name} />,
      patient: (
      <Link to={'/patientByPhysio'} state={{ physioId: item?._id }}>
      <SoftBadge
        variant="gradient"
        badgeContent={ item?.patientCount}
        color={item?.patientCount > 0 ? 'success' : 'secondary'}
        size="xs"
        container
      /></Link>
      ),
      created: (
      <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {moment(item.created_at).format('DD / MM / YYYY')}
      </SoftTypography>
      ),
      lastLogin: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
     {item.lastLoggedIn && moment(item.lastLoggedIn).format('DD / MM / YYYY hh:mm A')}
      </SoftTypography>
      ),
      Access: (
        <Switch checked={item?.adminApprovedList}
        onChange={() => toggleAccess(item._id, !item.adminApprovedList)} // Define this function to handle access toggling
        color="secondary" />
      ),
      action: (
      <>
<SoftTypography
        component="a"
        variant="caption"
        color="secondary"
        fontWeight="medium"
        onClick={() => {
          navigate('/physiotherapistHistory', { state: { physiotherapist: item } })
        }} >
        Credit History
      </SoftTypography> / <SoftTypography
        component="a"
        variant="caption"
        color="secondary"
        fontWeight="medium"
        onClick={() => {
          navigate('/physiotherapistHistory', { state: { physiotherapist: item } })
        }} >
        Payment History
      </SoftTypography> / <SoftTypography
        component="a"
        variant="caption"
        color="secondary"
        fontWeight="medium"
        onClick={() => {
          navigate('/EditPhysiotherapist', { state: { physiotherapist: item } })
        }} >
        Edit
      </SoftTypography>  /  <SoftTypography
        component="a"
        href="#"
        variant="caption"
        color="secondary"
        fontWeight="medium"
        onClick={() => handleDeleteClick(item._id)} // Add onClick handler here
      >
        Delete
      </SoftTypography>
      </>
      )
    }))
  }
  async function handleDeleteClick (patientId) {
    const url = `/DeletePhysiotherapist?id=${patientId}`
    try {
      const data = await deletes(url)
      if (data?.status === 200) {
        // Create a function to remove an item with a specific _id
        function removeItemWithId (array, patientId) {
          return array.filter((item) => item._id !== patientId)
        }
        // Update the state to remove the item
        setPhysiotherapistList((prevState) => ({
          ...prevState,
          items: removeItemWithId(prevState.items, patientId)
        }))
      }
    } catch (error) {
    }
  }
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };
  return (
    <>
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Physiothepist table</SoftTypography>
              <div style={{ padding: '1rem' }}>
              <SoftBox pr={1}>
              <SoftInput
                type="text"
                placeholder="Search Physiotherapist"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value)
                  setPage(0)
                }}
              />
              </SoftBox>
              </div>
            </SoftBox>
            {isLoading
              ? (
                <>
                <SoftBox display="flex" justifyContent="center" alignItems="center" p={3}>
     <Spinner 
       animation="border" 
       role="status" 
       style={{
         width: '3rem',
         height: '3rem',
         borderWidth: '0.3rem',
         color: '#007bff', // Customize to match your theme
         borderRightColor: 'transparent'// Gives a cool effect
       }}
     />
   </SoftBox></>
                )
              : (
              <SoftBox
                sx={{
                  '& .MuiTableRow-root:not(:last-child)': {
                    '& td': {
                      borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`
                    }
                  }
                }}
              >
              {physiotherapistList?.items?.length === 0 ? (<div style={{ textAlign: 'center' }}><SoftTypography variant='h6' color='textSecondary'> No Physiotherapist found </SoftTypography></div>) : <Table columns={authorsTableData?.columns} rows={authorsTableData?.rows}/>}
              </SoftBox>
                )}

<SoftBox display="flex" justifyContent="center" p={2}>
  <Pagination className='custom-pagination' style={{ display: 'flex', gap: '8px', padding: '10px', borderRadius: '8px', backgroundColor: '#f1f3f5' }}>
    {/* <Pagination.Prev 
      onClick={() => handlePageChange(page - 1)} 
      disabled={page === 0} 
      style={{ padding: '5px 10px', fontSize: '1rem', color: '#007bff', cursor: 'pointer', borderRadius: '4px' }}
    /> */}
    
    {Array.from({ length: totalPages }, (_, idx) => (
      <Pagination.Item
        key={idx}
        active={idx === page}
        onClick={() => handlePageChange(idx)}
        style={{
          fontSize: '0.9rem',
          color: idx === page ? '#ffffff' : '#007bff',
          backgroundColor: idx === page ? '#007bff' : 'transparent',
          borderRadius: '4px',
          border: idx === page ? 'none' : '1px solid #007bff',
          cursor: 'pointer'
        }}
      >
        {idx + 1}
      </Pagination.Item>
    ))}
    
    {/* <Pagination.Next 
      onClick={() => handlePageChange(page + 1)} 
      disabled={page === totalPages - 1} 
      style={{ padding: '5px 10px', fontSize: '1rem', color: '#007bff', cursor: 'pointer', borderRadius: '4px' }}
    /> */}
  </Pagination>
</SoftBox>

          </Card>

        </SoftBox>
        {/* <Card>
          <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <SoftTypography variant="h6">Phys table</SoftTypography>
          </SoftBox>
          <SoftBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            <Table columns={prCols} rows={prRows} />
          </SoftBox>
        </Card> */}
      </SoftBox>
      <Footer />
    </DashboardLayout>
    <Modal
      show={modalOpen}
      onHide={handleCloseModal}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Physiotherapist Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
  <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
    {/* Each detail with its icon */}
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <InfoIcon sx={{ color: '#17c1e8' }} />
      <span style={{ fontSize: '14px' }}>Physiotherapist Name: {selectedClinic?.name || 'Not provided'}</span>
    </Box>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <EmailIcon sx={{ color: '#17c1e8' }} />
      <span style={{ fontSize: '14px' }}>Physiotherapist Email: {selectedClinic?.email || 'Not provided'}</span>
      {selectedClinic?.email && (
      <img src={VerifiedBadge} alt="Verified" style={{ marginLeft: '4px', width: '16px', height: '16px' }} />
      )}
    </Box>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <PhoneIcon sx={{ color: '#17c1e8' }} />
      <span style={{ fontSize: '14px' }}>Physiotherapist Phone: {selectedClinic?.mobile || 'Not provided'}</span>
      {selectedClinic?.mobile && (
      <img src={VerifiedBadge} alt="Verified" style={{ marginLeft: '4px', width: '16px', height: '16px' }} />
      )}
    </Box>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <InfoIcon sx={{ color: '#17c1e8' }} />
      <span style={{ fontSize: '14px' }}>Gender : {selectedClinic?.gender || 'Not provided'}</span>
    </Box>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <InfoIcon sx={{ color: '#17c1e8' }} />
      <span style={{ fontSize: '14px' }}>Physiotherapist Speciality : {selectedClinic?.speciality || 'Not provided'}</span>
    </Box>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <InfoIcon sx={{ color: '#17c1e8' }} />
      <span style={{ fontSize: '14px' }}>Physiotherapist Associated Entity : {selectedClinic?.associatedEntity ? selectedClinic?.associatedEntity === 'Individual' ? 'Individual' : selectedClinic?.associatedEntity?.name : 'Not provided'}</span>
    </Box>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <LocationOnIcon sx={{ color: '#17c1e8' }} />
      <span style={{ fontSize: '14px' }}>Physiotherapist Address: {`${selectedClinic?.address?.street || ''}, ${selectedClinic?.address?.city || ''}, ${selectedClinic?.address?.state || ''}, ${selectedClinic?.address?.country || ''}`.trim() || 'Not provided'}</span>
    </Box>
  </Box>
  <SoftTypography variant="h6">Credits by Month</SoftTypography>
    {selectedClinic?.creditsByMonth && selectedClinic.creditsByMonth.map((entry, index) => (
      <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <InfoIcon sx={{ color: '#17c1e8' }} />
        <span style={{ fontSize: '14px' }}>Month: {entry.month}</span>
        <span style={{ fontSize: '14px', marginLeft: '4px' }}>Credits: {entry.credits}</span>
      </Box>
    ))}
</Modal.Body>

      <Modal.Footer style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
        <Button className='m-2' variant="outlined" color="error" sx={{ color: '#ea0606' }} onClick={handleCloseModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal></>
  )
}

export default PaidPhysiotherapist
