// Soft UI Dashboard React base styles
import borders from 'assets/theme/base/borders'
import colors from 'assets/theme/base/colors'

// Soft UI Dashboard React helper functions
import pxToRem from 'assets/theme/functions/pxToRem'

const { borderWidth } = borders
const { light } = colors

const tableCell = {
  styleOverrides: {
    root: {
      padding: `${pxToRem(12)} ${pxToRem(16)}`,
      borderBottom: `${borderWidth[1]} solid ${light.main}`
    }
  }
}

export default tableCell
