import React, { useEffect, useState } from 'react'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import { get, getAuthConfig } from 'libs/http-hydrate'
import { Spinner, Alert, Container, Row, Col, Button } from 'react-bootstrap'
import Switch from '@mui/material/Switch'
import moment from 'moment'
import SoftTypography from 'components/SoftTypography'
import { useNavigate } from 'react-router-dom'

function TicketList() {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [filterStatus, setFilterStatus] = useState('open')
  const [ticketList, setTicketList] = useState({
    items: [],
    totalPages: 0,
    currentPage: 1
  })

  useEffect(() => {
    getTicketList()
  }, [filterStatus])

  async function getTicketList() {
    setIsLoading(true)
    setError(null)
    try {
        const data = await get(`/admin/tickets?status=${filterStatus}`, getAuthConfig())// Use filterStatus in the API call
        if (data?.status === 200) {
        setTicketList({
          items: data?.data?.tickets,
          totalPages: data?.data?.totalPages,
          currentPage: data?.data?.currentPage
        })
      } else {
        setError('Failed to fetch tickets. Please try again later.')
      }
    } catch (error) {
      setError('Error fetching tickets. Please check your network and try again.')
    } finally {
      setIsLoading(false)
    }
  }

  function generatePageNumbers() {
    const pageNumbers = []
    for (let i = 0; i < ticketList.totalPages; i++) {
      pageNumbers.push(i)
    }
    return pageNumbers
  }

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <Container className="mt-4">
        <div className="d-flex justify-content-between align-items-center mb-3">
            <h5>Ticket List</h5>
            <div className="d-flex align-items-center">
              <SoftTypography variant="button" fontWeight="medium" mr={1}>
                {filterStatus === 'open' ? 'Open' : 'Closed'}
              </SoftTypography>
              <Switch
                checked={filterStatus === 'open'}
                onChange={() => setFilterStatus(filterStatus === 'open' ? 'closed' : 'open')}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </div>
          </div>
          {isLoading && (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" />
            </div>
          )}
          {error && (
            <Alert variant="danger" className="mt-3">
              {error}
            </Alert>
          )}
          {!isLoading && !error && ticketList.items.length === 0 && (
            <div className="d-flex justify-content-center">
              <p>No tickets found.</p>
            </div>
          )}
          {!isLoading && !error && ticketList.items.length > 0 && (
            <table className="table table-hover">
              <thead>
                <tr style={{ fontSize: '0.85rem' }}>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Created By</th>
                  <th>Status</th>
                  <th>Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {ticketList.items.map((ticket, index) => (
                  <tr key={index} style={{ fontSize: '0.8rem' }} onClick={(e) => {
                    e.preventDefault()
                    navigate('/ticketReply', { state: { ticket: ticket } })
                  }}>
                    <td>{ticket.title}</td>
                    <td>{ticket.description}</td>
                    <td>{ticket.createdBy.name}</td>
                    <td>{ticket.status}</td>
                    <td>{moment(ticket.createdAt).format('DD/MM/YYYY')}</td>
                    <td>
                      <Button
                        variant="info"
                        onClick={() => console.log(`Edit ticket: ${ticket._id}`)}
                      >
                        Reply
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {/* Pagination */}
          <div className="mt-3" style={{ display: 'flex', justifyContent: 'center' }}>
            <ul className="pagination">
              {generatePageNumbers().map((pageNumber) => (
                <li
                  key={pageNumber}
                  className={`page-item ${ticketList.currentPage === pageNumber ? 'active' : ''}`}
                >
                  <a className="page-link" onClick={() => setTicketList((prev) => ({ ...prev, currentPage: pageNumber }))}>
                    {pageNumber + 1}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </Container>
      </DashboardLayout>
    </>
  )
}

export default TicketList
