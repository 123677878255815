// @mui material components
import Card from '@mui/material/Card'

// Soft UI Dashboard React components
import SoftBox from 'components/SoftBox'
import SoftTypography from 'components/SoftTypography'

// Soft UI Dashboard React examples
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import Footer from 'examples/Footer'
import Table from 'examples/Tables/Table'
import SoftInput from 'components/SoftInput'
// Data
import { useEffect, useState } from 'react'
import { getAuthConfig, get, deletes } from 'libs/http-hydrate'
import SoftAvatar from 'components/SoftAvatar'
import SoftBadge from 'components/SoftBadge'
import PropTypes from 'prop-types' // Import PropTypes
import moment from 'moment'
import { useLocation, useNavigate } from 'react-router-dom'
import VerifiedBadge from 'assets/images/quality.png'

function PatientPaymentHistory () {
  // const { columns: prCols, rows: prRows } = projectsTableData
  const navigate = useNavigate()
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(false)
  const [physiotherapistList, setPhysiotherapistList] = useState({
    items: [],
    has_more: false
  })
  const [page, setPage] = useState(0)
  const [searchQuery, setSearchQuery] = useState('') // State for search query
  useEffect(() => {
    getPhysiotherapistList()
  }, [page, searchQuery])

  async function getPhysiotherapistList () {
    try {
      setIsLoading(true)
      const data = await get(`/patientsPaidHisotry?patientId=${location?.state?.patient?._id}&pageNumber=${page}&searchQuery=${searchQuery}&paid=paid`, getAuthConfig())
      if (data?.status === 200) {
        // if (page === 0) {
        setPhysiotherapistList((p) => ({ ...p, items: data?.data?.patients }))
        // } else {
        //   setPhysiotherapistList((p) => ({ ...p, items: physiotherapistList?.items?.concat(data?.data?.msg) }))
        // }
        setPhysiotherapistList((p) => ({ ...p, has_more: data?.data?.has_more }))
      }
    } catch (error) {

    } finally {
      setIsLoading(false) // Set isLoading to false in the finally block
    }
  }

  function Author ({ image, name, email, onClick }) {
    return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5} onClick={onClick}>
      <SoftBox mr={2}>
        <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="button" fontWeight="medium">
          {name}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {email}

          <img src={VerifiedBadge} alt="Verified" style={{ marginLeft: '4px', width: '16px', height: '16px' }} />
        </SoftTypography>
      </SoftBox>
    </SoftBox>
    )
  }

  function Function ({ job, org }) {
    return (
    <SoftBox display="flex" flexDirection="column">
      <SoftTypography variant="caption" fontWeight="medium" color="text">
        {job}
      </SoftTypography>
      <SoftTypography variant="caption" color="secondary">
        {org}
      </SoftTypography>
    </SoftBox>
    )
  }
  // PropTypes definition for Author
  Author.propTypes = {
    image: PropTypes.string.isRequired, // Assuming 'image' is a string
    name: PropTypes.string.isRequired, // Assuming 'name' is a string
    email: PropTypes.string.isRequired // Assuming 'email' is a string
  }

  // PropTypes definition for Function
  Function.propTypes = {
    job: PropTypes.string.isRequired, // Assuming 'job' is a string
    org: PropTypes.string.isRequired // Assuming 'org' is a string
  }

  const authorsTableData = {
    columns: [
      { name: 'Plan', align: 'left' },
      { name: 'Currency', align: 'left' },
      { name: 'Platform', align: 'center' },
      { name: 'Purchase', align: 'center' },
      { name: 'Status', align: 'center' },
      { name: 'action', align: 'center' }
    ],

    rows: physiotherapistList.items && physiotherapistList.items.map((item) => ({
      Plan: (
      <Author image={item.profile_image ? item?.profile_image : ''} name={item.planType} email={item?.price}/>
      ),
      Currency: <Function job={item.currency} />,
      Platform: (
      <SoftBadge
        variant="gradient"
        badgeContent={ item?.device}
        color={item?.patientCount > 0 ? 'success' : 'secondary'}
        size="xs"
        container
      />
      ),
      Purchase: (
      <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {moment(item.purchaseDate).format('DD / MM / YYYY')}
      </SoftTypography>
      ),
      Status: (
        <SoftBadge
          variant="gradient"
          badgeContent={ item?.isActive ? 'Active' : 'InActive'}
          color={item?.isActive ? 'success' : 'secondary'}
          size="xs"
          container
        />
      ),
      action: (
      <>
      <SoftTypography
        component="a"
        variant="caption"
        color="secondary"
        fontWeight="medium"
        onClick={() => {
          navigate('/EditPhysiotherapist', { state: { physiotherapist: item } })
        }} >
        Edit
      </SoftTypography>  /  <SoftTypography
        component="a"
        href="#"
        variant="caption"
        color="secondary"
        fontWeight="medium"
        onClick={() => handleDeleteClick(item._id)} // Add onClick handler here
      >
        Delete
      </SoftTypography>
      </>
      )
    }))
  }
  async function handleDeleteClick (patientId) {
    const url = `/DeletePhysiotherapist?id=${patientId}`
    try {
      const data = await deletes(url)
      if (data?.status === 200) {
        // Create a function to remove an item with a specific _id
        function removeItemWithId (array, patientId) {
          return array.filter((item) => item._id !== patientId)
        }
        // Update the state to remove the item
        setPhysiotherapistList((prevState) => ({
          ...prevState,
          items: removeItemWithId(prevState.items, patientId)
        }))
      }
    } catch (error) {
    }
  }

  return (
    <>
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">{location?.state?.patient?.name}'s Payment History Table</SoftTypography>
              <div style={{ padding: '1rem' }}>
              <SoftBox pr={1}>
              <SoftInput
                type="text"
                placeholder="Search Physiotherapist"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value)
                  setPage(0)
                }}
              />
              </SoftBox>
              </div>
            </SoftBox>
            {isLoading
              ? (
              <>
                <SoftBox display="flex" justifyContent="center" alignItems="center" p={3}>
                  <SoftTypography variant="h6">Loading ... </SoftTypography>
                </SoftBox></>
                )
              : (
              <SoftBox
                sx={{
                  '& .MuiTableRow-root:not(:last-child)': {
                    '& td': {
                      borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`
                    }
                  }
                }}
              >
              {physiotherapistList?.items?.length === 0 ? (<div style={{ textAlign: 'center' }}><SoftTypography variant='h6' color='textSecondary'> No Physiotherapist's Payment History found </SoftTypography></div>) : <Table columns={authorsTableData?.columns} rows={authorsTableData?.rows}/>}
              </SoftBox>
                )}

            <SoftBox display="flex" justifyContent="center" p={2}>
  <button
    onClick={() => setPage(current => current - 1)}
    disabled={page === 0}
    className='btn btn-primary m-2'
  >
    Previous
  </button>
  <button
    onClick={() => setPage(current => current + 1)}
    disabled={!physiotherapistList?.has_more}
    className='btn btn-primary m-2'
  >
    Next
  </button>
</SoftBox>

          </Card>

        </SoftBox>
        {/* <Card>
          <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <SoftTypography variant="h6">Phys table</SoftTypography>
          </SoftBox>
          <SoftBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            <Table columns={prCols} rows={prRows} />
          </SoftBox>
        </Card> */}
      </SoftBox>
      <Footer />
    </DashboardLayout></>
  )
}

export default PatientPaymentHistory
