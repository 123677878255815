// @mui material components
import Grid from '@mui/material/Grid'

// Soft UI Dashboard React components
import SoftBox from 'components/SoftBox'

// Soft UI Dashboard React examples
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'

// Data
import { useEffect, useState } from 'react'
import { get, getAuthConfig } from 'libs/http-hydrate'
import MiniStatisticsCard from 'examples/Cards/StatisticsCards/MiniStatisticsCard'
import { Link } from 'react-router-dom'

function Dashboard () {
  useEffect(() => {
    getHomeData()
  }, [])
  const [homeData, setHomeData] = useState({})

  async function getHomeData () {
    try {
      const data = await get('/homeAdmin', getAuthConfig())
      if (data?.status === 200) {
        setHomeData(data?.data?.msg)
      }
    } catch (error) {

    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
          <Grid item xs={12} sm={6} xl={3}>
          <Link to="/clinic" style={{ textDecoration: 'none' }}>

              <MiniStatisticsCard
                title={{ text: 'Total Clinic' }}
                count={homeData?.ClinicCount}
                // percentage={{ color: "success", text: "+55%" }}
                icon={{ color: 'info', component: 'paid' }}
              />
                            </Link>

            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
            <Link to="/physiothepist" style={{ textDecoration: 'none' }}>

              <MiniStatisticsCard
                title={{ text: 'Total Physiotherapist' }}
                count={homeData?.Physiotherapistcount}
                // percentage={{ color: "success", text: "+55%" }}
                icon={{ color: 'info', component: 'paid' }}
              />
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
            <Link to="/patient" style={{ textDecoration: 'none' }}>

              <MiniStatisticsCard
                title={{ text: 'Total Patient' }}
                count={homeData?.Patientcount}
                // percentage={{ color: "success", text: "+3%" }}
                icon={{ color: 'info', component: 'public' }}
              />
              </Link>
            </Grid>
            {/* <Grid item xs={12} sm={6} xl={3}>
            <Link to="/patient" style={{ textDecoration: 'none' }}>
              <MiniStatisticsCard
                title={{ text: 'Total Doctor' }}
                count={homeData?.Doctorcount}
                // percentage={{ color: "error", text: "-2%" }}
                icon={{ color: 'info', component: 'emoji_events' }}
              />
              </Link>
            </Grid> */}
            {/* <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "sales" }}
                count="$103,430"
                percentage={{ color: "success", text: "+5%" }}
                icon={{
                  color: "info",
                  component: "shopping_cart",
                }}
              />
            </Grid> */}
          </Grid>
        </SoftBox>
        {/* <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={7}>
              <BuildByDevelopers />
            </Grid>
            <Grid item xs={12} lg={5}>
              <WorkWithTheRockets />
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={5}>
              <ReportsBarChart
                title="active users"
                description={
                  <>
                    (<strong>+23%</strong>) than last week
                  </>
                }
                chart={chart}
                items={items}
              />
            </Grid>
            <Grid item xs={12} lg={7}>
              <GradientLineChart
                title="Sales Overview"
                description={
                  <SoftBox display="flex" alignItems="center">
                    <SoftBox fontSize={size.lg} color="success" mb={0.3} mr={0.5} lineHeight={0}>
                      <Icon className="font-bold">arrow_upward</Icon>
                    </SoftBox>
                    <SoftTypography variant="button" color="text" fontWeight="medium">
                      4% more{" "}
                      <SoftTypography variant="button" color="text" fontWeight="regular">
                        in 2021
                      </SoftTypography>
                    </SoftTypography>
                  </SoftBox>
                }
                height="20.25rem"
                chart={gradientLineChartData}
              />
            </Grid>
          </Grid>
        </SoftBox>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8}>
            <Projects />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OrderOverview />
          </Grid>
        </Grid> */}
      </SoftBox>
      {/* <Footer /> */}
    </DashboardLayout>
  )
}

export default Dashboard
