import { useCallback, useEffect, useRef, useState } from 'react'
import DashboardLayout from './LayoutContainers/DashboardLayout'
import DashboardNavbar from './Navbars/DashboardNavbar'
import { get, getAuthConfig, post } from 'libs/http-hydrate'
import { useLocation, useNavigate } from 'react-router-dom'
import checkIcon from '../assets/images/check (1).png'
import FilterIcon from '../assets/images/filter.svg'
import SearchIcon from '../assets/images/search.svg'
import Modal from 'react-bootstrap/Modal'
import { Button, Nav } from 'react-bootstrap'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Auth } from 'libs/auth'
import Multiselect from 'multiselect-react-dropdown'
const AddSurgeryTemplate = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [valuesBodyPart, setValuesBodyPart] = useState({
    body_part_id: [],
    bodyParterror: false
  })
  const [bodyPartData, setBodyPart] = useState(
    {
      has_more: false,
      items: []
    })
  const [ExerciseList, setExerciseList] = useState({
    items: [],
    has_more: false
  })
  const [filter, setFilter] = useState({
    body_part_id: '',
    title: '',
    searchTerm: '',
    patient_id: ''
  })
  const [showSidebar, setShowSidebar] = useState(false)
  const handleCloseSidebar = () => setShowSidebar(false)
  const handleShowSidebar = () => setShowSidebar(true)
  const [cart, setCart] = useState([])

  const [showFilter, setShowFilter] = useState(false)
  const handleCloseFilter = () => setShowFilter(false)
  const handleShowFilter = () => setShowFilter(true)
  const [page, setPage] = useState(0)
  const [showNote, setShowNote] = useState(false)
  const handleCloseNote = () => setShowNote(false)
  const handleShowNote = () => setShowNote(true)
  useEffect(() => {
    getExerciseList()
  }, [page])
  useEffect(() => {
    setPage(0, () => {
      getExerciseList()
    })
    if (page === 0) {
      getExerciseList()
    }
  }, [filter])
  useEffect(() => {
    getExerciseList()
  }, [])
  useEffect(() => {
    if (location?.state?.template && location.state.template.exerciseId) {
      setCart([...cart, ...location?.state?.template?.exerciseId])
      const bodyPartId = []
      for (const key in location?.state?.template?.body_part_id) {
        bodyPartId.push({
          key: location?.state?.template?.body_part_id[key].title,
          value: location?.state?.template?.body_part_id[key]._id
        })
      }
      setValuesBodyPart((p) => ({ ...p, body_part_id: bodyPartId }))
    }
  }, [location?.state?.template])
  async function getBodyPartData () {
    try {
      const data = await get('/bodyPartListAdmin?pageNumber=0', getAuthConfig())
      if (data?.status === 200) {
        setBodyPart((p) => ({ ...p, items: data?.data?.data }))
        setBodyPart((p) => ({ ...p, has_more: data?.data?.has_more }))
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate('/login')
      }
    }
  }
  async function getExerciseList () {
    try {
      const bodyPartId = filter.body_part_id !== '' ? filter.body_part_id : (location?.state?.bodypartId || '')
      const searchTerm = filter?.searchTerm || ''
      let queryParams = `pageNumber=${page}`
      if (searchTerm) {
        queryParams += `&searchTerm=${searchTerm}`
      } else {
        queryParams += `&body_part_id=${bodyPartId}`
      }
      const data = await get(`/exerciseListAdmin?${queryParams}`, getAuthConfig())
      if (data?.status === 200) {
        if (page > 0) {
          setExerciseList((p) => ({ ...p, items: ExerciseList?.items?.concat(data?.data?.data) }))
        } else {
          setExerciseList((p) => ({ ...p, items: data?.data?.data }))
        }
        setExerciseList((p) => ({ ...p, has_more: data?.data?.has_more }))
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        console.log(error)
      }
    }
  }
  const addToCart = (item) => {
    // Check if the item is already in the cart based on its _id
    const isItemInCart = cart.some((cartItem) => cartItem._id === item._id)

    // If the item is not already in the cart, add it
    if (!isItemInCart) {
      setCart([...cart, item])
    }
  }
  const observer = useRef()
  const lastBookElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && ExerciseList?.has_more) {
          setPage((prevPageNumber) => prevPageNumber + 1)
        }
      })
      if (node) observer.current.observe(node)
    },
    [ExerciseList?.has_more]
  )
  const removeFromCart = (itemToRemove) => {
    // Use filter to create a new cart array without the item to remove
    const updatedCart = cart.filter((item) => item._id !== itemToRemove._id)
    setCart(updatedCart)
  }

  console.log(location?.state?.template, 'template')
  console.log(valuesBodyPart?.body_part_id, 'bpdydslodsds')

  return (
        <DashboardLayout>
          <DashboardNavbar />
          <div className='content-data my-5 '>
                                    <div className='serchPart'>
                                        <div className='searchInput'>
                                            <div className='searchIcon'>
                                                <img src={SearchIcon} alt='searchIcon' />
                                            </div>
                                            <input type='text' placeholder='Search exercises' value={filter?.searchTerm} onChange={(e) => {
                                              e.preventDefault()

                                              if (e.target.value === '') {
                                                setPage(0)
                                              }
                                              setFilter((p) => ({ ...p, searchTerm: e.target.value }))
                                            }} />
                                            <div className='filterIcon' onClick={(e) => {
                                              getBodyPartData()
                                              handleShowFilter()
                                            }}>
                                                <img src={FilterIcon} alt='filterIcon' />
                                            </div>
                                        </div>
                                    </div>

                                </div>

            <div className='exercise-content row  p-1' style={{ height: '65vh', overflowY: 'auto' }}>
                                                  {ExerciseList?.items && ExerciseList?.items?.length <= 0 && <div className='text-center' style={{ fontSize: '14px' }}>No Exercise Found  </div>}

                                                  {ExerciseList?.items && ExerciseList?.items?.length > 0 &&
                                                      ExerciseList?.items?.map((item, index) => {
                                                        const result = (Number(item?.set) * Number(item?.count))

                                                        return (
                                                              <>
                                                                  <div className='exercise-card col-lg-3 mx-4  mb-3' ref={lastBookElementRef}>
                                                                      <div className='img-text-part'>
                                                                          <div className='img-block'>
                                                                              <img src={item?.exerciseImage} className='rounded' height={70} width={70} alt='ex-1' />
                                                                          </div>
                                                                          <div className='text-part p-3'>
                                                                              <h6>{item?.title}</h6>
                                                                              <p style={{ cursor: 'pointer' }} onClick={() => {
                                                                                // if (selectedExercises.some((exercise) => exercise._id === item._id)) {
                                                                                //   if (item?.set) {
                                                                                //     setSelected((p) => ({ ...p, set: item?.set }))
                                                                                //   }
                                                                                //   if (item?._id) {
                                                                                //     setSelected((p) => ({ ...p, _id: item?._id }))
                                                                                //   }
                                                                                //   if (item?.count) {
                                                                                //     setSelected((p) => ({ ...p, counter: item?.count }))
                                                                                //   }
                                                                                //   if (item?.restTime) {
                                                                                //     setSelected((p) => ({ ...p, restTime: item?.restTime }))
                                                                                //   }
                                                                                //   handleShow()
                                                                                // }
                                                                              }
                                                                              }>{result} Reps</p>
                                                                          </div>
                                                                      </div>
                                                                      <div className="checkbox me-2">
                                                                          <input
                                                                              type="checkbox"
                                                                              id={`checkbox_${item._id}`}
                                                                              checked={cart.some((cartItem) => cartItem._id === item._id)}
                                                                              onChange={(e) => {
                                                                                if (cart.some((cartItem) => cartItem._id === item._id)) {
                                                                                  // Item is in cart, so remove it
                                                                                  removeFromCart(item)
                                                                                } else {
                                                                                  // Item is not in cart, so add it
                                                                                  addToCart(item)
                                                                                }
                                                                              }}
                                                                          />                                                    <label for={`checkbox_${item._id}`}></label>
                                                                      </div>
                                                                  </div>
                                                              </>
                                                        )
                                                      })
                                                  }
                                              </div>

                                              <div className="center-div p-2 text-center" style={{ cursor: 'pointer' }} onClick={(e) => {
                                                e.preventDefault()
                                                handleShowSidebar()
                                              }}>

                <Nav variant="pills" className="flex-column">
                    <Nav.Item className='d-flex justify-content-start align-items-center'>

                        <div className="checkbox me-2">
                            <input type="checkbox" id="checkbox_6" checked />
                            <label for="checkbox_6"></label>
                        </div>
                        <Nav.Link eventKey="first" style={{ fontWeight: '500' }}>
                            Excersices</Nav.Link>

                    </Nav.Item>

                </Nav>

                <h1 style={{ color: '#FF6036' }}>

                    {cart.length < 10 ? `0${cart.length}` : cart.length}

                </h1>

            </div>
                                            <Modal show={showFilter} onHide={handleCloseFilter} centered size="md">
                    <Modal.Header closeButton>
                        <Modal.Title className='text-center'>Filter</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='pb-0'>
                        <div className='body-content'>
                            <ul className='filterList'>
                                {bodyPartData?.items && bodyPartData?.items.length > 0 && (
                                  bodyPartData.items.map((item, index) => (
                                        <li
                                            key={index}
                                            style={{ cursor: 'pointer' }}
                                            onClick={(e) => {
                                              setFilter((prevFilter) => ({
                                                ...prevFilter,
                                                body_part_id: item?._id
                                              }))
                                            }}
                                        >
                                            <a >{item?.title}
                                                {filter?.body_part_id === item?._id && (
                                                    <div className="select-img">
                                                        <img src={checkIcon} alt="checkIcon" style={{ display: 'block' }} />
                                                    </div>
                                                )}
                                            </a>
                                        </li>
                                  ))
                                )}

                            </ul>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className='theme-button' onClick={(e) => {
                          e.preventDefault()
                          handleCloseFilter()
                        }}>
                            Done
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showSidebar} onHide={handleCloseSidebar} className='modal-sidebar' size="md">
                <Modal.Header closeButton>

                    <Modal.Title className='text-start'>
                        <div>
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item className='d-flex justify-content-start align-items-center'>
                                    <div className="checkbox me-2 sidebarCheckbox">
                                        <input
                                            type="checkbox"
                                            id={`checkbox_${'morning'}`}
                                            checked={true}
                                        />
                                        <label for={`checkbox_${'morning'}`}></label>
                                    </div>
                                    <Nav.Link eventKey="first">

                                        Exercises ({cart.length < 10 ? `0${cart.length}` : cart.length}
                                        )</Nav.Link>

                                </Nav.Item>

                            </Nav>
                        </div>

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='body-content'>

                        {cart && cart.length > 0 && cart.map((item, index) => {
                          return (
                                <>
                                    <div className='exercise-card mb-3' style={{ boxShadow: 'none' }}>
                                        <div className='img-text-part'>
                                            <div className='img-block'>
                                                <img src={item?.exerciseImage} height={130} width={180} alt='ex-1' />
                                            </div>
                                            <div className='text-part ps-3'>

                                                <h6>{item?.title}</h6>
                                                <p style={{ cursor: 'pointer' }} >LTR - WIG - WAGS - KNEE ROCKS </p>

                                            </div>
                                        </div>
                                        <div className="checkbox me-2" style={{ cursor: 'pointer' }} onClick={() => removeFromCart(item)}
                                        >
                                            <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg">
                                                <line x1="5" y1="5" x2="25" y2="25" stroke="#ADADAD" stroke-width="2" />
                                                <line x1="25" y1="5" x2="5" y2="25" stroke="#ADADAD" stroke-width="2" />
                                            </svg>

                                        </div>
                                    </div>

                                    <hr />
                                </>
                          )
                        })}

                        {/* <div className='exercise-card mb-3' style={{ boxShadow: "none" }}>
                            <div className='img-text-part'>
                                <div className='img-block'>
                                    <img src={Ex1} height={130} width={180} alt='ex-1' />
                                </div>
                                <div className='text-part ps-3'>

                                    <h6>LOWER TRUNK ROTATION</h6>
                                    <p style={{ cursor: "pointer" }} >LTR - WIG - WAGS - KNEE ROCKS </p>

                                </div>
                            </div>
                            <div className="checkbox me-2" style={{ cursor: "pointer" }} >
                                <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg">
                                    <line x1="5" y1="5" x2="25" y2="25" stroke="#ADADAD" stroke-width="2" />
                                    <line x1="25" y1="5" x2="5" y2="25" stroke="#ADADAD" stroke-width="2" />
                                </svg>

                            </div>
                        </div> */}

                    </div>

                </Modal.Body>
                <Modal.Footer>
                    {cart.length > 0 &&
                        <Button className='theme-button' onClick={(e) => {
                          e.preventDefault()
                          handleCloseSidebar()
                          handleShowNote()
                          if (bodyPartData?.items <= 0) {
                            getBodyPartData()
                          }
                        }}>
                            Done
                        </Button>}
                </Modal.Footer>
            </Modal>
            <Modal show={showNote} onHide={handleCloseNote} centered size="lg">
  <Modal.Header closeButton>
    <Modal.Title className='text-center'>Add Surgery Template</Modal.Title>
  </Modal.Header>
  <Formik
    initialValues={{
      templateName: location?.state?.template?.name || '' // Set initial value from location state
    }}
    validationSchema={Yup.object({
      templateName: Yup.string().required('Template Name is required')
    })}
    onSubmit={async (values) => {
      console.log(valuesBodyPart, 'Fieldvalues')
      const authFunctions = Auth()

      const formData = new URLSearchParams()
      formData.append('name', values?.templateName)
      if (location?.state?.template) {
        formData.append('_id', location?.state?.template?._id)
      }

      // Appending the data to the URLSearchParams object
      for (const key in cart) {
        formData.append('exerciseId', cart[key]._id)
      }

      if (valuesBodyPart?.body_part_id?.length <= 0) {
        setValuesBodyPart((p) => ({ ...p, bodyParterror: true }))
        return false
      }

      // Iterate over the body_part_id array and append each value to formData
      valuesBodyPart?.body_part_id.forEach((id) => {
        formData.append('body_part_id', id?.value)
      })
      // return false
      try {
        await post('/addTemplates', formData.toString(), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: 'Web ' + 'Admin ' + authFunctions.getAuthToken()
          }
          // Add any other necessary configurations here
        }).then((data) => {
          if (data?.status === 200) {
            navigate('/dashboard')
          }
        }).catch((err) => {
          console.log(err)
        })
      } catch (error) {
        // Handle errors here
      }
    }}

  >
    <Form>
      <Modal.Body>
        <div className='body-content'>
          <p className='body-title'>Template Name</p>
          <div className="mb-3">
            <Field type='text' name="templateName" className="form-control" id="exampleFormControlTextarea1" rows="5" placeholder='Ex. Workout Name' />
            <ErrorMessage name="templateName" component="div" className="text-danger" />
          </div>

          <div className='mb-3'>
          <Multiselect
            options={bodyPartData?.items?.map((bodyPart) => ({
              key: bodyPart.title,
              value: bodyPart._id
            }))}
            selectedValues={valuesBodyPart?.body_part_id}
            onSelect={(selectedList) => setValuesBodyPart({ ...valuesBodyPart, body_part_id: selectedList })}
            onRemove={(selectedList) => setValuesBodyPart({ ...valuesBodyPart, body_part_id: selectedList })}
            customArrow={<img src="data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e" />}
            displayValue="key"
            placeholder="Select BodyPart"
            showArrow={true}
            keepSearchTerm={true}
            id={'body_part_id'}
          />
          {valuesBodyPart?.bodyParterror && <div className='text-danger'> Atleast one body part is required</div>}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>

          <Button className='theme-button' type="submit">
            Next
          </Button>
      </Modal.Footer>
    </Form>
  </Formik>
</Modal>
          </DashboardLayout>
  )
}
export default AddSurgeryTemplate
