import { Button, Col, Form, Row } from 'react-bootstrap'
import profileUpload from '../assets/images/upload.svg'
import { useEffect, useRef, useState } from 'react'
import { get, getAuthConfig, post } from 'libs/http-hydrate'
import { useLocation, useNavigate } from 'react-router-dom'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import axios from 'axios'
import FieldValidationError from 'common/FieldValidation'
import Select from 'react-select'
import DashboardLayout from './LayoutContainers/DashboardLayout'
import DashboardNavbar from './Navbars/DashboardNavbar'

function EditPhysiotherapist () {
  const location = useLocation()
  const navigate = useNavigate()
  const [data, setData] = useState({
  })
  const [loading, setLoading] = useState(false)
  const [selected, setSelected] = useState(null)
  const [profileImagePreview, setProfileImagePreview] = useState('')
  const clinicInputRef = useRef(null)
  const profileInputRef = useRef(null)
  const nameInputRef = useRef(null)
  const mobileInputRef = useRef(null)
  const emailInputRef = useRef(null)
  const passwordInputRef = useRef(null)
  const associatedInputRef = useRef(null)
  const yearsOfExperienceInputRef = useRef(null)
  const specialityInputRef = useRef(null)
  const availabilityInputRef = useRef(null)
  const genderInputRef = useRef(null)
  const briefDetailInputRef = useRef(null)
  const feesInputRef = useRef(null)
  const hospitalNameInputRef = useRef(null)
  const hospitalclinicPhoneRef = useRef(null)
  const hostitalEmailRef = useRef(null)
  //   const hospitalFeesInputRef = useRef(null)
  //   const hospitalAddressInputRef = useRef(null)
  const streetInputRef = useRef(null)
  const cityInputRef = useRef(null)
  const stateInputRef = useRef(null)
  const countryInputRef = useRef(null)
  const zipInputRef = useRef(null)
  const [country, setCountry] = useState('')
  const [state, setstate] = useState('')
  //   const [city, setCity] = useState('')
  const [countryLoading, setCountryLoading] = useState(false)
  const [stateLoading, setStateLoading] = useState(false)
  const [cityLoading, setCityLoading] = useState(false)
  const [countriesFetched, setCountriesFetched] = useState(false)
  const [countries, setCountries] = useState([])
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const [selectedClinic, setSelectedClinic] = useState({}) // Add selectedClinic state
  const [clinicError, setClinicError] = useState('')
  const [inputErrors, setInputErrors] = useState({
    name: false,
    mobile: false,
    email: false,
    password: false,
    confirmPassword: false,
    years_of_experience: false,
    speciality: false,
    availability: false,
    gender: false,
    brief_detail: false,
    fees: false,
    hospital_name: false,
    hospital_fees: false,
    hospital_address: false,
    street: false,
    country: false,
    state: false,
    city: false,
    pincode: false
  })
  const [clinics, setClinics] = useState({
    items: [],
    has_more: false,
    searchTerm: ''
  })
  const [page, setPage] = useState(1)
  const [DuplicatePhysio, setDuplicatePhysio] = useState('')
  async function getCountrie () {
    if (countriesFetched) {
      return // Countries have already been fetched
    }
    try {
      setCountryLoading(true)
      const data = await get('/country', getAuthConfig())
      setCountries(data?.data?.data)
      setCountriesFetched(true) // Update the state to indicate countries have been fetched
    } catch (error) {
      console.error(error)
    } finally {
      setCountryLoading(false)
    }
  }

  async function handleCountryChange (event) {
    const countryCode = event.target.value
    setCountry(countryCode)

    if (countryCode) {
      try {
        setStateLoading(true)
        const response = await get(`/state?countryCode=${countryCode}`, getAuthConfig())
        setStates(response?.data?.data)
        setstate('')
        setData((p) => ({ ...p, state: '' }))
      } catch (error) {
        console.error(error)
      } finally {
        setStateLoading(false)
      }
    } else {
      setStates([])
      setstate('')
    }
  }

  async function handleStateChange (event) {
    const stateCode = event.target.value
    setstate(stateCode)

    if (country && stateCode) {
      try {
        setCityLoading(true)
        const response = await get(`/city?countryCode=${country}&stateCode=${stateCode}`, getAuthConfig())
        setCities(response?.data?.data)
        setData((p) => ({ ...p, city: '' }))
      } catch (error) {
        console.error(error)
      } finally {
        setCityLoading(false)
      }
    } else {
      setCities([])
    }
  }
  const handleProfileImgChange = (e) => {
    const file = e?.target?.files[0]
    setData((prevData) => ({
      ...prevData,
      profile: file
    }))

    // Create a URL for the selected image to be used for preview
    const reader = new FileReader()
    reader.onloadend = () => {
      setProfileImagePreview(reader.result)
    }
    if (file) {
      reader.readAsDataURL(file)
    } else {
      setProfileImagePreview('')
    }
  }

  const handleChange = (e) => {
    const { name, value } = e?.target
    setData((prevData) => ({
      ...prevData,
      [name]: value
    }))

    // Update the specific field's error state to false when data is entered
    setInputErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false
    }))
    setDuplicatePhysio('')
  }
  const handleFieldError = (fieldRef) => {
    const inputField = fieldRef?.current?.querySelector('input')
    if (inputField) {
      inputField.focus() // Focus on the input field
      fieldRef.current.scrollIntoView({ behavior: 'smooth' })
    } else {
      const selectField = fieldRef?.current?.querySelector('select')
      if (selectField) {
        selectField.classList.add('field-error') // Add the error class to the select field
        fieldRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }

  async function createClinic () {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    if (!data?.name) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        name: true
      }))

      handleFieldError(nameInputRef)
      return
    }

    // if (!data?.mobile) {
    //   setInputErrors((prevErrors) => ({
    //     ...prevErrors,
    //     mobile: true
    //   }));
    //   handleFieldError(mobileInputRef)
    //   return;
    // }

    // if (!data?.email) {
    //   setInputErrors((prevErrors) => ({
    //     ...prevErrors,
    //     email: true
    //   }));
    //   handleFieldError(emailInputRef)
    //   return;
    // }

    if (!data?.years_of_experience || isNaN(data?.years_of_experience)) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        years_of_experience: true
      }))
      handleFieldError(yearsOfExperienceInputRef)
      return
    }

    if (!data?.speciality) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        speciality: true
      }))
      handleFieldError(specialityInputRef)
      return
    }

    if (data?.availability === '' || data?.availability === undefined) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        availability: true
      }))
      handleFieldError(availabilityInputRef)
      return
    }

    if (!data?.gender) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        gender: true
      }))
      handleFieldError(genderInputRef)
      return
    }
    if (!data?.brief_detail) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        brief_detail: true
      }))
      handleFieldError(briefDetailInputRef)

      return
    }

    if (!data?.type) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        associated: true
      }))
      handleFieldError(associatedInputRef)
      return
    }

    if (data?.type === 'clinic' && !data?.clinicName) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        clinicName: true
      }))
      handleFieldError(clinicInputRef)
      return
    }
    if (data?.type === 'Individual' && (!data?.fees || isNaN(data?.fees))) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        fees: true
      }))
      handleFieldError(feesInputRef)
      return
    }

    if (data?.clinicName === 'other' && !data?.hospital_name) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        hospital_name: true
      }))
      handleFieldError(hospitalNameInputRef)
      return
    }

    if (data?.clinicName === 'other' && !data?.clinicPhone) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        clinicPhone: true
      }))
      handleFieldError(hospitalclinicPhoneRef)
      return
    }
    if (data?.clinicName === 'other' && !emailRegex.test(data?.hospital_email)) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        hospital_email: true
      }))
      handleFieldError(hostitalEmailRef)
      return
    }

    if ((data?.clinicName === 'other' || data?.type === 'Individual') && !data?.street) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        street: true
      }))
      handleFieldError(streetInputRef)
      return
    }
    if ((data?.clinicName === 'other' || data?.type === 'Individual') && !data?.country) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        country: true
      }))
      handleFieldError(countryInputRef)

      return
    }
    if ((data?.clinicName === 'other' || data?.type === 'Individual') && !data?.state) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        state: true
      }))
      handleFieldError(stateInputRef)

      return
    }

    if ((data?.clinicName === 'other' || data?.type === 'Individual') && !data?.city) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        city: true
      }))
      handleFieldError(cityInputRef)
      return
    }

    if ((data?.clinicName === 'other' || data?.type === 'Individual') && !data?.pincode) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        pincode: true
      }))
      handleFieldError(zipInputRef)
      return
    }

    if (data?.email) {
      // Simple email validation using regular expression

      if (!emailRegex.test(data?.email)) {
        // toast.error("Email is not valid")
        setInputErrors((prevErrors) => ({
          ...prevErrors,
          email: true
        }))

        return
      }

      // if(!location?.state?.formProfile ){
      if (!data?.isPasswordAdded) {
        // Check if password and confirm password fields are filled and match
        if (!data?.password?.trim() || !data?.confirmPassword?.trim()) {
          setInputErrors((prevErrors) => ({
            ...prevErrors,
            confirmPassword: true
          }))

          handleFieldError(passwordInputRef)
          return
        }
        if (data?.password !== data?.confirmPassword) {
          setInputErrors((prevErrors) => ({
            ...prevErrors,
            password: true
          }))
          handleFieldError(passwordInputRef)
          return
        }

        // Check if password meets the minimum length requirement
        if (data?.password.length < 8) {
          setInputErrors((prevErrors) => ({
            ...prevErrors,
            password: true
          }))
          handleFieldError(passwordInputRef)

          return
        }
      }
    // }
    }
    const formdata = new FormData()

    formdata.append('name', data?.hospital_name)
    formdata.append('phone', '+' + data?.clinicPhone)
    formdata.append('email', data?.hospital_email)
    formdata.append('country', data?.country)
    formdata.append('state', data?.state)
    formdata.append('city', data?.city)
    formdata.append('street', data?.street)
    formdata.append('pincode', data?.pincode)
    try {
      await post('/createClinic', formdata, getAuthConfig()).then((data) => {
        if (data?.status === 200) {
          saveProfileDetail(data?.data?.result?._id)
        }
      }).catch((err) => {
        setClinicError(err?.response?.data?.err)
      })
    } catch (error) {
    }
  }

  async function saveProfileDetail (createdClinic) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    if (!data?.name) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        name: true
      }))

      handleFieldError(nameInputRef)
      return
    }

    // if (!data?.mobile) {
    //   setInputErrors((prevErrors) => ({
    //     ...prevErrors,
    //     mobile: true
    //   }));
    //   handleFieldError(mobileInputRef)
    //   return;
    // }

    // if (!data?.email) {
    //   setInputErrors((prevErrors) => ({
    //     ...prevErrors,
    //     email: true
    //   }));
    //   handleFieldError(emailInputRef)
    //   return;
    // }

    if (!data?.years_of_experience || isNaN(data?.years_of_experience)) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        years_of_experience: true
      }))
      handleFieldError(yearsOfExperienceInputRef)
      return
    }

    if (!data?.speciality) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        speciality: true
      }))
      handleFieldError(specialityInputRef)
      return
    }

    if (data?.availability === '' || data?.availability === undefined) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        availability: true
      }))
      handleFieldError(availabilityInputRef)
      return
    }

    if (!data?.gender) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        gender: true
      }))
      handleFieldError(genderInputRef)
      return
    }
    if (!data?.brief_detail) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        brief_detail: true
      }))
      handleFieldError(briefDetailInputRef)

      return
    }

    if (!data?.type) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        associated: true
      }))
      handleFieldError(associatedInputRef)
      return
    }

    if (data?.type === 'clinic' && !data?.clinicName) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        clinicName: true
      }))
      handleFieldError(clinicInputRef)
      return
    }
    if (data?.type === 'Individual' && (!data?.fees || isNaN(data?.fees))) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        fees: true
      }))
      handleFieldError(feesInputRef)
      return
    }

    if (data?.clinicName === 'other' && !data?.hospital_name) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        hospital_name: true
      }))
      handleFieldError(hospitalNameInputRef)
      return
    }

    if (data?.clinicName === 'other' && !data?.clinicPhone) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        clinicPhone: true
      }))
      handleFieldError(hospitalclinicPhoneRef)
      return
    }
    if (data?.clinicName === 'other' && !emailRegex.test(data?.hospital_email)) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        hospital_email: true
      }))
      handleFieldError(hostitalEmailRef)
      return
    }

    if ((data?.clinicName === 'other' || data?.type === 'Individual') && !data?.street) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        street: true
      }))
      handleFieldError(streetInputRef)
      return
    }
    if ((data?.clinicName === 'other' || data?.type === 'Individual') && !data?.country) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        country: true
      }))
      handleFieldError(countryInputRef)

      return
    }
    if ((data?.clinicName === 'other' || data?.type === 'Individual') && !data?.state) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        state: true
      }))
      handleFieldError(stateInputRef)

      return
    }

    if ((data?.clinicName === 'other' || data?.type === 'Individual') && !data?.city) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        city: true
      }))
      handleFieldError(cityInputRef)
      return
    }

    if ((data?.clinicName === 'other' || data?.type === 'Individual') && !data?.pincode) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        pincode: true
      }))
      handleFieldError(zipInputRef)
      return
    }

    if (data?.email) {
      // Simple email validation using regular expression

      if (!emailRegex.test(data?.email)) {
        // toast.error("Email is not valid")
        setInputErrors((prevErrors) => ({
          ...prevErrors,
          email: true
        }))

        return
      }

      // if(!location?.state?.formProfile ){
      if (!data?.isPasswordAdded) {
        // Check if password and confirm password fields are filled and match
        if (!data?.password?.trim() || !data?.confirmPassword?.trim()) {
          setInputErrors((prevErrors) => ({
            ...prevErrors,
            confirmPassword: true
          }))

          handleFieldError(passwordInputRef)
          return
        }
        if (data?.password !== data?.confirmPassword) {
          setInputErrors((prevErrors) => ({
            ...prevErrors,
            password: true
          }))
          handleFieldError(passwordInputRef)
          return
        }

        // Check if password meets the minimum length requirement
        if (data?.password.length < 8) {
          setInputErrors((prevErrors) => ({
            ...prevErrors,
            password: true
          }))
          handleFieldError(passwordInputRef)

          return
        }
      }
    // }
    }

    // If all validations pass, proceed to create the formdata
    const formdata = new FormData()

    // Append data to formdata
    formdata?.append('name', data?.name)
    if (data?.profile) {
      formdata?.append('profile_image', data?.profile)
    }
    if (data?.mobile) {
      if (!data?.mobile?.startsWith('+')) {
        // Add "+" before the phone number
        formdata?.append('mobile', '+' + data?.mobile)
      } else {
        formdata?.append('mobile', data?.mobile)
      }
    }
    if (data?.email) {
      formdata?.append('email', data?.email)
    }
    formdata?.append('_id', data?._id)
    formdata?.append('years_of_experience', data?.years_of_experience || '')
    formdata?.append('speciality', data?.speciality || '')
    formdata?.append('avaiability', data?.availability || '')
    formdata?.append('fees', data?.fees || '')
    formdata?.append('hospital_name', data?.hospital_name || '')
    formdata?.append('hospital_fees', data?.hospital_fees || '')
    formdata?.append('hospital_address', data?.hospital_address || '')
    formdata?.append('street', data?.street || '')
    formdata?.append('city', data?.city || '')
    formdata?.append('state', data?.state || '')
    formdata?.append('country', data?.country || '')
    formdata?.append('pincode', data?.pincode)
    formdata?.append('brief_detail', data?.brief_detail)
    formdata?.append('gender', data?.gender)
    if (selected?.lat) {
      formdata?.append('latitude', selected?.lat)
    }
    if (selected?.lng) {
      formdata?.append('longitude', selected?.lng)
    }

    if (!createdClinic) {
      if (data?.type === 'clinic') {
        if (data?.clinicName) {
          formdata?.append('associatedEntity', data?.clinicName)
        }
      } else {
        formdata?.append('associatedEntity', 'Individual')
      }
    } else {
      formdata?.append('associatedEntity', createdClinic)
    }

    if (data?.password && data?.password.trim() !== '') {
      formdata?.append('password', data?.password)
    }

    try {
      setLoading(true)
      const dataObject = {
        name: data?.name,
        mobile: data?.mobile ? (data?.mobile.startsWith('+') ? data?.mobile : '+' + data?.mobile) : undefined,
        email: data?.email,
        _id: data?._id,
        years_of_experience: data?.years_of_experience || '',
        speciality: data?.speciality || '',
        availability: data?.availability || '',
        fees: data?.fees || '',
        hospital_name: data?.hospital_name || '',
        hospital_fees: data?.hospital_fees || '',
        hospital_address: data?.hospital_address || '',
        street: data?.street || '',
        city: data?.city || '',
        state: data?.state || '',
        country: data?.country || '',
        pincode: data?.pincode,
        brief_detail: data?.brief_detail,
        gender: data?.gender,
        latitude: selected?.lat,
        longitude: selected?.lng,
        associatedEntity: createdClinic || (data?.type === 'clinic' && data?.clinicName ? data?.clinicName : 'Individual'),
        password: data?.password && data?.password.trim() !== '' ? data?.password : undefined
      }

      // Handling profile image separately since it's a File object
      if (data?.profile) {
        dataObject.profile_image = data.profile
      }

      // Clean up the object to remove any undefined properties
      Object.keys(dataObject).forEach(key => dataObject[key] === undefined && delete dataObject[key])

      const config = getAuthConfig()
      config.headers = {
        ...config.headers,
        'Content-Type': 'application/json'
      }
      const response = await post('/editPhysioByAdmin', JSON.stringify(dataObject), config)
      if (response?.status === 200) {
        // localStorage.setItem(
        //   'user',
        //   JSON.stringify({
        //     email: response?.data?.msg?.email,
        //     mobile_number: response?.data?.msg?.mobile,
        //     token: response?.data?.msg?.token,
        //     profile_image: response?.data?.msg?.profile_image,
        //     _id: response?.data?.msg?._id,
        //     profileCompleted: response?.data?.msg?.profileCompleted
        //   })
        // )
        // Delay navigation by 2 seconds
        setTimeout(() => {
          setLoading(false)
          navigate('/physiothepist')
        }, 2000)
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }
  useEffect(() => {
    if (location?.state?.physiotherapist) {
      getHomeData()
    }
  }, [])
  async function getHomeData () {
    try {
      const data = await get(`/physiotherapistDetails?id=${location?.state?.physiotherapist?._id}`, getAuthConfig())
      if (data?.status === 200) {
        const modifiedData = { ...data?.data?.doc }

        // Check the type of associatedEntity and set the 'type' field accordingly
        modifiedData.type = typeof modifiedData.associatedEntity === 'object' ? 'clinic' : 'Individual'
        modifiedData.availability = data?.data?.doc?.avaiability
        modifiedData.clinicName = typeof modifiedData.associatedEntity === 'object' && modifiedData?.associatedEntity?._id
        if (typeof modifiedData.associatedEntity === 'object') {
          setSelectedClinic(modifiedData.associatedEntity)
        } else {
          modifiedData.country = data?.data?.doc?.address?.country
          modifiedData.state = data?.data?.doc?.address?.state
          modifiedData.city = data?.data?.doc?.address?.city
          modifiedData.street = data?.data?.doc?.address?.street
          modifiedData.pincode = data?.data?.doc?.address?.pincode
        }

        setData(modifiedData)

        // setData(data?.data?.doc)
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate('/login')
      }
    }
  }

  const checkForExistingPatient = async (isEmail) => {
    try {
      const formdata = new FormData()
      if (!data?.mobile?.startsWith('+')) {
        formdata.append('phone', ('+' + data?.mobile))
      } else {
        formdata.append('phone', data?.mobile)
      }
      if (data?.email) {
        formdata.append('email', data?.email)
      }
      formdata.append('type', 'physiotherapist')
      const formattedMobile = data?.mobile?.trim()

      const dataes = {
        email: data.email,
        id: data._id,
        phone: formattedMobile && !formattedMobile.startsWith('+') ? `+${formattedMobile}` : formattedMobile || ''
      }
      const config = getAuthConfig()
      config.headers = {
        ...config.headers,
        'Content-Type': 'application/json'
      }

      const response = await post('/checkForPatient', JSON.stringify(dataes), config)

      if (response?.status === 200) {
        if (data?.type === 'clinic' && data?.clinicName === 'other') {
          createClinic()
        } else {
          saveProfileDetail()
        }
      }
    } catch (error) {
      if (error?.response?.status === 409) {
        setDuplicatePhysio(error?.response?.data?.msg)
        if (error?.response?.data?.msg === 'Email already exists') {
          setInputErrors((prevErrors) => ({
            ...prevErrors,
            email: true
          }))
          handleFieldError(emailInputRef)
          return
        }
        if (error?.response?.data?.msg === 'Phone already exists') {
          setInputErrors((prevErrors) => ({
            ...prevErrors,
            mobile: true
          }))
          handleFieldError(mobileInputRef)
          return
        }
        // toast.error("Physiotherapist Alreaday Exist")
      }
      console.error('Error checking for existing patient:', error)
    }
  }

  const getCurrentLocation = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords
          getAddressFromCoordinates(latitude, longitude)
        },
        (error) => {
          console.error('Error getting location:', error)
        }
      )
    } else {
      console.error('Geolocation is not available in this browser.')
    }
  }

  const getAddressFromCoordinates = async (latitude, longitude) => {
    const nominatimUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1`

    try {
      const response = await axios.get(nominatimUrl, {
        headers: {
          'User-Agent': 'YourAppName'
        }
      })

      const addressData = response.data
      if (addressData && addressData.display_name) {
        const { city, state, country, postcode } = addressData.address

        setData((p) => ({
          ...p,
          city: city || '',
          state: state || '',
          country: country || '',
          pincode: postcode || '',
          street: addressData.display_name
        }))
        setSelected({ lat: latitude, lng: longitude, address: addressData?.display_name })
        // setData((p) => ({ ...p, street: addressData.display_name }));
      } else {
        // setAddress("Address not found");
      }
    } catch (error) {
      console.error('Error fetching address:', error)
    }
  }

  useEffect(() => {
    fetchClinics()
  }, [clinics?.searchTerm, page])
  const fetchClinics = async () => {
    try {
      const response = await get(`/getAllClinic?pageNumber=${page}&searchTerm=${clinics?.searchTerm}`, getAuthConfig())
      if (response?.status === 200) {
        const otherClinic = { _id: 'other', name: 'Add more' }

        // Check if page > 1
        if (page > 1) {
          // If it's not the first page, just set the response clinics
          setClinics((p) => ({
            ...p,
            items: response?.data?.clinics,
            has_more: response?.data?.has_more
          }))
        } else {
          // If it's the first page, concatenate "OTHER" and response clinics
          setClinics((p) => ({
            ...p,
            items: [...response?.data?.clinics, otherClinic],
            has_more: response?.data?.has_more
          }))
        }
      }
    } catch (error) {
      console.error('Error fetching clinics:', error)
    }
  }
  console.log(data)
  return (
    <>
     <DashboardLayout>
      <DashboardNavbar />
      <div className="profile-main ">

        <div className="form-data full-width-data mt-5">
          <Row className="g-4">
            <Col lg="6">
              <div className="filed" ref={profileInputRef}
              >
                <label htmlFor="profile-img" className="upload">
                  <div className="profile-pic"> {profileImagePreview
                    ? (
                    <img src={profileImagePreview} alt="Profile Preview" style={{ height: '100px', width: '100px', borderRadius: '55%' }} />
                      )

                    : (data.profile_image
                        ? <img
                      src={data.profile_image}
                      alt="Exercise Preview"
                      style={{ height: '100px', width: '100px', borderRadius: '55%' }}
                    />
                        : <img
                      src={profileUpload}
                      alt="Exercise Image"
                      height={50}
                      width={50}
                    />
                      )
                  }
                    <p>Profile Picture</p>
                  </div>
                </label>
                <input
                  type="file"
                  accept="image/*"
                  id="profile-img"
                  name="profile"
                  onChange={handleProfileImgChange} // Add the onChange event handler here
                />
              </div>
              <div className={'filed '} ref={nameInputRef}>
                <label>Name <span className="text-danger">* </span></label>
                <input
                  type="text"
                  className={`input-field form-control ${inputErrors?.name ? 'field-error' : ''}`}
                  name="name"
                  onChange={handleChange}
                  value={data?.name || ''}
                />
                {inputErrors?.name && <FieldValidationError message="Please enter your full name" />}
              </div>

              <div className={'filed'} ref={mobileInputRef}>
                <label>Contact Number {data?.email ? '' : <span className="text-danger">* </span> } </label>
                <PhoneInput
                  country={'in'}
                  name="mobile"
                  value={data?.mobile}
                  onChange={(phone, country) => {
                    setData((prevData) => ({ ...prevData, mobile: phone }))
                  }}
                  className={` ${inputErrors?.mobile ? 'field-error' : ''}`}
                />
                {!DuplicatePhysio && inputErrors?.mobile && <FieldValidationError message="Please enter a valid contact number" />}
              </div>

              {/* Email Field */}
              <div className={'filed '} ref={emailInputRef}>
                <label>Email id {data?.mobile ? '' : <span className="text-danger">* </span> }</label>
                <input
                  type="email"
                  className={`input-field form-control ${inputErrors?.email ? 'field-error' : ''}`}
                  name="email"
                  onChange={handleChange}
                  value={data?.email || ''}
                />
                {!DuplicatePhysio && inputErrors?.email && <FieldValidationError message="Please enter a valid email address" />}
              </div>

              {DuplicatePhysio && <FieldValidationError message={DuplicatePhysio} />}

                {
              data?.email && !data?.isPasswordAdded && (
                <>
                  <div className="filed" ref={passwordInputRef}>
                    <label>Password  <span className="text-danger">* </span></label>
                    <input
                      type="password"
                      className={`input-field form-control ${inputErrors?.password ? 'field-error' : ''}`}
                      name="password"
                      value={data?.password}
                      onChange={handleChange}
                    />
                    {inputErrors?.password && <FieldValidationError message="Please enter valid password" />}
                  </div>
                  <div className="filed">
                    <label>Confirm Password  <span className="text-danger">* </span></label>
                    <input
                      type="password"
                      className={`input-field form-control ${inputErrors?.confirmPassword ? 'field-error' : ''}`}
                      name="confirmPassword"
                      value={data?.confirmPassword}
                      onChange={handleChange}
                    />
                    {inputErrors?.confirmPassword && <FieldValidationError message="Password not matching" />}

                  </div>

                </>
              )
}

              <div className="filed" ref={yearsOfExperienceInputRef}>
                <label>Years of Exeperince  <span className="text-danger">* </span></label>
                <input
                  type="text"
                  className={`input-field form-control ${inputErrors?.years_of_experience ? 'field-error' : ''}`}
                  name="years_of_experience"
                  onChange={(e) => {
                    const value = e.target.value

                    // Check if the input is a valid number (integer) or empty
                    if (value === '' || /^[0-9]*$/.test(value)) {
                      const newAge = value === '' ? '' : parseInt(value, 10)

                      // Check if the new age is within the range 0 to 100
                      if (newAge === '' || (newAge >= 0 && newAge <= 100)) {
                        setData((p) => ({
                          ...p,
                          years_of_experience: newAge
                        }))
                        setInputErrors((p) => ({ ...p, years_of_experience: false }))
                      } else if (newAge > 100) {
                        // If age is more than 100, set age to 100
                        // setData((p) => ({
                        //   ...p,
                        //   age: 100,
                        // }));
                        setInputErrors((p) => ({ ...p, years_of_experience: false }))
                      }
                    } else {
                      // Handle invalid input
                      setInputErrors((p) => ({ ...p, years_of_experience: true }))
                    }
                  }}
                  min="0"
                  value={data?.years_of_experience || ''}
                />
                {inputErrors?.years_of_experience && <FieldValidationError message="Please enter your experience" />}

              </div>
              <div className={'filed '} ref={specialityInputRef}>
                <label>Speciality  <span className="text-danger">* </span></label>
                <input
                  type="text"
                  className={`input-field form-control ${inputErrors?.speciality ? 'field-error' : ''}`}
                  name="speciality"
                  onChange={handleChange}
                  value={data?.speciality || ''}
                />
                {inputErrors?.speciality && <FieldValidationError message="Please enter your speciality" />}
              </div>

              {/* Availability Field */}
              <div className={'filed '} ref={availabilityInputRef}>
                <label className="text-bold">Availability  <span className="text-danger">* </span></label>
                <Form.Select
                  aria-label="Default select example"
                  name="availability"
                  onChange={handleChange}
                  value={data?.availability || ''}
                  className={` form-select ${inputErrors?.availability ? 'field-error' : ''}`}
                >
                  <option selected> Please select Availability</option>
                  <option value={'true'}>Yes</option>
                  <option value={'false'}>No</option>
                </Form.Select>
                {inputErrors?.availability && <FieldValidationError message="Please select your availability" />}
              </div>

            </Col>
            <Col lg="6">

              <div className="filed " ref={genderInputRef}>
                <label>Gender  <span className="text-danger">* </span></label>
                <Form.Select
                  aria-label="Default select example"
                  name="gender"
                  onChange={handleChange}
                  value={data?.gender || ''}
                  className={` form-select ${inputErrors?.gender ? 'field-error' : ''}`}

                >
                  <option value="">Please select Gender</option>
                  <option value="female">Female</option>
                  <option value="male">Male</option>
                  <option value="non-binary">Non-Binary</option>
                  <option value="other">Other</option>
                  <option value="Prefer not to answer">Perfer not to Answer</option>
                </Form.Select>
                {inputErrors?.gender && <FieldValidationError message="Please select gender" />}

              </div>

              <div className="filed " ref={briefDetailInputRef}>
                <label>Brief detail  <span className="text-danger">* </span></label>
                <input
                  type="textarea"
                  className={`input-field form-control ${inputErrors?.brief_detail ? 'field-error' : ''}`}
                  name="brief_detail"
                  onChange={handleChange}
                  value={data?.brief_detail || ''}
                />
                {inputErrors?.brief_detail && <FieldValidationError message="Please enter some details about you" />}

              </div>
              <div className="filed" ref={feesInputRef}>
                <label>Fees  <span className="text-danger">* </span></label>
                <input
                type="text"
                className={`input-field form-control ${inputErrors?.fees ? 'field-error' : ''}`}
                  name="fees"
                  min="0"

                     onChange={(e) => {
                       e.preventDefault()
                       const value = e.target.value
                       if (value === '' || /^[0-9]*$/.test(value)) {
                         const newAge = value === '' ? '' : parseInt(value, 10)

                         // Check if the new age is within the range 0 to 100
                         if (newAge === '' || (newAge >= 0)) {
                           setData((p) => ({
                             ...p,
                             fees: newAge
                           }))
                           setInputErrors((p) => ({ ...p, fees: false }))
                         }
                       } else {
                         // Handle invalid input
                         setInputErrors((p) => ({ ...p, fees: true }))
                       }
                     }}
                  // onChange={handleChange}
                  value={data?.fees || ''}
                />
                {inputErrors?.fees && <FieldValidationError message="Please enter fees" />}

              </div>
              <div className="filed" ref={associatedInputRef}>
                <label>Associated with  <span className="text-danger">*</span></label>
                <div className="radio-buttons d-flex">
                  <label className="w-25 align-items-center d-flex">
                    <input
                      type="radio"
                      name="type"
                      value="Individual"
                      checked={data?.type === 'Individual'}
                      onChange={(e) => {
                        setInputErrors((p) => ({ ...p, associated: false }))
                        handleChange(e)
                      }}
                    />
                    Individual
                  </label>
                  <label className="w-25 align-items-center d-flex">
                    <input
                      type="radio"
                      name="type"
                      value="clinic"
                      checked={data?.type === 'clinic'}
                      onChange={(e) => {
                        setInputErrors((p) => ({ ...p, associated: false }))
                        handleChange(e)
                      }}
                    />
                    Clinic
                  </label>
                </div>
                {inputErrors?.associated === true ? <FieldValidationError message="Please select associated entity" /> : ''}
              </div>

              {data?.type === 'clinic' && (
                <>
                  <div className="filed" ref={clinicInputRef}>

                    <div className="clinic-dropdown">
                      <label>Select Clinic <span className="text-danger">*</span></label>

                      <Select
                        placeholder="Select Clinics"
                        id="clinic_id"
                        name="clinic_id"
                        className={`form-select p-0 py-2 ${inputErrors?.clinicName ? 'field-error' : ''}`}
                        styles={{ padding: '5px' }}
    value={selectedClinic ? { value: selectedClinic._id, label: selectedClinic.name } : null}
                        options={
                          clinics?.items &&
                          clinics?.items?.map(function (productArray) {
                            return {
                              value: productArray?._id,
                              label: productArray?.name
                            }
                          })
                        }
                        onKeyDown={(e) => {
                          setClinics((p) => ({ ...p, searchTerm: e.target.value }))
                        }}
                        onChange={(e) => {
                          const selectedClinic = clinics?.items.find(clinic => clinic._id === e.value)
                          if (e.value === 'other') {
                            // Reset or clear the selectedClinic data and related input fields
                            setSelectedClinic(null)
                            setData((p) => ({
                              ...p,
                              clinicName: e.value,
                              hospital_name: '',
                              clinicPhone: '',
                              hospital_email: '',
                              street: '',
                              country: '',
                              state: '',
                              city: '',
                              pincode: ''
                            }))
                          } else {
                            // Set the selectedClinic and update related input fields
                            setSelectedClinic(selectedClinic)
                            setData((p) => ({
                              ...p,
                              clinicName: e.value,
                              hospital_name: selectedClinic?.name || '',
                              clinicPhone: selectedClinic?.phone || '',
                              hospital_email: selectedClinic?.email || '',
                              street: selectedClinic?.address?.street || '',
                              country: selectedClinic?.address?.country || '',
                              state: selectedClinic?.address?.state || '',
                              city: selectedClinic?.address?.city || '',
                              pincode: selectedClinic?.address?.pincode || ''
                            }))
                          }
                          if (inputErrors?.clinicName === true) {
                            setInputErrors((p) => ({ ...p, clinicName: false }))
                          }
                        }}
                        onBlur={(e) => {
                          e.preventDefault()
                        }}
                        onMenuScrollToBottom={(e) => {
                          if (clinics?.has_more === true) {
                            const p = page + 1
                            setPage(p)
                          }
                        }}
                      >

                      </Select>

                      {inputErrors?.clinicName && <FieldValidationError message="Please select a clinic" />}
                    </div>
                  </div>

                  {data?.clinicName === 'other'
                    ? <>

                      <div className="filed" ref={hospitalNameInputRef}>
                        <label>Hospital name  <span className="text-danger">* </span></label>
                        <input
                          type="text"
                          className={`input-field form-control ${inputErrors?.hospital_name ? 'field-error' : ''}`}
                          name="hospital_name"
                          onChange={handleChange}
                          value={data?.hospital_name || ''}
                        />
                        {inputErrors?.hospital_name && <FieldValidationError message="Please enter hospital name" />}

                      </div>
                    </>
                    : ''

                  }
                  <div className="filed" ref={hospitalclinicPhoneRef}>
                    <label>Hospital Contact Number  <span className="text-danger">* </span></label>
                    <PhoneInput
                      country={'in'}
                      name="mobileClinic"
                      className={`${inputErrors?.clinicPhone ? 'field-error' : ''}`}
                      disabled={data?.clinicName !== 'other'}
                      value={selectedClinic?.phone || data?.clinicPhone}
                      onChange={(phone, country) => {
                        setData((prevData) => ({ ...prevData, clinicPhone: phone }))
                        setInputErrors((p) => ({ ...p, clinicPhone: false }))
                      }}
                    />
                    {inputErrors?.clinicPhone && <FieldValidationError message="Please enter valid contact number for the hospital " />}

                  </div>
                  <div className="filed" ref={hostitalEmailRef}>
                    <label>Hospital Email  <span className="text-danger">* </span></label>
                    <input
                      type="text"
                      className={`input-field form-control ${inputErrors?.hospital_email ? 'field-error' : ''}`}
                      name="hospital_email"
                      disabled={data?.clinicName !== 'other'}
                      value={selectedClinic?.email || data?.hospital_email}
                      onChange={handleChange}
                    />

                    {inputErrors?.hospital_email && <FieldValidationError message="Please enter valid contact email for the hospital " />}

                  </div>
                  {clinicError && <FieldValidationError message={clinicError} />}

                  <Row>

                    <Col lg="6" className="" >
                      <div className="filed" ref={streetInputRef}>
                        <label>Address  <span className="text-danger">* </span></label>

                        <input
                          type="text"
                          className={'input-field form-control'}
                          name="street"
                          disabled={data?.clinicName !== 'other'}
                          value={selectedClinic?.address?.street || data?.street}
                          onChange={handleChange}
                        />

                      </div>
                    </Col>
                    <Col lg="6" className="">
                      <div className="filed" ref={countryInputRef}>
                        <label>Country  <span className="text-danger">* </span></label>

                        <select className={` form-select   ${inputErrors?.country ? 'field-error' : ''}`} value={country}
                          disabled={data?.clinicName !== 'other'}
                          onChange={(e) => {
                            handleCountryChange(e)
                            const selectedCountry = countries.find(
                              (c) => c.isoCode === e.target.value
                            )
                            if (selectedCountry) {
                              setData((prevData) => ({
                                ...prevData,
                                country: selectedCountry.name
                              }))
                            }
                            setInputErrors((p) => ({ ...p, country: false }))
                          }} onClick={(e) => {
                            getCountrie()
                          }}>
                          <option value="">{selectedClinic?.address?.country ? selectedClinic?.address?.country : 'Select Country'}</option>
                          {countryLoading
                            ? (
                            <option disabled>Loading...</option>
                              )
                            : (
                                countries.map((country) => (
                              <option
                                key={country?.code}
                                value={country?.isoCode}
                              >
                                {country?.name}
                              </option>
                                ))
                              )}

                        </select>
                        {inputErrors?.country && <FieldValidationError message="Please select country" />}

                      </div>
                    </Col>
                    <Col lg="6" className="">
                      <div className="filed" ref={stateInputRef}>
                        <label>State  <span className="text-danger">* </span></label>

                        <select className={`form-select ${inputErrors?.state ? 'field-error' : ''}`}
                          disabled={data?.clinicName !== 'other'}

                          value={state} onChange={(e) => {
                            handleStateChange(e)
                            const selectedStates = states.find(
                              (c) => c.isoCode === e.target.value
                            )
                            if (selectedStates) {
                              setData((prevData) => ({
                                ...prevData,
                                state: selectedStates.name
                              }))
                            }

                            setInputErrors((p) => ({ ...p, state: false }))
                          }}>
                          <option value="">{selectedClinic?.address?.state ? selectedClinic?.address?.state : 'Select State'}</option>
                          {stateLoading
                            ? (
                            <option disabled>Loading...</option>
                              )
                            : (
                                states.map((state) => (
                              <option
                                key={state?.code}
                                value={state?.isoCode}
                              >
                                {state?.name}
                              </option>
                                ))
                              )}
                        </select>

                        {inputErrors?.state && <FieldValidationError message="Please select state" />}

                      </div>
                    </Col>
                    <Col lg="6" className="">
                      <div className="filed" ref={cityInputRef}>
                        <label>City  <span className="text-danger">* </span></label>

                        <select className={` form-select ${inputErrors?.city ? 'field-error' : ''}`}
                          disabled={data?.clinicName !== 'other'}

                          value={data?.hospital_city} onChange={(e) => {
                            setData((p) => ({ ...p, city: e?.target?.value }))
                            setInputErrors((p) => ({ ...p, city: false }))
                          }}>
                          <option value="">{selectedClinic?.address?.city ? selectedClinic?.address?.city : 'Select City'}</option>
                          {cityLoading
                            ? (
                            <option disabled>Loading...</option>
                              )
                            : (
                                cities.map((city) => (
                              <option key={city?.code} value={city?.code}>
                                {city?.name}
                              </option>
                                ))
                              )}
                        </select>
                        {inputErrors?.city && <FieldValidationError message="Please select city" />}

                      </div>
                    </Col>
                    <Col lg="6" className="">
                      <div className="filed" ref={zipInputRef} >
                        <label>Postal Code <span className="text-danger">* </span></label>

                        <input
                          type="number"
                          className={`input-field form-control  ${inputErrors?.pincode ? 'field-error' : ''}`}
                          disabled={data?.clinicName !== 'other'}
                          name="pincode"
                          onChange={handleChange}
                          value={selectedClinic?.address?.pincode || data?.pincode}
                        />
                        {inputErrors?.pincode && <FieldValidationError message="Please enter valid pincode" />}

                      </div>
                    </Col>
                  </Row>
                </>
              )}

              {data?.type === 'Individual' &&
                <div className="filed">
                  <Row>

                    <Col lg="6" className="" >
                      <div className="filed" ref={streetInputRef}>
                        <label>Address  <span className="text-danger">* </span></label>
                        <button className="theme-button btn btn-primary mb-2" style={{ minWidth: 'unset', fontWeight: 'unset', fontSize: '14px', borderRadius: '6px' }} onClick={(e) => {
                          e.preventDefault()
                          getCurrentLocation()
                        }} >Use Current Location</button>
                        {/* <PlacesAutoComplete data={data} setFormData={setData} selected={selected} setSelected={setSelected} isLoaded={isLoaded} /> */}

                        <input
                          type="text"
                          className={`input-field form-control ${inputErrors?.street ? 'field-error' : ''}`}
                          name="street"
                          value={data?.street || ''}
                          onChange={handleChange}
                        />
                        {inputErrors?.street && <FieldValidationError message="Please enter your address" />}

                      </div>
                    </Col>
                    <Col lg="6" className="">
                      <div className="filed" ref={countryInputRef}>
                        <label>Country  <span className="text-danger">* </span></label>

                        <select className={` form-select   ${inputErrors?.country ? 'field-error' : ''}`} value={data?.country} onChange={(e) => {
                          handleCountryChange(e)
                          const selectedCountry = countries.find(
                            (c) => c.isoCode === e.target.value
                          )
                          if (selectedCountry) {
                            setData((prevData) => ({
                              ...prevData,
                              country: selectedCountry.name
                            }))
                          }
                          setInputErrors((p) => ({ ...p, country: false }))
                        }} onClick={(e) => {
                          getCountrie()
                        }}>
                          <option value="">{ data?.country ? data?.country : 'Select Country'}</option>
                          {countryLoading
                            ? (
                            <option disabled>Loading...</option>
                              )
                            : (
                                countries.map((country) => (
                              <option
                                key={country?.code}
                                value={country?.isoCode}
                              >
                                {country?.name}
                              </option>
                                ))
                              )}

                        </select>
                        {inputErrors?.country && <FieldValidationError message="Please select country" />}

                      </div>
                    </Col>
                    <Col lg="6" className="">
                      <div className="filed" ref={stateInputRef}>
                        <label>State  <span className="text-danger">* </span></label>

                        <select className={`form-select ${inputErrors?.state ? 'field-error' : ''}`} value={data?.state} onChange={(e) => {
                          handleStateChange(e)
                          const selectedStates = states.find(
                            (c) => c.isoCode === e.target.value
                          )
                          if (selectedStates) {
                            setData((prevData) => ({
                              ...prevData,
                              state: selectedStates.name
                            }))
                          }

                          setInputErrors((p) => ({ ...p, state: false }))
                        }}>
                                                    <option value="">{ data?.state ? data?.state : 'Select State'}</option>

                          {stateLoading
                            ? (
                            <option disabled>Loading...</option>
                              )
                            : (
                                states.map((state) => (
                              <option
                                key={state?.code}
                                value={state?.isoCode}
                              >
                                {state?.name}
                              </option>
                                ))
                              )}
                        </select>

                        {inputErrors?.state && <FieldValidationError message="Please select state" />}

                      </div>
                    </Col>
                    <Col lg="6" className="">
                      <div className="filed" ref={cityInputRef}>
                        <label>City  <span className="text-danger">* </span></label>

                        <select className={` form-select ${inputErrors?.city ? 'field-error' : ''}`} value={data?.city} onChange={(e) => {
                          setData((p) => ({ ...p, city: e?.target?.value }))
                          setInputErrors((p) => ({ ...p, city: false }))
                        }}>
                                                    <option value="">{ data?.city ? data?.city : 'Select City'}</option>

                          {/* <option value="">Select City</option> */}
                          {cityLoading
                            ? (
                            <option disabled>Loading...</option>
                              )
                            : (
                                cities.map((city) => (
                              <option key={city?.code} value={city?.code}>
                                {city?.name}
                              </option>
                                ))
                              )}
                        </select>
                        {inputErrors?.city && <FieldValidationError message="Please select city" />}

                      </div>
                    </Col>
                    <Col lg="6" className="">
                      <div className="filed" ref={zipInputRef} >
                        <label>Pincode <span className="text-danger">* </span></label>

                        <input
                          type="number"
                          className={` form-control  ${inputErrors?.pincode ? 'field-error' : ''}`}

                          name="pincode"
                          onChange={handleChange}
                          value={data?.pincode || ''}
                        />
                        {inputErrors?.pincode && <FieldValidationError message="Please enter valid pincode" />}

                      </div>
                    </Col>
                  </Row>
                </div>}
            </Col>
          </Row>
          <div className="buttonPart mt-5 d-flex justify-content-center mb-4">
          <Button className="theme-button" onClick={checkForExistingPatient} disabled={loading}>
        {loading ? 'Loading...' : 'Submit'}
      </Button>
      </div>
        </div>
      </div>
      </DashboardLayout>
    </>
  )
}

export default EditPhysiotherapist
