// @mui material components
import Card from '@mui/material/Card'
// Soft UI Dashboard React components
import SoftBox from 'components/SoftBox'
import SoftTypography from 'components/SoftTypography'

// Soft UI Dashboard React examples
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import Footer from 'examples/Footer'
import Table from 'examples/Tables/Table'
import SoftInput from 'components/SoftInput'

// Data
import React, { useEffect, useState } from 'react'
import { getAuthConfig, get, post, deletes } from 'libs/http-hydrate'
import SoftAvatar from 'components/SoftAvatar'
import PropTypes from 'prop-types' // Import PropTypes
import moment from 'moment'
import Box from '@mui/material/Box'
import Modal from 'react-bootstrap/Modal'
import InfoIcon from '@mui/icons-material/Info'
import Button from '@mui/material/Button'
import AccessTimeIcon from '@mui/icons-material/AccessTimeFilled'
import AccessibleIcon from '@mui/icons-material/Accessible'
import { useNavigate } from 'react-router-dom'
import { Auth } from 'libs/auth'
import ConfirmModal from 'layouts/Modal/ConfirmModal'

function ExerciseList () {
  // const { columns: prCols, rows: prRows } = projectsTableData
  const [isLoading, setIsLoading] = useState(false)
  const [physiotherapistList, setPhysiotherapistList] = useState({
    items: [],
    has_more: false,
    total_pages: 0
  })
  const navigate = useNavigate()
  const [page, setPage] = useState(0)
  const [searchQuery, setSearchQuery] = useState('') // State for search query
  useEffect(() => {
    getPhysiotherapistList()
  }, [page, searchQuery])

  useEffect(() => {
    getLanguageList()
  }, [])
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [exerciseToDelete, setExerciseToDelete] = useState(null);
  
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedClinic, setSelectedClinic] = useState(null)
  const [language, setLanguage] = useState({
    items: [],
    has_more: false
  })
  const [descriptions, setDescriptions] = useState([])
  const [descriptionLoading, setDescriptionLoading] = useState(false)
  const [descriptionError, setDescriptionError] = useState('')
  const [editingLanguage, setEditingLanguage] = useState(null)
  const [descriptionText, setDescriptionText] = useState('')
  const [editError, setEditError] = useState('')
  const [editSuccess, setEditSuccess] = useState('')
  // Function to handle opening the modal
  const handleOpenModal = (clinic) => {
    setSelectedClinic(clinic)
    setModalOpen(true) // Open the modal
  }

  // Function to handle closing the modal
  const handleCloseModal = () => {
    setModalOpen(false)
    setDescriptions([]) // Clear descriptions when closing modal
    setDescriptionError('') // Clear error when closing modal
    setEditingLanguage(null) // Clear editing language when closing modal
    setDescriptionText('') // Clear description text when closing modal
    setEditError('') // Clear edit error when closing modal
    setEditSuccess('') // Clear edit success when closing modal
  }

  async function getPhysiotherapistList () {
    try {
      setIsLoading(true)
      const data = await get(`/exerciseListAdmin?pageNumber=${page}&searchTerm=${searchQuery}`, getAuthConfig())
      if (data?.status === 200) {
        // if (page === 0) {
        setPhysiotherapistList((p) => ({ ...p, items: data?.data?.data }))
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
        // }
        //  else {
        //   setPhysiotherapistList((p) => ({ ...p, items: physiotherapistList?.items?.concat(data?.data?.data) }))
        // }
        setPhysiotherapistList((p) => ({ ...p, has_more: data?.data?.has_more, total_pages: data?.data?.total_pages }))
      }
    } catch (error) {

    } finally {
      setIsLoading(false) // Set isLoading to false in the finally block
    }
  }

  async function getLanguageList () {
    try {
      const data = await get('/api/languages', getAuthConfig())
      if (data?.status === 200) {
        if (data?.data?.msg) {
          setLanguage((p) => ({ ...p, items: data?.data?.msg }))
        }
      }
    } catch (error) {
    }
  }

  async function fetchDescriptions (languageCode, exerciseIds) {
    try {
      setDescriptionLoading(true)
      let queryParams = `languageCode=${languageCode}`
      exerciseIds?.forEach((id, index) => {
        queryParams += `&exerciseIds[${index}]=${id}`
      })
      const data = await get(`/api/exerciseDescriptions?${queryParams}`, getAuthConfig())
      if (data?.status === 200) {
        setDescriptions(data?.data?.descriptions)
        setDescriptionError('')
      } else {
        setDescriptions([])
        setDescriptionError('No descriptions found for the given Exercise IDs and Language Code')
      }
    } catch (error) {
      setDescriptions([])
      setDescriptionError('Error fetching exercise descriptions')
    } finally {
      setDescriptionLoading(false)
    }
  }

  async function handleSaveDescription () {
    try {
      const authFunctions = Auth()
      const payload = {
        exerciseId: selectedClinic?._id,
        language: editingLanguage,
        text: descriptionText
      }
      const data = await post('/api/exerciseDescription', payload, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'Web ' + 'Admin ' + authFunctions.getAuthToken()
        }
      })
      if (data?.status === 200) {
        setEditSuccess(data?.data?.msg)
        setEditError('')
        fetchDescriptions(editingLanguage, [selectedClinic?._id]) // Refresh descriptions
      } else {
        setEditError('Error saving the description')
      }
    } catch (error) {
      setEditError('Error saving the description')
    }
  }

  function Author ({ image, name, onClick }) {
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5} onClick={onClick} >
        <SoftBox mr={2}>
          <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
        </SoftBox>
        <SoftBox display="flex" flexDirection="column">
          <SoftTypography variant="button" fontWeight="medium">
            {name}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    )
  }

  function Function ({ job, org }) {
    return (
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="caption" fontWeight="medium" color="text">
          {job}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {org}
        </SoftTypography>
      </SoftBox>
    )
  }
  // PropTypes definition for Author
  Author.propTypes = {
    image: PropTypes.string.isRequired, // Assuming 'image' is a string
    name: PropTypes.string.isRequired // Assuming 'name' is a string
  }

  // PropTypes definition for Function
  Function.propTypes = {
    job: PropTypes.string.isRequired, // Assuming 'job' is a string
    org: PropTypes.string.isRequired // Assuming 'org' is a string
  }

  const authorsTableData = {
    columns: [
      { name: 'Exercise', align: 'left' },
      { name: 'BodyPart', align: 'center' },
      { name: 'created', align: 'left' },
      { name: 'action', align: 'center' }
    ],

    rows: physiotherapistList.items && physiotherapistList.items.map((item) => ({
      Exercise: (
        <Author image={item.exerciseImage ? item?.exerciseImage : ''} name={item.title} onClick={() => handleOpenModal(item)} />
      ),
      BodyPart: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {item?.body_part_id && item?.body_part_id.length > 0 && (
            <div className="text-part ps-3 mt-2">
              <p style={{ cursor: 'pointer' }}>
                {item?.body_part_id.map(
                  (itemEq, ind) => (
                    <React.Fragment key={ind}>
                      {ind > 0 && ' - '}
                      {itemEq?.title}
                    </React.Fragment>
                  )
                )}
              </p>
            </div>
          )}
        </SoftTypography>
      ),
      created: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {moment(item.created_at).format('DD / MM / YYYY')}
        </SoftTypography>
      ),
      action: (
        <>
          <SoftTypography
            component="a"
            variant="caption"
            color="secondary"
            fontWeight="medium"
            onClick={(e) => {
              navigate('/DescriptionAdding', { state: { item } })
            }}
          >
            Add Description
          </SoftTypography> /
          <SoftTypography
            component="a"
            variant="caption"
            color="secondary"
            fontWeight="medium"
            onClick={() => {
              navigate('/editExercise', { state: { exercise: item } })
            }}
          >
            &nbsp; Edit
          </SoftTypography> /
          <SoftTypography
            component="a"
            href="#"
            variant="caption"
            color="secondary"
            fontWeight="medium"
            onClick={() => handleDeleteClick(item._id)} // Add onClick handler here
          >
            Delete
          </SoftTypography>
        </>
      )
    }))
  }
  const handleDeleteClick = (exerciseId) => {
    setExerciseToDelete(exerciseId); // Store the exercise ID to delete
    setDeleteModalVisible(true); // Show the confirmation modal
  };
  const confirmDelete = async () => {
    const url = `/exercise?id=${exerciseToDelete}`;
    try {
      const data = await deletes(url);
      if (data?.status === 200) {
        // Remove the deleted item from the list
        setPhysiotherapistList((prevState) => ({
          ...prevState,
          items: prevState.items.filter((item) => item._id !== exerciseToDelete)
        }));
      }
    } catch (error) {
      console.error('Error deleting exercise:', error);
    } finally {
      setDeleteModalVisible(false); // Close the confirmation modal
    }
  };
  
  function generatePageNumbers () {
    const pageNumbers = []
    for (let i = 0; i < physiotherapistList.total_pages; i++) {
      pageNumbers.push(i)
    }
    return pageNumbers
  }

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <SoftBox py={3}>
          <SoftBox mb={3}>
            <Card>
              <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <SoftTypography variant="h6">Exercise table</SoftTypography>
                <div style={{ padding: '1rem' }}>
                  <SoftBox pr={1}>
                    <SoftInput
                      type="text"
                      placeholder="Search Exercise"
                      value={searchQuery}
                      onChange={(e) => {
                        setSearchQuery(e.target.value)
                        setPage(0)
                        getPhysiotherapistList()
                      }}
                    />
                  </SoftBox>
                </div>
              </SoftBox>
              {isLoading
                ? (
                  <>
                    <SoftBox display="flex" justifyContent="center" alignItems="center" p={3}>
                      <SoftTypography variant="h6">Loading ... </SoftTypography>
                    </SoftBox>
                  </>
                  )
                : (
                  <SoftBox
                    sx={{
                      '& .MuiTableRow-root:not(:last-child)': {
                        '& td': {
                          borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`
                        }
                      }
                    }}
                  >
                    {physiotherapistList?.items?.length === 0
                      ? (
                      <div style={{ textAlign: 'center' }}>
                        <SoftTypography variant='h6' color='textSecondary'> No Exercise found </SoftTypography>
                      </div>
                        )
                      : <Table columns={authorsTableData?.columns} rows={authorsTableData?.rows} />}
                  </SoftBox>
                  )}
              <div className='mt-3' style={{ display: 'flex', justifyContent: 'center' }}>
                <ul className="pagination" style={{ overflow: 'scroll' }}>
                  {generatePageNumbers().map((pageNumber) => (
                    <li key={pageNumber} className={`page-item ${page === pageNumber ? 'active' : ''}`}>
                      <a className="page-link" onClick={() => setPage(pageNumber)}>
                        {pageNumber + 1}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </Card>
          </SoftBox>
        </SoftBox>
        <Footer />
      </DashboardLayout>
      <ConfirmModal
        title="Confirm Delete"
        message="Are you sure you want to delete this exercise?"
        onConfirm={confirmDelete}
        onCancel={() => setDeleteModalVisible(false)}
        isVisible={deleteModalVisible}
      />
      <Modal
        show={modalOpen}
        onHide={handleCloseModal}
        centered
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title>Exercise Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
            {selectedClinic?.videoLink && (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <video controls height="200">
                  <source src={selectedClinic.videoLink} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </Box>
            )}
            {language?.items &&
              language?.items.length > 0 &&
              language?.items.map((item, index) => (
                item?.code !== 'EN' && ( // Check if item.code is not "EN"
                  <a
                    key={index}
                    style={{ cursor: 'pointer', fontSize: '14px', fontWeight: 'bold' }}
                    onClick={() => {
                      fetchDescriptions(item?.code, [selectedClinic?._id])
                      setEditingLanguage(item?.code) // Set editing language
                    }} // Fetch descriptions for the selected language
                  >
                    Click here to see the description in <span style={{ color: '#17c1e8' }}>{item?.name}</span>
                  </a>
                )
              ))
            }
            {/* Display loading, error, or descriptions */}
            {descriptionLoading && (
              <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                Loading descriptions...
              </SoftTypography>
            )}
            {descriptionError && (
              <SoftTypography variant="caption" color="error" fontWeight="medium">
                {descriptionError}
              </SoftTypography>
            )}
            {!descriptionLoading && !descriptionError && descriptions.map((desc, index) => (
              <SoftTypography key={index} variant="caption" color="secondary" fontWeight="medium">
                {desc.text}
              </SoftTypography>
            ))}
            {/* Edit description form */}
            {editingLanguage && (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <SoftTypography variant="h6">Edit Description ({editingLanguage})</SoftTypography>
                <SoftInput
                  multiline
                  rows={4}
                  value={descriptionText}
                  onChange={(e) => setDescriptionText(e.target.value)}
                  placeholder="Enter the description..."
                />
                {editError && (
                  <SoftTypography variant="caption" color="error" fontWeight="medium">
                    {editError}
                  </SoftTypography>
                )}
                {editSuccess && (
                  <SoftTypography variant="caption" color="success" fontWeight="medium">
                    {editSuccess}
                  </SoftTypography>
                )}
                <Button variant="contained" color="primary" onClick={handleSaveDescription}>
                  Save Description
                </Button>
              </Box>
            )}
            {/* Each detail with its icon */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <InfoIcon sx={{ color: '#17c1e8' }} />
              <span style={{ fontSize: '14px' }}> {selectedClinic?.title}</span>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <InfoIcon sx={{ color: '#17c1e8' }} />
              <span style={{ fontSize: '14px', maxHeight: '50vh', overflow: 'scroll' }} dangerouslySetInnerHTML={{ __html: selectedClinic?.description }}></span>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <AccessibleIcon sx={{ color: '#17c1e8' }} />
              <span style={{ fontSize: '14px' }}>
                {selectedClinic?.body_part_id && selectedClinic?.body_part_id.length > 0 && (
                  <div className="text-part ps-3 mt-2">
                    <p style={{ cursor: 'pointer' }}>
                      {selectedClinic?.body_part_id.map(
                        (itemEq, ind) => (
                          <React.Fragment key={ind}>
                            {ind > 0 && ' - '}
                            {itemEq?.title}
                          </React.Fragment>
                        )
                      )}
                    </p>
                  </div>
                )}
              </span>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <AccessTimeIcon sx={{ color: '#17c1e8' }} />
              <span style={{ fontSize: '14px' }}>Posted At: {moment(selectedClinic?.created_at).format('DD / MM / YYYY')}</span>
            </Box>
          </Box>
        </Modal.Body>
        <Modal.Footer style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
          <Button className='m-2' variant="outlined" color="error" sx={{ color: '#ea0606' }} onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ExerciseList
